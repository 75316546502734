import clsx from 'clsx';
interface CircularProgressBarProps {
  value: number;
  size?: number;
  strokeWidth?: number;
  className?: string;
}

export const CircularProgressBar = ({value, size = 96, strokeWidth = 4, className = ''}: CircularProgressBarProps) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (value / 100) * circumference;
  const textSize = `${size / 2}px`;

  return (
    <div className={clsx('relative', className)} style={{width: size, height: size}}>
      <svg className="h-full w-full -rotate-90">
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke="currentColor"
          strokeWidth={strokeWidth}
          fill="transparent"
          className="text-grey"
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke="currentColor"
          strokeWidth={strokeWidth}
          fill="transparent"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          className="text-s text-mls-sky-blue transition-all duration-300 ease-in-out"
        />
      </svg>
      <div className="absolute inset-0 flex items-center justify-center">
        <span
          className="font-teko text-2xl font-bold text-mls-sky-blue"
          style={{
            fontSize: textSize,
          }}
        >
          {value}
        </span>
      </div>
    </div>
  );
};
