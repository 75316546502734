import {useMemo} from 'react';
import clsx from 'clsx';
import {Button} from '../Button/Button';
import {ReactComponent as ChevronBack} from '../../../assets/img/icons/mls-icons/chevron-left.svg';
import {ReactComponent as ChevronNext} from '../../../assets/img/icons/mls-icons/chevron-right.svg';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export const Pagination = ({currentPage, totalPages, onPageChange}: PaginationProps) => {
  const pageNumbers = useMemo(() => Array.from({length: totalPages}, (_, i) => i + 1), [totalPages]);

  const shouldDisplayPageNumber = (number: number) => {
    return number === 1 || number === totalPages || (number >= currentPage - 1 && number <= currentPage + 1);
  };

  if (totalPages <= 1) {
    return null;
  }

  return (
    <div className="flex items-center justify-center space-x-2" data-test-id="pagination-container">
      <Button
        variant="outline"
        onClick={() => onPageChange(currentPage - 1)}
        size="sm"
        className="rounded-sm py-2"
        disabled={currentPage === 1}
      >
        <ChevronBack className="h-4 w-4 fill-white" />
      </Button>

      {pageNumbers.map((number) => {
        if (shouldDisplayPageNumber(number)) {
          return (
            <Button
              variant={currentPage === number ? 'dark' : 'outline'}
              key={number}
              onClick={() => onPageChange(number)}
              size="sm"
              className="min-h-[35px] min-w-10 rounded-sm"
            >
              {number}
            </Button>
          );
        } else if (number === currentPage - 2 || number === currentPage + 2) {
          return (
            <span key={number} className="px-2">
              ...
            </span>
          );
        }
        return null;
      })}

      <Button
        variant="outline"
        onClick={() => onPageChange(currentPage + 1)}
        size="sm"
        className="rounded-sm py-2"
        disabled={currentPage === totalPages}
      >
        <ChevronNext className="h-4 w-4 fill-white" />
      </Button>
    </div>
  );
};
