import {useParams} from 'react-router-dom';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Assessment as AssessmentType} from 'api/Coach/useGetCoachInfo';
import {useProfiles} from 'api/User/useProfiles';
import Spinner from 'components/Loader/Spinner';
import Dropdown, {DropdownOption} from 'components/Dropdown/Dropdown';
import {useGlobalActiveAssessmentState} from 'api/Director/useGlobalActiveAssessmentId';
import {useGlobalActiveTeamState} from 'api/Director/useGlobalActiveTeamId';
import {useGetDirectorInfo} from 'api/Director/useGetDirectorInfo';
import {DirectorAssessment} from './DirectorAssessment/DirectorAssessment';
import {DirectorAssessmentStats} from './DirectorAssessmentStats/AssessmentStats';
import {Button} from 'components/Connects/Button';
import {AddEditAssessmentModal} from './AddEditAssessmentModal/AddEditAssessmentModal';
import {ReactComponent as EditIcon} from '../../assets/img/icons/edit-icon-minimal.svg';
import {isFutureAssessment} from './utils';

export const DirectorHome = () => {
  const {teamId} = useParams();
  const {currentProfile} = useProfiles();
  const {data: directorInfo, isFetching} = useGetDirectorInfo(currentProfile?._id);
  const team = directorInfo?.teams.find((team) => team._id === teamId);
  const [currentAssessment, setCurrentAssessment] = useState<AssessmentType | null>(null);
  const {globalActiveAssessmentId, setGlobalActiveAssessmentId} = useGlobalActiveAssessmentState();
  const {globalActiveTeamId, setGlobalActiveTeamId} = useGlobalActiveTeamState();
  const [defaultAssessmentOption, setDefaultAssessmentOption] = useState<DropdownOption | null>(null);
  const [showAssessmentModal, setShowAssessmentModal] = useState<boolean>(false);
  const [editAssessment, setEditAssessment] = useState<boolean>(false);

  const {allAssessments, assessmentOptions} = useMemo(() => {
    const allAssessments: any = {};
    const assessmentOptions: any = [];
    if (team) {
      for (const currentAssessment of directorInfo?.teamAssessments?.[team?._id] ?? []) {
        allAssessments[currentAssessment?._id] = currentAssessment;
        assessmentOptions.push({displayValue: currentAssessment?.name, value: currentAssessment?._id});
      }
    }
    return {allAssessments, assessmentOptions};
  }, [directorInfo?.teamAssessments, team]);

  useEffect(() => {
    if (globalActiveAssessmentId || assessmentOptions.length) {
      let assessmentId = globalActiveAssessmentId;
      if (teamId && globalActiveTeamId !== teamId) {
        assessmentId = assessmentOptions[0]?.value;
        setGlobalActiveAssessmentId(assessmentId);
        setGlobalActiveTeamId(teamId);
      }
      setCurrentAssessment(allAssessments[assessmentId]);
      setDefaultAssessmentOption({displayValue: allAssessments[assessmentId]?.name, value: assessmentId});
    }
  }, [
    allAssessments,
    assessmentOptions,
    globalActiveAssessmentId,
    teamId,
    globalActiveTeamId,
    setGlobalActiveTeamId,
    setGlobalActiveAssessmentId,
  ]);

  const handleAssessmentChange = useCallback(
    (option: DropdownOption) => {
      const assessmentId = option?.value;
      setCurrentAssessment(allAssessments[assessmentId]);
      setGlobalActiveAssessmentId(assessmentId);
    },
    [allAssessments, setGlobalActiveAssessmentId]
  );

  const handleRightActionItemClick = (selectedOption: DropdownOption | null) => {
    setEditAssessment(true);
    setShowAssessmentModal(true);
  };

  const cancelModal = () => {
    setEditAssessment(false);
    setShowAssessmentModal(false);
  };

  const RightItem: React.ElementType = () => (
    <EditIcon className="fill-black h-4 w-4 ml-2 mb-1 cursor-pointer" data-test-id="edit-assessment" />
  );

  return (
    <div className="flex-1 flex flex-col w-full">
      {isFetching ? (
        <div className="w-full h-screen flex flex-1 items-center justify-center">
          <Spinner />
        </div>
      ) : !team ? (
        <div className="w-full h-full flex text-base mt-4 ml-4">Director is not part of this team.</div>
      ) : (
        <>
          <div className="flex mt-4">
            <Button
              color="secondary"
              text="Add Assessment"
              variant="cta"
              onClick={() => setShowAssessmentModal(true)}
            ></Button>
          </div>
          {showAssessmentModal && (
            <AddEditAssessmentModal
              showModal={showAssessmentModal}
              siteId={team.siteId}
              teamId={teamId as string}
              cancelModal={cancelModal}
              isEdit={editAssessment}
              assessment={currentAssessment || undefined}
            />
          )}
          {currentAssessment ? (
            <>
              <div className="flex mt-2">
                <Dropdown
                  placeholder="Select Assessment"
                  defaultOption={defaultAssessmentOption}
                  options={assessmentOptions}
                  onSelect={handleAssessmentChange}
                  RightActionItem={isFutureAssessment(currentAssessment) ? RightItem : undefined}
                  onRightActionItemClick={handleRightActionItemClick}
                />
              </div>
              <DirectorAssessmentStats assessmentId={currentAssessment?._id ?? ''} />
              {/* <Button color="primary" variant="cta" text={'ASSIGN SESSIONS'} /> */}
              <DirectorAssessment assessment={currentAssessment} />
            </>
          ) : (
            <div className="w-full h-full flex text-base mt-4 ml-4">No Assessment found</div>
          )}
        </>
      )}
    </div>
  );
};
