import {memo, useMemo} from 'react';
import {ButtonVariant} from '../Standard/StandardButton';
import styles from './FormButton.module.scss';

interface FormButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isPrimary?: boolean;
  hasError?: boolean;
  text?: string;
  variant?: ButtonVariant;
  buttonCustomClassName?: string;
}

export const BookingFormButton = memo((props: FormButtonProps) => {
  const {
    children,
    isPrimary,
    hasError,
    variant = isPrimary ? 'primary' : 'default',
    text,
    buttonCustomClassName,
    ...rest
  } = props;

  const buttonClasses = useMemo(
    () => `${styles[variant]} ${hasError ? styles.error : ''} ${styles.button} ${buttonCustomClassName}`,
    [hasError, variant, buttonCustomClassName]
  );
  return (
    <>
      <button className={buttonClasses} {...rest}>
        {children ?? text}
      </button>
    </>
  );
});
BookingFormButton.displayName = 'BookingFormButton';
