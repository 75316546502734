import {useState, useCallback, memo, useMemo, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import PhotoSourceSelector from './PhotoSourceSelector';
import PatternAndColorSelector from './PatternAndColorSelector';
import {AvatarPreview} from './AvatarPreview';
import {AvatarCartoonSelector} from './AvatarCartoonSelector';
import {useQueryClient} from '@tanstack/react-query';
import {generateCartoonStylesQueryKey, getPhotoId} from 'api/MLSChallenge/useGetCartoonStyles';
import {useGetCartoonStyles} from 'api/MLSChallenge/useGetCartoonStyles';
import {Button} from 'components/MLS/Button/Button';
import useUpdatePlayerCard from 'api/MLSChallenge/useUpdatePlayerCard';
import {useProfiles} from 'api/User/useProfiles';
import {getFallbackAvatars, getImageSizeInMbs, urlToDataUrl} from './utils';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import {CropModal} from './CropModal';
import {useGetCroppedImage} from 'api/MLSChallenge/useGetCroppedImage';
import ArrowIcon from 'components/MLS/ArrowIcon/ArrowIcon';
import {useColors} from 'hooks/MLSChallenge/usePatterns';
import {useGetImageTransformations} from 'api/MLSChallenge/useGetImageTransformations';
import {useGetDownsizedImage} from 'api/MLSChallenge/useGetDownsizedImage';
import Loader from 'components/MLS/Loader/Loader';
import {useGetExplicitImageCheck} from 'api/MLSChallenge/useGetExplicitImageCheck';
import {toast} from 'react-toastify';
import {TransformationType} from 'api/MLSChallenge/getImageTransformations';
import {usePatterns} from 'hooks/MLSChallenge/usePatterns';

const DEFAULT_COLOR_INDEX = 11;
const DOWNSIZE_THRESHOLD_MBS = 3;

enum Step {
  PHOTO = 'Photo',
  CARTOON = 'Avatar',
  PATTERN_COLOR = 'Pattern & Color',
}

const STEPS = [Step.PHOTO, Step.CARTOON, Step.PATTERN_COLOR];

const AvatarCreator = memo(() => {
  const colors = useColors();
  const patterns = usePatterns();
  const navigate = useNavigate();
  const {
    currentProfile: {_id: playerProfileId, playerCardInfo},
    refetchUserAndProfiles,
  } = useProfiles();

  const queryClient = useQueryClient();
  const [currentStep, setCurrentStep] = useState<Step>(Step.PHOTO);
  const [selectedPattern, setSelectedPattern] = useState<number>(playerCardInfo?.patternIndex ?? 0);
  const [selectedColor, setSelectedColor] = useState<number>(() => {
    if (
      playerCardInfo?.playerCardLightColor &&
      playerCardInfo?.playerCardDarkColor &&
      playerCardInfo?.playerCardTextColor
    ) {
      const selectedColorIndex = colors.findIndex(
        (color) =>
          color.lightColor === playerCardInfo?.playerCardLightColor &&
          color.darkColor === playerCardInfo?.playerCardDarkColor &&
          color.textColor === playerCardInfo?.playerCardTextColor
      );
      return selectedColorIndex !== -1 ? selectedColorIndex : DEFAULT_COLOR_INDEX;
    }
    return DEFAULT_COLOR_INDEX;
  });
  const [photoUrl, setPhotoUrl] = useState<string | null>(() => {
    if (playerCardInfo?.cartoonUrls?.length) {
      return playerCardInfo.cartoonUrls[0];
    }
    return null;
  });
  const [cartoonPhotoUrl, setCartoonPhotoUrl] = useState<string | null>(() => {
    return playerCardInfo?.imageSrc ?? null;
  });
  const [selectedCartoonIndex, setSelectedCartoonIndex] = useState<number>(playerCardInfo?.selectedCartoonIndex ?? 0);
  const [fallbackCartoons, setFallbackCartoons] = useState<string[]>([]);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const [cartoons, setCartoons] = useState<string[]>(() => {
    if (playerCardInfo?.cartoonUrls?.length) {
      return playerCardInfo.cartoonUrls;
    }
    return [];
  });

  const [croppedCartoons, setCroppedCartoons] = useState<(string | null)[]>(playerCardInfo?.croppedCartoonUrls ?? []);
  const [setProcessingSelectedPhoto, setIsProcessingSelectedPhoto] = useState(false);

  const {updatePlayerCard} = useUpdatePlayerCard({
    playerProfileId: playerProfileId,
    onSuccessHandler: () => {
      console.log('onSuccessHandler');
      // toast.success('Player Card details updated successfully');
      refetchUserAndProfiles();
      navigate('/home');
    },
    onErrorHandler: () => {
      console.log('onErrorHandler');
      setError('Failed to save Player Card Info. Please try again.');
    },
  });

  const {
    data: transformations,
    isLoading: isTransformationLoading,
    error: transformationError,
  } = useGetImageTransformations(photoUrl, currentStep === Step.CARTOON && !!photoUrl && !cartoons.length, () => {
    console.log('fetching image transformations');
  });

  const hasEnoughInfo = useMemo(() => {
    return !!cartoonPhotoUrl && selectedPattern !== null && selectedColor !== null;
  }, [cartoonPhotoUrl, selectedPattern, selectedColor]);

  const CARTOON_MAPPING: Record<number, TransformationType> = {
    0: 'original',
    1: 'cartoon',
    2: 'background-removed',
  } as const;

  const availableTransformations = useMemo(() => {
    if (!photoUrl) {
      return fallbackCartoons.map((url) => ({type: 'cartoon' as const, url}));
    }
    if (cartoons.length) {
      return cartoons.map((url, i) => ({
        type: CARTOON_MAPPING[i as keyof typeof CARTOON_MAPPING],
        url,
      }));
    }
    if (isTransformationLoading || !transformations) {
      return [{type: 'original' as const, url: photoUrl}];
    }
    return transformations;
  }, [photoUrl, cartoons, fallbackCartoons, transformations, isTransformationLoading]);

  useEffect(() => {
    if (!croppedCartoons.length && availableTransformations.length) {
      setCroppedCartoons(Array(availableTransformations.length).fill(null));
    }
  }, [croppedCartoons, availableTransformations]);

  const unsavedChanges = useMemo(() => {
    // Calculate the initial values based on playerCardInfo
    const initialPattern = playerCardInfo?.patternIndex ?? null;
    const initialPlayerCardLightColor = playerCardInfo?.playerCardLightColor ?? null;
    const initialPlayerCardDarkColor = playerCardInfo?.playerCardDarkColor ?? null;
    const initialPlayerCardTextColor = playerCardInfo?.playerCardTextColor ?? null;
    const initialSelectedCartoonIndex = playerCardInfo?.selectedCartoonIndex ?? null;
    const initialCartoonUrls = playerCardInfo?.cartoonUrls ?? [];
    const initialCroppedCartoonUrls = playerCardInfo?.croppedCartoonUrls ?? [];

    const initialCroppedCartoons = playerCardInfo?.croppedCartoonUrls?.filter((url) => !!url).length;
    const initialUncroppedCartoons = playerCardInfo?.croppedCartoonUrls?.filter((url) => !url).length;

    const currentCroppedCartoons = croppedCartoons.filter((url) => !!url).length;
    const currentUncroppedCartoons = croppedCartoons.filter((url) => !url).length;

    const selectedPatternData = patterns.find((p) => p.patternIndex === selectedPattern);

    const selectedColorObj = selectedPatternData?.disableColorSelection
      ? selectedPatternData.defaultColors
      : colors[selectedColor];

    if (initialPattern !== selectedPattern) {
      console.log('Pattern changed');
      return true;
    }

    if (!selectedColorObj) {
      return true;
    }

    if (
      initialPlayerCardLightColor !== selectedColorObj.lightColor ||
      initialPlayerCardDarkColor !== selectedColorObj.darkColor ||
      initialPlayerCardTextColor !== selectedColorObj.textColor
    ) {
      console.log('Color changed');
      return true;
    }

    if (initialSelectedCartoonIndex !== selectedCartoonIndex) {
      console.log('Selected cartoon index changed');
      return true;
    }

    // Determine if the avatar collection is unchanged.
    if (
      initialUncroppedCartoons !== currentUncroppedCartoons ||
      initialCroppedCartoons !== currentCroppedCartoons ||
      initialCroppedCartoonUrls.some((url, index) => url !== croppedCartoons[index])
    ) {
      console.log('Cropped cartoon urls changed');
      return true;
    }

    if (photoUrl) {
      if (
        initialCartoonUrls.length !== availableTransformations.length ||
        initialCartoonUrls.some((url, index) => url !== availableTransformations[index].url)
      ) {
        console.log('Cartoon urls changed');
        return true;
      }
    }

    return false;
  }, [croppedCartoons, availableTransformations, selectedCartoonIndex, selectedPattern, selectedColor, playerCardInfo]);

  const {getDownsizedImage} = useGetDownsizedImage();
  const {checkExplicitImage} = useGetExplicitImageCheck();

  const handleCartoonifySelect = useCallback(
    (index: number) => {
      setCartoonPhotoUrl(croppedCartoons[index] ?? availableTransformations[index].url);
      setSelectedCartoonIndex(index);
    },
    [croppedCartoons, availableTransformations]
  );

  const handlePhotoSelect = useCallback(
    async (dataUrl: string) => {
      if (!dataUrl) {
        console.log('Invalid photo dataUrl');
        return;
      }

      try {
        setIsProcessingSelectedPhoto(true);
        // First check if image is explicit
        const {isExplicit} = await checkExplicitImage({imageUrl: dataUrl});
        if (isExplicit) {
          toast.error('The selected photo contains inappropriate content and cannot be used.');
          setIsProcessingSelectedPhoto(false);
          return;
        }

        const photoId = getPhotoId(dataUrl);
        queryClient.invalidateQueries({
          queryKey: generateCartoonStylesQueryKey(photoId),
        });

        const imageSizeInMbs = getImageSizeInMbs(dataUrl);
        if (imageSizeInMbs <= DOWNSIZE_THRESHOLD_MBS) {
          setPhotoUrl(dataUrl);
          setCartoonPhotoUrl(dataUrl);
          setSelectedCartoonIndex(0);
          setCartoons([]);
          setCroppedCartoons(Array(availableTransformations.length).fill(null));
          setCurrentStep(Step.CARTOON);
          return;
        }

        const {downsized, imageUrl: downsizedImage} = await getDownsizedImage({imageUrl: dataUrl});

        if (downsized && downsizedImage) {
          setPhotoUrl(downsizedImage);
          setCartoonPhotoUrl(downsizedImage);
        } else {
          setPhotoUrl(dataUrl);
          setCartoonPhotoUrl(dataUrl);
        }
        setSelectedCartoonIndex(0);
        setCartoons([]);
        setCroppedCartoons(Array(availableTransformations.length).fill(null));
        setCurrentStep(Step.CARTOON);
      } catch (error) {
        console.error('Error processing photo:', error);
        toast.error('Failed to process photo. Please try again.');
      } finally {
        setIsProcessingSelectedPhoto(false);
      }
    },
    [queryClient, getDownsizedImage, checkExplicitImage]
  );

  const handlePatternSelect = useCallback((patternId: number) => {
    console.log('handlePatternSelect', patternId);
    setSelectedPattern(patternId);
  }, []);

  const handleColorSelect = useCallback((colorId: number) => {
    console.log('handleColorSelect', colorId);
    setSelectedColor(colorId);
  }, []);

  const handleConfirm = useCallback(async () => {
    if (!cartoonPhotoUrl) {
      console.log('No avatar selected!');
      setError('No avatar selected!');
      return;
    }

    if (!unsavedChanges) {
      console.log('No changes made. Skipping update.');
      navigate('/home');
      return;
    }

    try {
      setIsLoading(true);
      console.log('selectedColor', selectedColor);

      const selectedPatternData = patterns.find((p) => p.patternIndex === selectedPattern);
      if (!selectedPatternData) {
        throw new Error('Selected pattern not found');
      }

      // Get color values based on whether the pattern has disabled color selection
      const colorValues =
        selectedPatternData.disableColorSelection && selectedPatternData.defaultColors
          ? {
              playerCardLightColor: selectedPatternData.defaultColors.lightColor,
              playerCardDarkColor: selectedPatternData.defaultColors.darkColor,
              playerCardTextColor: selectedPatternData.defaultColors.textColor,
            }
          : {
              playerCardLightColor: colors[selectedColor].lightColor,
              playerCardDarkColor: colors[selectedColor].darkColor,
              playerCardTextColor: colors[selectedColor].textColor,
            };

      await updatePlayerCard({
        patternIndex: selectedPattern,
        ...colorValues,
        cartoonUrls: photoUrl ? availableTransformations.map((t) => t.url) : [],
        croppedCartoonUrls: croppedCartoons,
        selectedCartoonIndex,
        imageSrc:
          photoUrl || croppedCartoons[selectedCartoonIndex] ? null : availableTransformations[selectedCartoonIndex].url,
      });
    } catch (error) {
      console.log('Error during update:', error);
      setError('Failed to save Player Card Info. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [
    cartoonPhotoUrl,
    selectedCartoonIndex,
    selectedPattern,
    selectedColor,
    playerCardInfo,
    photoUrl,
    availableTransformations,
    croppedCartoons,
    unsavedChanges,
    updatePlayerCard,
    navigate,
    patterns,
    colors,
  ]);

  const canGoBack = useCallback(() => {
    if (currentStep === Step.PHOTO) {
      return (
        (playerCardInfo?.patternIndex || playerCardInfo?.patternIndex === 0) &&
        playerCardInfo?.playerCardLightColor &&
        playerCardInfo?.playerCardDarkColor &&
        playerCardInfo?.playerCardTextColor &&
        playerCardInfo?.croppedCartoonUrls?.length
      );
    }
    return true;
  }, [currentStep, playerCardInfo]);

  const canGoNext = useCallback(() => {
    if (currentStep === Step.PHOTO) {
      return true;
    }
    if (currentStep === Step.CARTOON) {
      return !!cartoonPhotoUrl;
    }
    if (currentStep === Step.PATTERN_COLOR) {
      return hasEnoughInfo && !isLoading;
    }
    return false;
  }, [currentStep, cartoonPhotoUrl, hasEnoughInfo, isLoading]);

  const getNextStep = useCallback(() => {
    const currentIndex = STEPS.indexOf(currentStep);
    if (currentIndex === STEPS.length - 1) {
      return null;
    }
    return STEPS[currentIndex + 1];
  }, [currentStep]);

  const getPreviousStep = useCallback(() => {
    const currentIndex = STEPS.indexOf(currentStep);
    if (currentIndex === 0) {
      return null;
    }
    return STEPS[currentIndex - 1];
  }, [currentStep]);

  const handleNext = useCallback(() => {
    if (currentStep === Step.PATTERN_COLOR) {
      throw new Error('Next step not allowed');
    }
    if (!canGoNext()) {
      return;
    }
    const nextStep = getNextStep();
    if (nextStep) {
      if (currentStep === Step.PHOTO) {
        if (!selectedCartoonIndex && selectedCartoonIndex !== 0) {
          setSelectedCartoonIndex(0);
        }
        if (!cartoonPhotoUrl) {
          setCartoonPhotoUrl(photoUrl ? photoUrl : fallbackCartoons?.[0] ?? null);
        }
      }
      setCurrentStep(nextStep);
    }
  }, [
    currentStep,
    photoUrl,
    cartoonPhotoUrl,
    selectedCartoonIndex,
    fallbackCartoons,
    setSelectedCartoonIndex,
    setCartoonPhotoUrl,
    getNextStep,
    canGoNext,
    setCurrentStep,
  ]);

  const handleBack = useCallback(() => {
    if (!canGoBack()) {
      return;
    }

    const previousStep = getPreviousStep();
    if (currentStep === Step.PHOTO) {
      if (unsavedChanges) {
        setShowConfirmation(true);
      } else {
        navigate('/home');
      }
    } else if (previousStep) {
      setCurrentStep(previousStep);
    }
  }, [currentStep, unsavedChanges, getPreviousStep, navigate, canGoBack]);

  const handleConfirmNavigation = useCallback(() => {
    setShowConfirmation(false);
    navigate('/home');
  }, [navigate]);

  const fallbackCartoonsImages = useMemo(() => getFallbackAvatars(), []);
  useEffect(() => {
    const fetchFallbackCartoons = async () => {
      try {
        const dataUrls = await Promise.all(fallbackCartoonsImages.map((url) => urlToDataUrl(url)));
        setFallbackCartoons(dataUrls);
      } catch (error) {
        console.error('Failed to load fallback images:', error);
      }
    };
    fetchFallbackCartoons();
  }, []);

  const displayedPhotoUrl = useMemo(() => {
    return cartoonPhotoUrl || photoUrl;
  }, [photoUrl, cartoonPhotoUrl]);

  // Handler to open crop modal on preview click
  const handleOpenCropModal = useCallback(() => {
    if (!displayedPhotoUrl) {
      console.log('No photo to crop');
      return;
    }
    setIsCropModalOpen(true);
  }, [displayedPhotoUrl]);

  // Handler to set the cropped image back in state when cropping is done
  const handleCropComplete = useCallback(
    (croppedImage: string) => {
      setCartoonPhotoUrl(croppedImage);
      setCroppedCartoons((prev) => {
        const newCroppedCartoons = [...prev];
        newCroppedCartoons[selectedCartoonIndex] = croppedImage;
        return newCroppedCartoons;
      });
      setIsCropModalOpen(false);
    },
    [selectedCartoonIndex]
  );

  const showRevertOriginalButton = useMemo(() => {
    return !!croppedCartoons?.[selectedCartoonIndex];
  }, [selectedCartoonIndex, availableTransformations, croppedCartoons]);

  // New callback to restore cartoonPhotoUrl to the original value from cartoonStyles[selectedCartoonIndex]
  const handleRevertOriginalFromCrop = useCallback(() => {
    if (availableTransformations[selectedCartoonIndex].url) {
      setCartoonPhotoUrl(availableTransformations[selectedCartoonIndex].url);
    }
    setCroppedCartoons((prev) => {
      const newCroppedCartoons = [...prev];
      newCroppedCartoons[selectedCartoonIndex] = null;
      return newCroppedCartoons;
    });
    // setIsCropModalOpen(false);
  }, [selectedCartoonIndex, availableTransformations]);

  const {getCroppedImage} = useGetCroppedImage();

  return (
    <div className="flex min-h-screen flex-col overflow-auto">
      {setProcessingSelectedPhoto ? (
        <div className="absolute inset-0 z-50 flex flex-col items-center justify-center bg-white bg-opacity-70">
          <div className="w-full text-center">
            <h2 className="text-sm text-mls-grey-xdark xs:text-base">Processing photo. Please wait...</h2>
          </div>
          <div className="mt-4 flex items-center justify-center">
            <Loader />
          </div>
        </div>
      ) : (
        <>
          <AvatarPreview
            photoUrl={displayedPhotoUrl}
            colorIndex={selectedColor}
            patternIndex={selectedPattern}
            onPreviewClick={handleOpenCropModal}
          />
          <div className="w-full">
            {error && (
              <div className="bg-red-100 border-red-400 text-red-700 mb-4 rounded-lg border px-4 py-3">{error}</div>
            )}

            {/* Navigation Arrows */}
            <div className="mt-2 flex items-center justify-between px-1 xs:px-4">
              <Button
                variant="dark"
                onClick={handleBack}
                disabled={!canGoBack()}
                className="pl-3 2xs:text-sm xs:text-lg"
              >
                <div className="flex items-center justify-center">
                  <ArrowIcon direction="left" strokeColor="white" /> Back
                </div>
              </Button>
              <div className="whitespace-nowrap text-center text-sm xs:text-lg">{currentStep}</div>
              {currentStep == Step.PATTERN_COLOR ? (
                <Button
                  label="Done"
                  variant="dark"
                  onClick={handleConfirm}
                  disabled={!canGoNext()}
                  className="2xs:text-sm xs:text-lg"
                />
              ) : (
                <Button
                  variant="dark"
                  onClick={handleNext}
                  disabled={!canGoNext()}
                  className="pr-3 2xs:text-sm xs:text-lg"
                >
                  <div className="flex items-center justify-center">
                    Next <ArrowIcon direction="right" strokeColor="white" />
                  </div>{' '}
                </Button>
              )}
            </div>

            {currentStep === Step.PHOTO && (
              <PhotoSourceSelector onPhotoSelect={handlePhotoSelect} photoPresent={!!photoUrl} />
            )}

            {currentStep === Step.CARTOON && (
              <AvatarCartoonSelector
                selectedIndex={selectedCartoonIndex}
                transformations={availableTransformations}
                onCartoonSelect={handleCartoonifySelect}
                showLoader={isTransformationLoading && !!photoUrl && cartoons.length === 0}
                error={transformationError}
              />
            )}

            {currentStep === Step.PATTERN_COLOR && (
              <PatternAndColorSelector
                patternIndex={selectedPattern}
                colorIndex={selectedColor}
                onPatternSelect={handlePatternSelect}
                onColorSelect={handleColorSelect}
              />
            )}
          </div>

          {/* Confirmation modal appears only when unsaved changes exist and the Back button is clicked on the Photo step */}
          {showConfirmation && (
            <ConfirmationModal
              isOpen={showConfirmation}
              message="Leaving now will discard any progress you made. Do you want to continue?"
              onConfirm={handleConfirmNavigation}
              onCancel={() => setShowConfirmation(false)}
            />
          )}

          {/* Render CropModal when Avatar preview is clicked */}
          {isCropModalOpen && displayedPhotoUrl && (
            <CropModal
              isOpen={isCropModalOpen}
              imageSrc={displayedPhotoUrl}
              onClose={() => setIsCropModalOpen(false)}
              onCropComplete={handleCropComplete}
              getCroppedImage={getCroppedImage}
              onRevertOriginal={showRevertOriginalButton ? handleRevertOriginalFromCrop : null}
            />
          )}
        </>
      )}
    </div>
  );
});

AvatarCreator.displayName = 'AvatarCreator';
export default AvatarCreator;
