import {useQuery, useQueryClient} from '@tanstack/react-query';
import {useCallback, useMemo, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../redux/reduxUtils';
import {resetBookingSessions} from '../../redux/slices/booking';
import {setCurrentProfileId} from '../../redux/slices/profile';
import {Profile} from '../../user/player-info.interface';
import {removeAllPlayerDataFromLocalStorage} from '../../user/user-utils';
import {getLocationBySiteId} from '../../constants/locations';
import {ProfileTeams} from 'user/user.interface';
import {useNavigate} from 'react-router-dom';

export const useProfiles = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const {currentProfileId} = useAppSelector((state) => state.profileReducer);
  const navigate = useNavigate();

  let {data: profiles} = useQuery<Profile[]>(
    ['user', 'profiles'],
    () => queryClient.getQueryData(['user', 'profiles']) ?? [],
    {initialData: []}
  );

  const {data: profileTeams} = useQuery<ProfileTeams>(
    ['user', 'profileTeams'],
    () => queryClient.getQueryData(['user', 'profileTeams']) ?? {},
    {initialData: {}}
  );

  profiles = profiles ?? [];
  profiles = useMemo(
    () =>
      profiles.map((profile) => {
        if (profile?.mboDetails?.siteId) {
          return {
            ...profile,
            location: getLocationBySiteId(profile.mboDetails.siteId),
          };
        }
        return profile;
      }),
    [profiles]
  );

  const currentProfile = useMemo(() => {
    if (profiles?.length > 0) {
      return profiles.find((profile) => profile._id === currentProfileId) ?? profiles[0];
    }
    return undefined;
  }, [currentProfileId, profiles]);

  const currentProfileTeam = useMemo(() => {
    return profileTeams?.[currentProfile?._id ?? ''] ?? {};
  }, [profileTeams, currentProfile]);

  const otherProfiles = useMemo(
    () => (profiles.length <= 1 ? [] : profiles.filter((profile) => profile._id !== currentProfileId)),
    [currentProfileId, profiles]
  );

  const selectProfile = useCallback(
    (id: string) => {
      removeAllPlayerDataFromLocalStorage();
      dispatch(setCurrentProfileId(id));
      dispatch(resetBookingSessions());
    },
    [dispatch]
  );

  const refetchUserAndProfiles = useCallback(() => {
    // There is no query associated with ['user', 'profiles']. The query data for ['user', 'profiles'] is set from
    // the ['user'] query request. So, we need to invalidate the ['user'] query to get the updated profiles.
    void queryClient.invalidateQueries(['user']);
  }, [queryClient]);

  return {
    /*
     * We use non-null assertion here because many existing components expect currentProfile to always exist.
     * While useProfiles can now handle cases with no profiles (for new accounts), we maintain this assertion
     * to prevent breaking changes in components that rely on currentProfile being defined.
     * The actual profile existence check and redirect logic is now handled in AuthContainer.
     */
    currentProfile: currentProfile!,
    otherProfiles,
    selectProfile,
    currentProfileTeam: currentProfileTeam!,
    refetchUserAndProfiles,
  };
};
