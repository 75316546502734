import {Profile} from 'user/player-info.interface';
import tocaLogoBlack from '../../assets/img/icons/toca-logo-black.png';
import avatar1 from '../../assets/img/mls/avatar1.png';
import avatar2 from '../../assets/img/mls/avatar2.png';
import avatar3 from '../../assets/img/mls/avatar3.png';
import avatar4 from '../../assets/img/mls/avatar4.png';
import avatar5 from '../../assets/img/mls/avatar5.png';
import tocaBallBlack200 from '../../assets/img/mls/toca-ball-outline-200x200.png';

// Helper function to convert dataURL to File object
export const dataURLtoFile = async (dataUrl: string, filename: string): Promise<File> => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1] || 'image/jpeg';
  const bstr = atob(arr[1]);
  const u8arr = new Uint8Array(bstr.length);

  for (let i = 0; i < bstr.length; i++) {
    u8arr[i] = bstr.charCodeAt(i);
  }

  // Ensure filename has an extension
  const extension = mime.split('/')[1];
  const filenameWithExt = filename.includes('.') ? filename : `${filename}.${extension}`;

  return new File([u8arr], filenameWithExt, {type: mime});
};

// Helper function to convert URL to File object
export const urlToFile = async (url: string): Promise<File> => {
  if (url.startsWith('data:')) {
    // Generate a unique filename with timestamp
    const timestamp = new Date().getTime();
    return await dataURLtoFile(url, `avatar_${timestamp}`);
  }
  const response = await fetch(url);
  const blob = await response.blob();
  // Ensure we have a valid filename with extension
  const filename = url.split('/').pop() || 'image.jpg';
  const extension = blob.type.split('/')[1];
  const filenameWithExt = filename.includes('.') ? filename : `${filename}.${extension}`;

  return new File([blob], filenameWithExt, {type: blob.type});
};

export const urlToDataUrl = async (url: string): Promise<string> => {
  if (url.startsWith('data:')) {
    return url;
  }

  // Fetch the image from the provided URL
  const response = await fetch(url);
  const blob = await response.blob();

  // Convert the blob to a data URL using FileReader
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob);
  });
};

export const computeImageHash = async (dataUrl: string): Promise<string> => {
  if (!dataUrl.startsWith('data:')) {
    return '';
  }
  // Remove the metadata prefix from the data URL (e.g., "data:image/png;base64,")
  const base64String = dataUrl.split(',')[1];
  // Decode the base64 string
  const binaryString = atob(base64String);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Use the web crypto API to compute a SHA-256 hash of the bytes
  const hashBuffer = await crypto.subtle.digest('SHA-256', bytes);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  // Convert the hash to a hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
};

export const getFallbackAvatars = () => {
  return [tocaLogoBlack, tocaBallBlack200, avatar1, avatar2, avatar3, avatar4, avatar5];
};

export const getImageSizeInMbs = (dataUrl: string) => {
  const base64Data = dataUrl.split(',')[1];
  // Decode the base64 string to get the binary data length in bytes.
  const imageByteSize = atob(base64Data).length;
  return imageByteSize / (1024 * 1024);
};
