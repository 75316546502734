import {PropsWithChildren, useEffect, useCallback} from 'react';
import {useAuthStatus} from '../../firebase/useAuthStatus.hook';
import Splash from '../Splash/Splash';
import {useLocation, useNavigate} from 'react-router-dom';
import {useGetUser} from '../../api/User/useGetUser';
import {auth as fireBaseAuth} from '../../firebase/config';
import {sendEmailVerification} from 'firebase/auth';
import {
  isAdminUser,
  isCoach,
  isCoachUser,
  isDirector,
  isDirectorUser,
  isPlayerUser,
  isTrainer,
  isTrainerUser,
  setPostLoginUri,
} from '../../user/user-utils';
import {useProfiles} from '../../api/User/useProfiles';

const noEmailVerificationRequiredPaths = ['/programs'];

/**
 * Renders children only if there is an authenticated user and user details are available.
 */
export default function AuthContainer({children}: PropsWithChildren) {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {loggedIn, checkingStatus: isCheckingAuthStatus} = useAuthStatus();
  const {isLoading: isUserLoading, data: user} = useGetUser();
  const {currentProfile} = useProfiles();

  const firebaseUser = fireBaseAuth.currentUser;
  const _isAdmin = isAdminUser(user);
  const _isTrainer = isTrainerUser(user);
  const _isCoach = isCoachUser(user);
  const _isDirector = isDirectorUser(user);
  const emailVerificationRequired =
    firebaseUser &&
    !firebaseUser.emailVerified &&
    !noEmailVerificationRequiredPaths.some((path) => pathname.startsWith(path));

  useEffect(() => {
    if ((!loggedIn && !isCheckingAuthStatus) || (!isUserLoading && !user)) {
      if (window.location.pathname !== '/') {
        // Store the original requested URI in local storage so that we can redirect the user there after login
        const requestedUri = window.location.pathname + window.location.search;
        setPostLoginUri(requestedUri);
      }
      navigate('/auth');
    } else if (!isUserLoading && user && !currentProfile && !pathname.includes('/create-profile')) {
      // Redirect to profile creation if authenticated but no profile exists
      console.log('Redirecting to profile creation');
      navigate('/create-profile');
    } else {
      if (_isAdmin && !window.location.pathname.startsWith('/admin')) {
        console.debug('Detected admin. Redirecting to /admin');
        navigate('/admin');
      } else if (_isTrainer && isTrainer(currentProfile) && !window.location.pathname.startsWith('/trainer')) {
        console.debug('Detected trainer. Redirecting to /trainer');
        navigate('/trainer');
      } else if (_isCoach && isCoach(currentProfile) && !window.location.pathname.startsWith('/coach')) {
        console.debug('Detected coach. Redirecting to /coach');
        navigate('/coach');
      } else if (_isDirector && isDirector(currentProfile) && !window.location.pathname.startsWith('/director')) {
        console.debug('Detected director. Redirecting to /director');
        navigate('/director');
      }
    }
  }, [
    pathname,
    loggedIn,
    isCheckingAuthStatus,
    isUserLoading,
    user,
    currentProfile,
    navigate,
    _isAdmin,
    _isTrainer,
    _isCoach,
    _isDirector,
  ]);

  useEffect(() => {
    const sendVerificationEmail = async () => {
      if (emailVerificationRequired) {
        if (window.location.pathname !== '/') {
          const requestedUri = window.location.pathname + window.location.search;
          setPostLoginUri(requestedUri);
        }

        try {
          await sendEmailVerification(firebaseUser);
        } catch (error) {
          console.error(error);
        }

        navigate('/verify-email');
      }
    };

    sendVerificationEmail();
  }, [navigate, emailVerificationRequired]);

  if (isCheckingAuthStatus || isUserLoading) {
    return <Splash />;
  }

  if (!user || !firebaseUser || emailVerificationRequired) {
    return null;
  }

  if (_isAdmin || _isTrainer) {
    console.debug(`[AuthContainer] Skipping user completeness check for ${_isAdmin ? 'admin' : 'trainer'} user`);
    return <>{children}</>;
  }

  // Removed UserCompletenessCheck component for now since our user flows have changed significantly and the user check
  // is no longer appropriate in all situations (e.g. when a new user is trying to book a first session/class).
  // return <UserCompletenessCheck>{children}</UserCompletenessCheck>;
  return <>{children}</>;
}
