import {useQuery} from '@tanstack/react-query';
import {getPlayerAppointments} from 'api/api';

export interface GetPlayerAppointmentsParams {
  includeSessionSummary?: boolean;
  includePastAppointments?: boolean;
  includeFutureAppointments?: boolean;
  pastAppointmentsOrder?: string;
  pastAppointmentsLimit?: number;
  pastAppointmentsSkip?: number;
  futureAppointmentsOrder?: string;
  futureAppointmentsLimit?: number;
  futureAppointmentsSkip?: number;
  sessionSummariesOrder?: string;
  sessionSummariesLimit?: number;
  sessionSummariesSkip?: number;
}

export interface Appointment {
  _id: string;
  appointmentId: string;
  siteId: string;
  staffId: string;
  clientId: string;
  locationId: number;
  providerId: string;
  startDateTime: string; // ISO string format
  endDateTime: string; // ISO string format
  durationMinutes: number;
  status: string;
  notes: string;
  genderRequested: string;
  resources?: Array<{
    id: number;
    name: string;
  }> | null;
  isConfirmed?: boolean;
  hasArrived?: boolean;
  clientFirstName?: string;
  clientLastName?: string;
  clientEmail?: string;
  clientPhone?: string;
  staffFirstName?: string;
  staffLastName?: string;
  formulaNotes?: string;
  icdCodes?: Array<{
    code: string;
    description: string;
  }>;
  staffRequested?: boolean;
  programId?: string;
  sessionTypeId?: number;
  isFirstAppointment?: boolean;
  isWaitlist?: boolean;
  waitlistEntryId?: number | null;
  clientServiceId?: number | null;
  addons?: Array<{id: number; name: string; staffId: string; typeId: string}> | null;
  onlineDescription?: string;
  sessionTypeName?: string;
  completed?: boolean;
  sessionDataReceived?: boolean;
  createdAt: string; // ISO string format
  updatedAt: string; // ISO string format
}

export interface SessionSummary {
  session: {
    speedOfPlay: number;
    totalGoalsScored: number;
    accuracy: number;
    streak: number;
    mph: number;
    quality_pass: {
      goodPass: number;
      badPass: number;
    };
    totalBallsFired: number;
    repetitions: number;
  };
  exercises: Array<{
    quality_pass: {
      goodPass: number;
      badPass: number;
    };
    _id: string;
    name: string;
    speedOfPlay?: number;
    totalGoalsScored: number;
    accuracy: number;
    streak: number;
    mph?: number;
    confirmedBy: string;
  }>;
}

export interface AppointmentResponse {
  pastAppointments: {
    appointments: Appointment[];
    totalCount: number;
    skip: number;
    limit: number;
  };
  futureAppointments: {
    appointments: Appointment[];
    totalCount: number;
    skip: number;
    limit: number;
  };
  sessionSummaries: {
    summaries: Array<{
      _id: string;
      startDateTime: Date;
      endDateTime: Date;
      siteId: string;
      durationMinutes: number;
      appointmentIds: number[];
      summary: SessionSummary;
      isBaselineSession: boolean;
      totalBallsFired: number;
    }>;
    totalCount: number;
    skip: number;
    limit: number;
  };
}

export const useGetPlayerAppointments = ({
  playerProfileId,
  includeSessionSummary = true,
  includePastAppointments = false,
  includeFutureAppointments = true,
  pastAppointmentsLimit,
  pastAppointmentsSkip,
  pastAppointmentsOrder = 'desc',
  futureAppointmentsLimit,
  futureAppointmentsSkip,
  futureAppointmentsOrder = 'asc',
  sessionSummariesLimit,
  sessionSummariesSkip,
  sessionSummariesOrder = 'desc',
}: GetPlayerAppointmentsParams & {playerProfileId: string}) => {
  return useQuery<AppointmentResponse>({
    queryKey: [
      'player-appointments',
      playerProfileId,
      pastAppointmentsLimit,
      pastAppointmentsSkip,
      includeSessionSummary,
      includeFutureAppointments,
      pastAppointmentsOrder,
      pastAppointmentsLimit,
      pastAppointmentsSkip,
      futureAppointmentsLimit,
      futureAppointmentsSkip,
      futureAppointmentsOrder,
      sessionSummariesLimit,
      sessionSummariesSkip,
      sessionSummariesOrder,
    ],
    queryFn: () =>
      getPlayerAppointments({
        includeSessionSummary,
        includePastAppointments,
        pastAppointmentsLimit,
        pastAppointmentsSkip,
        pastAppointmentsOrder,
        includeFutureAppointments,
        futureAppointmentsOrder,
        futureAppointmentsLimit,
        futureAppointmentsSkip,
        sessionSummariesLimit,
        sessionSummariesSkip,
        sessionSummariesOrder,
      }),
  });
};
