import {memo, useEffect, useState} from 'react';
import styles from './RegistrationLandingForm.module.scss';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useAppSelector} from '../../redux/reduxUtils';
import {logAnalyticsEvent} from '../../common/analytics-events';
import {sendFkoHubspotData} from 'common/fko-utils';
import {isLocationPermitted, locationLockEnabled} from '../../feature-flags/fko-locations';
import {getLocationBySiteId} from '../../constants/locations';
import {FormInput} from '../../components/Forms/FormInput/FormInput';
import {FormButton} from '../../components/Forms/FormButton/FormButton';
import {FormOptionGroup, FormSelect} from '../../components/Forms/FormSelect/FormSelect';
import {useGetSport} from 'common/useGetSport';
import {useGetLocationFormOptionGroups} from 'api/FKO/useGetLocationFormOptionGroups';
import {GetCachedUserExists} from 'api/FKO/useGetCachedUserExists';
import Spinner from 'components/Loader/Spinner';
import {CLUB_NAMES, PlayerClubSearchableList} from './PlayerClubSearchableList';
import {isDevelopmentEnvironment} from 'common/isTestEnvironment';

export type RegistrationFormTypes = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  location: string;
  dob?: string;
  club?: string; // MLS Next Fest
};

export const PHONE_REGEX = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

export const FkoRegistrationLandingForm = memo(
  ({
    errorMessage,
    isLoading,
    onSubmitHandler,
    getCachedUserExists,
  }: {
    errorMessage?: string;
    isLoading?: boolean;
    onSubmitHandler: SubmitHandler<RegistrationFormTypes>;
    getCachedUserExists: GetCachedUserExists;
  }) => {
    const {initialParams} = useAppSelector((state) => state.fkoFormDataReducer);
    const {
      isDbat,
      isPlayerAssessment,
      isClasses,
      isFreeTrialClasses,
      fkoMethod,
      fkoAnalyticsPrefix,
      sportPermittedLocations,
    } = useGetSport();
    const {locationFormOptionGroups} = useGetLocationFormOptionGroups();

    const includeDobField = isClasses; // || isFreeTrialClasses;
    const [showDobInput, setShowDobInput] = useState(false);
    const [autoFocusDobInput, setAutoFocusDobInput] = useState(false);

    const presetLocation =
      initialParams?.locationId &&
      isLocationPermitted({
        locationId: initialParams.locationId,
        isDbat,
        isPlayerAssessment,
        isClasses,
        isFreeTrialClasses,
      })
        ? getLocationBySiteId(initialParams.locationId)
        : undefined;

    const showDateInputAndFocus = () => {
      setAutoFocusDobInput(true);
      setShowDobInput(true);
    };

    const emailOnBlurHandler: React.FocusEventHandler<HTMLInputElement> = (e) => {
      // sends Hubspot form and also checks for an existing user
      yup
        .string()
        .email('Email is invalid')
        .matches(/^[^+]+$/, 'Email must not contain "+" signs')
        .validate(e.currentTarget.value)
        .then((validatedEmail) => {
          if (validatedEmail) {
            const lowerCaseValidatedEmail = validatedEmail.toLowerCase();
            const values = getValues();
            sendFkoHubspotData({
              alternateProfileData: {
                firstName: values.firstName,
                lastName: values.lastName,
                phoneNumber: values.phoneNumber,
                email: lowerCaseValidatedEmail,
              },
              initialParams,
              locationId: values.location !== 'disabled-placeholder' ? values.location : undefined,
              fkoMethod,
              isPlayerAssessment,
            });

            const siteId = initialParams.locationId ?? values.location;
            // send request to check user and fko eligibility. promise handled during form submit.
            if (siteId && siteId !== 'disabled-placeholder') {
              void getCachedUserExists({
                email: lowerCaseValidatedEmail,
                siteId,
                sessionTypeNames: initialParams.sessionTypeNames,
              });
            }
          }
        })
        .catch(() => {
          // field blur with invalid email, do nothing
        });
    };

    const restrictedLocationFormOptionGroups = locationFormOptionGroups
      .map((optionGroup) => {
        // TODO CLASSES this logic *should* be redundant with useGetLocationFormOptionGroups
        const allowedOptions = optionGroup.options.filter((opt) =>
          isLocationPermitted({locationId: opt.id, isClasses, isFreeTrialClasses, isDbat, isPlayerAssessment})
        );
        if (!allowedOptions.length) {
          return null;
        }
        return {...optionGroup, options: allowedOptions};
      })
      .filter((optionGroup) => !!optionGroup);

    const formFieldConfig: any = {
      email: yup
        .string()
        .email('Email is invalid')
        .required('Email is required')
        .matches(/^[^+]+$/, 'Email must not contain "+" signs'),
      firstName: yup
        .string()
        .trim('Name cannot include leading or trailing spaces')
        .required('Please enter your first name'),
      lastName: yup
        .string()
        .trim('Name cannot include leading or trailing spaces')
        .required('Please enter your last name'),
      phoneNumber: yup
        .string()
        .required('Phone number is required')
        .matches(PHONE_REGEX, 'Must be a valid phone number'),
      club: yup
        .string()
        // .required('Required')
        .test('club', 'Please enter or select your club name', (val) => {
          return val ? CLUB_NAMES.includes(val) : true;
        }),
    };

    if (showDobInput) {
      formFieldConfig.dob = yup.date().typeError('Required').required('Required');
    }
    if (!presetLocation && !locationLockEnabled) {
      formFieldConfig.location = yup
        .string()
        .required('Required')
        .notOneOf(['disabled-placeholder'], 'Please choose a location');
    }
    if (locationLockEnabled && sportPermittedLocations) {
      formFieldConfig.location = yup
        .string()
        .required('Required')
        .notOneOf(['disabled-placeholder'], 'Please choose a location')
        .oneOf(sportPermittedLocations, 'Sorry this location is not yet supported');
    }

    const {
      register,
      handleSubmit,
      setValue,
      reset,
      formState: {errors},
      getValues,
    } = useForm<RegistrationFormTypes>({
      mode: 'onBlur',
      resolver: yupResolver(yup.object().shape(formFieldConfig)),
    });

    const includeClubField =
      isPlayerAssessment &&
      (isDevelopmentEnvironment ||
        (presetLocation
          ? !!presetLocation.includeAssessmentClubField
          : !!getLocationBySiteId(getValues('location'))?.includeAssessmentClubField));

    useEffect(() => {
      logAnalyticsEvent(`${fkoAnalyticsPrefix}_registration_landing_form_view`);
    }, [fkoAnalyticsPrefix]);

    useEffect(() => {
      reset();
    }, [presetLocation, reset]);

    // Update form fields with initialParams values if they exist.
    // During mounting phase initialParams are still empty, so we set them on the next render when they are available.
    useEffect(() => {
      const fieldsMapping: Record<string, keyof typeof initialParams> = {
        firstName: 'firstname',
        lastName: 'lastname',
        email: 'email',
        phoneNumber: 'phone',
      };
      Object.entries(fieldsMapping).forEach(([formField, paramKey]) => {
        const value = initialParams[paramKey];
        if (typeof value === 'string') {
          setValue(formField as keyof RegistrationFormTypes, value);
        }
      });
    }, [initialParams, setValue]);

    let firstNamePlaceholder = 'Parent/Guardian: First Name';
    let lastNamePlaceholder = 'Parent/Guardian: Last Name';

    if (isPlayerAssessment) {
      firstNamePlaceholder = 'First Name (Player)';
      lastNamePlaceholder = 'Last Name (Player)';
    }

    return (
      <form onSubmit={handleSubmit(onSubmitHandler)} className="grow w-full">
        <div data-form-section="center-name" className={styles.userInfoWrapper}>
          {presetLocation && <div className={styles.userInfo}>Your center: {presetLocation.name}</div>}
        </div>

        <div className="form-fields-wrapper">
          <FormInput
            spellCheck="false"
            {...register('firstName')}
            type="text"
            id="firstName"
            placeholder={firstNamePlaceholder}
            hasError={!!errors.firstName?.message}
          />
          <p className="text-xs text-alert mb-1">{errors.firstName?.message ?? '\u00A0'}</p>

          <FormInput
            spellCheck="false"
            {...register('lastName')}
            type="text"
            id="lastName"
            placeholder={lastNamePlaceholder}
            hasError={!!errors.lastName?.message}
          />
          <p className="text-xs text-alert mb-1">{errors.lastName?.message ?? '\u00A0'}</p>

          <FormInput
            spellCheck="false"
            {...register('email', {setValueAs: (val) => val.toLowerCase()})}
            type="email"
            id="email"
            placeholder="Email"
            hasError={!!errors.email?.message}
            onBlur={emailOnBlurHandler}
          />
          <p className="text-xs text-alert mb-1">{errors.email?.message ?? '\u00A0'}</p>

          <FormInput
            spellCheck="false"
            {...register('phoneNumber')}
            type="tel"
            id="phoneNumber"
            placeholder="Phone Number"
            hasError={!!errors.phoneNumber?.message}
          />
          <p className="text-xs text-alert mb-1">{errors.phoneNumber?.message ?? '\u00A0'}</p>

          {/* Number input placeholder and actual input */}
          {/* Date input order is controlled by client localization. don't put in placeholder. */}
          {includeDobField && !showDobInput && (
            <>
              <FormInput spellCheck="false" type="text" placeholder="Date of Birth" onFocus={showDateInputAndFocus} />
              <p className="text-xs text-alert mb-1">{errors.dob?.message ?? '\u00A0'}</p>
            </>
          )}
          {includeDobField && showDobInput && (
            <>
              <FormInput
                spellCheck="false"
                {...register('dob')}
                type="date"
                id="dob"
                placeholder="Player Date of Birth"
                autoFocus={autoFocusDobInput}
                hasError={!!errors.dob?.message}
                min="1900-01-01"
                max={new Date().toISOString().split('T')[0]}
              />
              <p className="text-xs text-alert mb-1">{errors.dob?.message ?? '\u00A0'}</p>
            </>
          )}

          {!presetLocation ? (
            <>
              <FormSelect
                {...register('location')}
                id="location"
                hasError={!!errors.location}
                // this will (confusingly) only be true when an invalid locationId param has been passed in
                optionGroups={
                  initialParams.locationId
                    ? locationFormOptionGroups
                    : (restrictedLocationFormOptionGroups as FormOptionGroup[])
                }
                placeholderValue={isDbat ? 'D-BAT Training Center' : 'TOCA Training Center'}
                defaultValue={'disabled-placeholder'}
                onChange={(e) => setValue('location', e.currentTarget.value, {shouldValidate: true})}
              />
              <p className="text-xs text-alert mb-1">{errors.location?.message ?? '\u00A0'}</p>
            </>
          ) : (
            <input type="hidden" {...register('location')} value={presetLocation.siteId} />
          )}

          {includeClubField && (
            <PlayerClubSearchableList
              register={register}
              setValue={setValue}
              hasError={!!errors.club?.message}
              errorMessage={errors.club?.message}
            />
          )}
        </div>

        <div className="h-[13px] block" />

        <p className="text-xs text-alert mb-1">{errorMessage ?? '\u00A0'}</p>

        <div className="h-[13px] block" />

        {isLoading ? (
          <div className="flex items-center justify-center w-full h-[48px] rounded-[12px] bg-primary">
            <Spinner darkMode />
          </div>
        ) : (
          <FormButton type="submit" isPrimary hasError={Object.keys(errors).length !== 0}>
            CONTINUE
          </FormButton>
        )}

        <div className="flex justify-center my-[20px]">
          <p className="text-[10px] text-[#aaa] text-center">
            This site is protected by reCAPTCHA and the Google{' '}
            <a
              className="underline text-[#999]"
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              className="underline text-[#999]"
              href="https://policies.google.com/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>{' '}
            apply.
          </p>
        </div>
        {/* </div> */}
      </form>
    );
  }
);
FkoRegistrationLandingForm.displayName = 'FkoRegistrationLandingForm';
