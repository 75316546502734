import axios from 'axios';
import {player} from 'api/api';

export interface ExplicitImageCheckResponse {
  isExplicit: boolean;
  flagged?: {[key: string]: string};
}

export const getExplicitImageCheck = async (imageUrl: string): Promise<ExplicitImageCheckResponse> => {
  const {data} = await axios.post(
    `${player}/image/check-explicit`,
    {
      imageUrl,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  return data;
};
