import {auth as fireBaseAuth} from './config';
import {linkPlayerToAccount} from 'api/api';

export async function simpleLogout(reason?: string) {
  await silentLogout();
  window.location.replace(`/auth${reason ? '?reason=' + reason : ''}`);
}

export async function silentLogout() {
  await fireBaseAuth.signOut();
  localStorage.clear();
}

export async function handlePostSignUp(userCredential: any): Promise<string> {
  const isNewAccount = userCredential._tokenResponse.isNewUser;
  const route = '/home';
  if (isNewAccount) {
    await linkPlayerToAccount();
  }

  return route;
}
