import {useQuery} from '@tanstack/react-query';
import {getImageTransformations, ImageTransformation} from './getImageTransformations';

export const generateImageTransformationsQueryKey = (originalPhotoId: string) => {
  return ['avatar', 'transformations', originalPhotoId];
};

export const getPhotoId = (photoUrl: string) => {
  if (photoUrl.startsWith('data:')) {
    return photoUrl.split('/').pop()?.slice(0, 100) ?? '';
  }
  return photoUrl.split('/')?.pop() ?? '';
};

export const useGetImageTransformations = (photoUrl: string | null, enabled: boolean, onQuery?: () => void) => {
  return useQuery<ImageTransformation[]>({
    queryKey: generateImageTransformationsQueryKey(photoUrl || ''),
    queryFn: async () => {
      onQuery?.();
      const result = await getImageTransformations(photoUrl || '');
      return result;
    },
    enabled: enabled && !!photoUrl,
  });
};
