import {useEffect, useRef} from 'react';
import {createPortal} from 'react-dom';
import {ReactComponent as MyTocaLogoWhite} from '../../assets/img/my-toca-logo-white.svg';
import './Splash.style.scss';
import Spinner from '../Loader/Spinner';

const splashRoot = document.querySelector('#splash') as HTMLElement | null;

const Splash = () => {
  const el = useRef(document.createElement('div'));

  useEffect(() => {
    // Use this in case CRA throws an error about react-hooks/exhaustive-deps
    const current = el.current;

    if (splashRoot) {
      splashRoot.appendChild(current);
      return () => void splashRoot.removeChild(current);
    }
  }, []);

  return createPortal(
    <div className="splash-container">
      <div className="flex bg-black text-white h-screen w-screen items-center justify-center px-6">
        <div className="flex flex-col items-center w-full max-w-md space-y-24 justify-center">
          <MyTocaLogoWhite className="w-64 md:w-80 h-20 md:h-24 object-cover" />
          <Spinner darkMode />
        </div>
      </div>
    </div>,
    el.current
  );
};

export default Splash;

// function OldSplash() {
//   return (
//     <div className="splash-container">
//       <MyTocaLogo className="toca-splash-logo" />
//       <div className="toca-spinning-ball-container">
//         <img src="/images/soccer-ball-animated-white-bg.gif" alt="TOCA spinning soccer ball" />
//       </div>
//     </div>
//   );
// }
