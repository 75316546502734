import {Button} from 'components/MLS/Button/Button';
import {useState, useRef, ChangeEvent} from 'react';
import Webcam from 'react-webcam';
import {toast} from 'react-toastify';

const MAX_FILE_SIZE_MBS = 10;

interface PhotoSourceSelectorProps {
  photoPresent: boolean;
  onPhotoSelect: (dataUrl: string) => void;
}

const PhotoSourceSelector = ({photoPresent, onPhotoSelect}: PhotoSourceSelectorProps) => {
  const [cameraActive, setCameraActive] = useState(false);
  const webcamRef = useRef<Webcam>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleCapture = () => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      // Extract the base64-encoded part of the data URL.
      const base64Data = imageSrc.split(',')[1];
      // Decode the base64 string to get the binary data length in bytes.
      const imageByteSize = atob(base64Data).length;
      if (imageByteSize > MAX_FILE_SIZE_MBS * 1024 * 1024) {
        setCameraActive(false);
        toast.error(`Photo exceeds the maximum allowed limit of ${MAX_FILE_SIZE_MBS}MBs.`);
        return;
      }
      onPhotoSelect(imageSrc);
      setCameraActive(false);
    }
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      console.log('file.type', file.type);
      // Validate file is an image before proceeding
      if (!file.type.startsWith('image/')) {
        toast.error('Please upload only images.');
        return;
      }
      // Check if the file size exceeds 5MB
      if (file.size > MAX_FILE_SIZE_MBS * 1024 * 1024) {
        toast.error(`Photo exceeds the maximum allowed limit of ${MAX_FILE_SIZE_MBS}MBs.`);
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          onPhotoSelect(e.target.result as string);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center gap-6">
      <h2 className="mt-4 px-4 text-center text-sm text-mls-grey-xdark xs:text-base">
        Upload or take a photo to create your avatar
      </h2>
      <div className="flex flex-col gap-4">
        {cameraActive ? (
          <div className="fixed left-0 top-0 z-100 flex h-full w-full flex-col items-center bg-black">
            <Webcam
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{facingMode: 'user'}}
              className="h-full w-full"
            />
            <div className="absolute bottom-12 mb-4 flex flex-col gap-4">
              <Button label="Capture Photo" onClick={handleCapture} className="2xs:text-base xs:text-lg" />
              <Button label="Cancel" onClick={() => setCameraActive(false)} className="2xs:text-base xs:text-lg" />
            </div>
          </div>
        ) : (
          <div className="mb-4 flex flex-col gap-4">
            <Button
              variant="dark"
              label="Take Photo"
              onClick={() => setCameraActive(true)}
              className="2xs:text-sm xs:text-lg"
            />

            <input type="file" accept="image/*" onChange={handleFileUpload} ref={fileInputRef} hidden />
            <Button
              variant="dark"
              label={photoPresent ? 'Upload new Photo' : 'Upload Photo'}
              onClick={() => fileInputRef.current?.click()}
              className="2xs:text-sm xs:text-lg"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PhotoSourceSelector;
