import {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import styles from './VerifyEmail.module.scss';
import {auth as fireBaseAuth} from '../../firebase/config';
import {sendEmailVerification} from 'firebase/auth';
import {getPostLoginRedirectUri, refreshAccessToken} from '../../user/user-utils';
import {useGetUser} from '../../api/User/useGetUser';
import LoaderFullPage from '../LoaderFullPage/LoaderFullPage';
import {toast} from 'react-toastify';
import {MLSButton} from 'components/Buttons/MLSButton';

export const VerifyEmail = () => {
  const navigate = useNavigate();
  const {data: user} = useGetUser();
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [isVerifyingEmail, setIsVerifyingEmail] = useState(false);
  if (!user) {
    return <LoaderFullPage />;
  }

  const doSendEmailVerification = async () => {
    try {
      setIsSendingEmail(true);
      await sendEmailVerification(fireBaseAuth.currentUser);
      toast.success('We have re-sent the email verification link.', {autoClose: 5000});
    } catch (err) {
      toast.error('We were unable to re-send the email verification link at this time. Please try again later.', {
        autoClose: 5000,
      });
    } finally {
      setIsSendingEmail(false);
    }
  };

  const verifiedEmailVerification = async () => {
    setIsVerifyingEmail(true);
    await fireBaseAuth.currentUser.reload();
    if (fireBaseAuth.currentUser.emailVerified) {
      await refreshAccessToken(); // refresh so that id token reflects verified email
      navigate(getPostLoginRedirectUri());
    } else {
      toast.error('It looks like your email address is still not verified.', {autoClose: 5000});
    }
    setIsVerifyingEmail(false);
  };

  const email = user.account?.email ?? user.profiles?.[0]?.email;

  return (
    <div className="bg-black">
      <div className="flex relative flex-col min-h-screen max-w-lg mx-auto flex-1 text-white items-center md:items-start justify-start px-4">
        <h2 className="text-white uppercase text-2xl font-bold mt-20 md:mt-28">Verify your email address</h2>
        <p className="text-white my-4 text-lg">You&apos;re almost there!</p>

        <div className={styles.container}>
          <p className={styles.info}>
            We sent you an email to <span>{email}</span>. Just click on the link in that email to complete your email
            verification.
          </p>
          <p className={styles.info}>{`If you don't see it, you may need to check your spam folder.`}</p>

          <MLSButton
            onClick={verifiedEmailVerification}
            isLoading={isVerifyingEmail}
            loadingText="Verifying Email..."
            variant="primary"
            text="I have verified my email"
          />

          <MLSButton
            onClick={doSendEmailVerification}
            isLoading={isSendingEmail}
            loadingText="Sending..."
            variant="outline"
            text="Resend verification link"
          />

          <div className={styles.bottomContainer}>
            <p className="text-white text-sm">
              Need help? <Link to="/helpdesk">Contact us</Link>
            </p>
            <p className="text-white text-sm">
              <Link to="/logout">Sign out</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
