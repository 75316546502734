import {Outlet} from 'react-router-dom';
import {PageContainer} from 'components/PageContainer/RevisedPageContainer';
import {Footer} from 'components/Programs/Footer';
import {useAuthStatus} from '../firebase/useAuthStatus.hook';
import {useGetUser} from 'api/User/useGetUser';
import Loader from 'components/MLS/Loader/Loader';

export default function LeaderBoardLayout() {
  const {loggedIn, checkingStatus} = useAuthStatus();
  const {isLoading: isUserAndProfilesLoading} = useGetUser({enabled: loggedIn});
  const isLoading = checkingStatus || (loggedIn && isUserAndProfilesLoading);
  const publicView = !loggedIn;
  return (
    <PageContainer zigzag={false}>
      <div className="flex min-h-[100dvh] flex-col justify-center text-mls-primary">
        {isLoading ? <Loader text="Loading..." /> : <Outlet />}
        {!isLoading && publicView && <Footer />}
      </div>
    </PageContainer>
  );
}
