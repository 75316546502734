import moment from 'moment';
import {Helmet} from 'react-helmet';
import {useNavigate, useParams} from 'react-router-dom';
import {MobileBaselineSessionTable} from 'components/Baseline/SessionTable/MobileBaselineSessionTable';
import {useProfiles} from 'api/User/useProfiles';
import {useGetPlayerSession} from 'api/User/useGetPlayerSession';
import {NewPageHeading} from 'components/MLS/NewPageHeading/NewPageHeading';
import Loader from 'components/MLS/Loader/Loader';

export const BaselineSessionData = () => {
  const navigate = useNavigate();
  const {sessionId} = useParams();
  const {currentProfile} = useProfiles();

  const {data: sessionData, isLoading} = useGetPlayerSession({
    clientId: currentProfile?.mboDetails?.clientId,
    sessionId: sessionId,
    siteId: Number(currentProfile?.mboDetails?.siteId),
  });

  const {startTime, isBaselineSession, baselineLevel = '', totalBallsFired, exercises} = sessionData || {};
  const startDate = startTime ? moment(startTime).format('MMM DD, YYYY') : undefined;

  return (
    <>
      <Helmet>
        <title>MyTOCA | Baseline Session Data</title>
      </Helmet>

      {isLoading ? (
        <div className="flex min-h-screen items-center justify-center">
          <Loader text="Loading..." />
        </div>
      ) : (
        <div className="grow flex flex-col exceed-global-padding">
          <div className="pb-4 xs:px-6">
            <div className="rounded overflow-hidden">
              <div className="text-center">
                <NewPageHeading text="Baseline Session Data" onClickOverride={() => navigate(-1)} />
              </div>
              {isBaselineSession ? (
                <div className="text-lg font-medium uppercase">
                  <p className="p-3 text-center bg-mls-grey border border-mls-grey-dark rounded-t-md font-semibold">{`${baselineLevel} pathway - ${startDate}`}</p>
                  <div className="p-2 bg-mls-grey flex items-center justify-around border-l border-r border-mls-grey-dark">
                    <div className="flex gap-2 items-center justify-center">
                      <p>Balls:</p>
                      <div className="p-3 rounded-full grid place-content-center bg-mls-baseline text-white w-12 h-12">
                        <p className="font-teko font-bold text-lg">{totalBallsFired}</p>
                      </div>
                    </div>
                    <div className="flex gap-2 items-center justify-center">
                      <p>Sets:</p>
                      <div className="p-3 rounded-full grid place-content-center bg-mls-baseline text-white w-12 h-12">
                        <p className="font-teko font-bold text-lg">{exercises?.length}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="grid place-content-center">
                  <p className="text-sm text-grey-xdark text-center p-4">Unable to load session data</p>
                </div>
              )}
              {isBaselineSession && <MobileBaselineSessionTable sessionData={sessionData} isMls={true} />}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
