import {useGetPlayerAppointments} from 'api/MLSChallenge/useGetPlayerAppointments';
import {useProfiles} from 'api/User/useProfiles';
import Loader from 'components/MLS/Loader/Loader';
import {NavHeading} from '../NavHeading/NavSection';
import {Session} from './Session';
import {UpcomingSessions} from './UpcomingSessions';

export const MySessions = () => {
  const {currentProfile} = useProfiles();
  const {data, isLoading} = useGetPlayerAppointments({
    playerProfileId: currentProfile?._id,
    sessionSummariesLimit: 2,
    futureAppointmentsLimit: 6,
  });
  const sessionSummaries = data?.sessionSummaries?.summaries ?? [];
  const futureAppointments = data?.futureAppointments?.appointments ?? [];
  const hasCompletedSessions = sessionSummaries.length > 0;
  return (
    <div className="w-full">
      <NavHeading title="My Sessions" href="/my-sessions" />

      {isLoading ? (
        <div className="flex min-h-40 items-center justify-center">
          <Loader />
        </div>
      ) : (
        <>
          {/* TODO: Separate completed and upcoming sessions */}
          {/* Completed Sessions */}
          {hasCompletedSessions ? (
            <div className="mt-4 space-y-6">
              {sessionSummaries.map((session) => (
                <Session key={session._id} session={session} />
              ))}
            </div>
          ) : (
            <p className="mt-4 min-h-20 rounded-lg bg-mls-grey p-6 text-center text-base">No sessions found</p>
          )}
          {/* Upcoming Sessions */}
          <div className="mt-6">
            <UpcomingSessions appointments={futureAppointments} />
          </div>
        </>
      )}
    </div>
  );
};
