import {useCallback} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {
  DownsizedImageRequestPayload,
  DownsizedImageResponse,
  getDownsizedImage as getDownsizedImageApi,
} from './getDownsizedImage';

export const useGetDownsizedImage = () => {
  const queryClient = useQueryClient();

  const getDownsizedImage = useCallback(
    async (payload: DownsizedImageRequestPayload): Promise<DownsizedImageResponse> => {
      try {
        const response = await queryClient.fetchQuery({
          queryKey: ['downsized-image', payload.imageUrl],
          queryFn: () => getDownsizedImageApi(payload.imageUrl),
        });
        return response;
      } catch (error) {
        console.log('Failed to downsize image', error);
        // return {downsized: false, imageUrl: payload.imageUrl};
        throw error;
      }
    },
    [queryClient]
  );

  return {getDownsizedImage};
};
