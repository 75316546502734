import * as tf from '@tensorflow/tfjs';
import {initializeTFBackend, fileToHTMLImage, urlToFile, formatError} from './utils';

/**
 * Cache the loaded Graph model globally so we only load once.
 */
let cartoonGraphModel: tf.GraphModel | null = null;

const MODEL_CONFIG = {
  INPUT_SIZE: 512,
  NORMALIZATION_OFFSET: 127.5,
  INPUT_TENSOR_NAME: 'input_photo:0',
} as const;

/**
 * Loads your converted TF.js Graph Model if not already loaded.
 */
async function loadCartoonModel(): Promise<tf.GraphModel> {
  if (!cartoonGraphModel) {
    try {
      await initializeTFBackend();
      cartoonGraphModel = await tf.loadGraphModel('/tfmodel/cartoongan.json');
    } catch (error) {
      console.error('Detailed error loading JSON model:', error);
      throw new Error(formatError(error, 'load Graph model'));
    }
  }
  return cartoonGraphModel;
}

/**
 * Process tensor for model input
 */
function preprocessInput(img: tf.Tensor3D): tf.Tensor4D {
  return tf.tidy(() => {
    const offset = tf.scalar(MODEL_CONFIG.NORMALIZATION_OFFSET);
    return tf.image
      .resizeBilinear(img, [MODEL_CONFIG.INPUT_SIZE, MODEL_CONFIG.INPUT_SIZE])
      .reshape([1, MODEL_CONFIG.INPUT_SIZE, MODEL_CONFIG.INPUT_SIZE, 3])
      .sub(offset)
      .div(offset);
  });
}

function processOutput(outputTensor: tf.Tensor): tf.Tensor3D {
  return tf.tidy(() => {
    return outputTensor.squeeze().add(1).div(2).clipByValue(0, 1) as tf.Tensor3D;
  });
}

/**
 * Given a user-uploaded file, runs Cartoon model inference
 * and returns the result as a PNG data URL string.
 */
export async function cartoonifyImage(file: File): Promise<string> {
  let input: tf.Tensor | null = null;
  let outputTensor: tf.Tensor | null = null;

  try {
    const model = await loadCartoonModel();
    const htmlImage = await fileToHTMLImage(file);

    // Convert and normalize input
    const rawInput = tf.browser.fromPixels(htmlImage);
    input = preprocessInput(rawInput);
    rawInput.dispose();

    // Execute model with named input
    outputTensor = model.execute({[MODEL_CONFIG.INPUT_TENSOR_NAME]: input}) as tf.Tensor;
    if (!outputTensor) {
      throw new Error('Model execution failed to produce output');
    }

    // Process output and convert to canvas
    const processedTensor = processOutput(outputTensor);
    const canvas = document.createElement('canvas');
    canvas.width = MODEL_CONFIG.INPUT_SIZE;
    canvas.height = MODEL_CONFIG.INPUT_SIZE;
    await tf.browser.toPixels(processedTensor, canvas);
    processedTensor.dispose();

    return canvas.toDataURL('image/png');
  } catch (error) {
    console.error('Detailed error in cartoonifyImage:', error);
    throw new Error(formatError(error, 'process image'));
  } finally {
    // Clean up tensors
    [input, outputTensor].forEach((tensor) => tensor?.dispose());
  }
}

export async function getCartoonStyles(input: string | File): Promise<string[]> {
  try {
    const file = input instanceof File ? input : await urlToFile(input);
    return Promise.all(
      Array(1)
        .fill(null)
        .map(() => cartoonifyImage(file))
    );
  } catch (error) {
    console.error('Error in getCartoonStyles:', error);
    throw new Error(formatError(error, 'generate cartoon styles'));
  }
}
