import React from 'react';
import {FormSelect, FormOptionGroup} from '../FormSelect/FormSelect';
import {useGetLocationFormOptionGroups} from 'api/FKO/useGetLocationFormOptionGroups';
import {useGetSport} from 'common/useGetSport';
import {isLocationPermitted} from '../../../feature-flags/fko-locations';
import {UseFormRegister, UseFormSetValue} from 'react-hook-form';

enum SPORT_PLACEHOLDER_VALUE {
  DBAT = 'D-BAT Training Center',
  DBAT_75_SWINGS = 'D-BAT 75 Free Swings Center',
  TOCA = 'TOCA Training Center',
}

type LocationFormSelectProps = {
  register: any;
  hasError: boolean;
  setValue: any;
  disabled?: boolean;
  fieldName: string;
  defaultValue?: string;
};

export const LocationFormSelect = ({
  register,
  hasError,
  setValue,
  disabled,
  fieldName,
  defaultValue,
}: LocationFormSelectProps) => {
  const {
    isDbat,
    isPlayerAssessment,
    isClasses,
    isFreeTrialClasses,
    isDbat75Swings,
    isFtcDsr,
    isFreeTrialPickupSoccer,
    isFreeTrialLeagues,
  } = useGetSport();
  const {locationFormOptionGroups} = useGetLocationFormOptionGroups();

  const getRestrictedLocationFormOptionGroups = () => {
    return locationFormOptionGroups
      .map((optionGroup) => {
        const allowedOptions = optionGroup.options.filter((opt) =>
          isLocationPermitted({
            locationId: opt.id,
            isClasses,
            isFreeTrialClasses,
            isDbat,
            isPlayerAssessment,
            isDbat75Swings,
            isFtcDsr,
            isFreeTrialPickupSoccer,
            isFreeTrialLeagues,
          })
        );
        if (!allowedOptions.length) {
          return null;
        }
        return {...optionGroup, options: allowedOptions};
      })
      .filter((optionGroup) => !!optionGroup);
  };

  const restrictedLocationFormOptionGroups = getRestrictedLocationFormOptionGroups();

  return (
    <FormSelect
      {...register(fieldName)}
      id={fieldName}
      optionGroups={restrictedLocationFormOptionGroups as FormOptionGroup[]}
      placeholderValue={
        isDbat75Swings
          ? SPORT_PLACEHOLDER_VALUE.DBAT_75_SWINGS
          : isDbat
          ? SPORT_PLACEHOLDER_VALUE.DBAT
          : SPORT_PLACEHOLDER_VALUE.TOCA
      }
      defaultValue={defaultValue || 'disabled-placeholder'}
      hasError={hasError}
      disabled={disabled}
      onChange={(e) => setValue(fieldName, e.currentTarget.value, {shouldValidate: true})}
    />
  );
};
