import {useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {auth as firebaseAuth} from '../firebase/config';
import {createNewProfile, createInitialProfile} from 'api/api';
import {useProfiles} from '../api/User/useProfiles';
import {UserProfileFormInterface} from '../components/PlayerProfileForm/PlayerProfileForm';

export type ProfileCreationType = 'initial' | 'additional';

export const useCreateProfile = (type: ProfileCreationType = 'additional') => {
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const {selectProfile} = useProfiles();

  const createProfile = async (data: UserProfileFormInterface) => {
    setIsLoading(true);
    try {
      const user = firebaseAuth.currentUser;
      if (!user) {
        throw new Error('User not found');
      }

      const profileData = {
        ...data,
        email: user.email,
        ...(type === 'initial' && user.phoneNumber && {phoneNumber: user.phoneNumber}),
        ...(type === 'initial' && {createMboClient: true}),
      };

      const profile =
        type === 'initial' ? await createInitialProfile(profileData) : await createNewProfile(profileData);

      // Wait for the user query to refetch and get fresh data
      await queryClient.refetchQueries({queryKey: ['user'], type: 'active'});
      selectProfile(profile._id);
      return profile;
    } catch (error) {
      console.error('Error creating profile:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return {createProfile, isLoading};
};
