import {memo} from 'react';

interface ArrowButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  className?: string;
  size?: 'small' | 'medium' | 'large';
  variant?: 'primary' | 'secondary';
  loading?: boolean;
  hasError?: boolean;
  arrowDirection?: 'right' | 'left' | 'up' | 'down';
}

export const ArrowButton = memo(
  ({
    type = 'button',
    onClick,
    className = '',
    size = 'medium',
    variant = 'primary',
    disabled = false,
    loading = false,
    hasError = false,
    arrowDirection = 'right',
    ...rest
  }: ArrowButtonProps) => {
    const sizeClasses = {
      small: 'w-10 h-10',
      medium: 'w-14 h-14',
      large: 'w-16 h-16',
    };

    const variantClasses = {
      primary: 'bg-white text-black',
      secondary: 'bg-black text-white',
    };

    const getRotation = () => {
      switch (arrowDirection) {
        case 'left':
          return 'rotate(180deg)';
        case 'up':
          return 'rotate(-90deg)';
        case 'down':
          return 'rotate(90deg)';
        default:
          return 'rotate(0deg)';
      }
    };

    const isButtonDisabled = disabled || hasError || loading;

    return (
      <button
        type={type}
        onClick={onClick}
        disabled={isButtonDisabled}
        className={`rounded-full flex items-center justify-center p-0 transition-all ${sizeClasses[size]} ${
          variantClasses[variant]
        } ${isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''} ${className}`}
        {...rest}
      >
        {loading ? (
          <svg
            className="animate-spin h-5 w-5 text-current"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373
                 0 0 5.373 0 12h4zm2 5.291l2.928-2.928
                 1.386 1.386L6 20l-4-4 1.414-1.414
                 L6 16.586z"
            />
          </svg>
        ) : (
          <svg
            style={{transform: getRotation()}}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z" fill="currentColor" />
          </svg>
        )}
      </button>
    );
  }
);

ArrowButton.displayName = 'ArrowButton';
