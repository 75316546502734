import {useMemo} from 'react';
import moment from 'moment';
import {TrainingSession} from 'api/User/useGetPlayerDetails';
import {MobileBaselineSessionTable} from 'components/Baseline/SessionTable/MobileBaselineSessionTable';

export const ConnectsBaselineSessionData = ({session}: {session: TrainingSession}) => {
  const startDate = moment(session.startTime).format('MMM DD, YYYY');
  const sessionData = useMemo(() => {
    const performance = session.performance ?? {
      metrics: {
        repetitions: 0,
        accuracy: 0,
        ballSpeed: 0,
        speedOfPlay: 0,
        recoveries: 0,
        streak: 0,
      },
      exercises: [],
    };
    return {
      exercises: Array.isArray(performance.exercises) ? performance.exercises : [],
      baselineLevel: session.baselineLevel ?? '',
      totalBallsFired: session?.totalBallsFired ?? 0,
      repetitions: performance.metrics?.repetitions ?? 0,
    };
  }, [session]);

  return (
    <>
      <p className="p-2 text-lg text-center font-semibold text-secondary">{startDate}</p>
      <div className="flex flex-col lg:rounded lg:shadow-dispersed lg:overflow-hidden">
        <div className="text-lg text-white font-medium uppercase">
          <p className="p-3 text-center bg-secondary">{`${sessionData.baselineLevel} Baseline`}</p>
          <div className="p-3 bg-primary flex items-center justify-around">
            <div className="flex gap-2 items-center justify-center">
              <p>Balls:</p>
              <div className="h-[40px] w-[40px] rounded-full grid place-content-center bg-secondary">
                <p>{sessionData.totalBallsFired}</p>
              </div>
            </div>
            <div className="flex gap-2 items-center justify-center">
              <p>Sets:</p>
              <div className="h-[40px] w-[40px] rounded-full grid place-content-center bg-secondary">
                <p>{sessionData.repetitions}</p>
              </div>
            </div>
          </div>
        </div>
        <MobileBaselineSessionTable sessionData={sessionData} />
      </div>
    </>
  );
};
