import {useEffect, useMemo} from 'react';
import styles from './LeaderboardList.module.scss';
import {LeaderboardPageContainer} from '../LeaderboardPageContainer/LeaderboardPageContainer';
import {ScrollableList} from '../../../components/ScrollableList/ScrollableList';
import {LeaderboardCard} from './LeaderboardCard/LeaderboardCard';
import {useGetLeaderboardList} from '../../../api/Leaderboards/useGetLeaderboardList';
import {useProfiles} from '../../../api/User/useProfiles';
import {NewPageHeading} from 'components/MLS/NewPageHeading/NewPageHeading';
import {useNavigate, useLocation} from 'react-router-dom';
import Loader from 'components/MLS/Loader/Loader';

export const LeaderboardList = () => {
  const navigate = useNavigate();
  const {currentProfile} = useProfiles();
  const {data, isLoading: leaderboardLoading} = useGetLeaderboardList({
    mboClientId: currentProfile.mboDetails?.clientId,
    locationId: currentProfile.mboDetails?.siteId,
  });
  const location = useLocation();
  useEffect(() => {
    if (data?.length === 1) {
      const leaderboardId = data?.[0]?.leaderboard?._id;
      const fromLeaderboard = location.state?.from === `/leaderboards/${leaderboardId}`;
      if (fromLeaderboard) {
        navigate('/');
      } else {
        navigate(`/leaderboards/${data[0].leaderboard._id}`);
      }
    }
  }, [data, location.state, navigate]);

  // const parseLeaderboards = useCallback(
  //   // TODO fix types
  //   (leaderboards: AthleteRankedLeaderboardEntry[], status: Leaderboard['leaderBoard']['status']) =>
  //     // (leaderboards: AthleteRankedLeaderboardEntry[]) =>
  //     leaderboards
  //       .filter((lb) =>
  //         status === 'inProgress' ? !lb.athlete.overallRank : status === 'completed' ? !!lb.athlete.overallRank : false
  //       )
  //       .map((filteredLb) => <LeaderboardCard leaderboard={filteredLb.leaderboard} />),
  //   []
  // );

  // const inProgressCards = useMemo(
  //   () => (data?.length ? parseLeaderboards(data, 'inProgress') : undefined),
  //   [data, parseLeaderboards]
  // );
  // const completedCards = useMemo(
  //   () => (data?.length ? parseLeaderboards(data, 'completed') : undefined),
  //   [data, parseLeaderboards]
  // );
  const leaderboardCards = useMemo(
    () => (data?.length ? data.map((leaderboard) => <LeaderboardCard leaderboard={leaderboard} />) : undefined),
    [data]
  );

  if (leaderboardLoading && !data) {
    return (
      <div className="flex min-h-[100dvh] items-center justify-center">
        <Loader text="Loading..." />
      </div>
    );
  }

  if (!leaderboardLoading && !data?.length) {
    return (
      <LeaderboardPageContainer>
        <NewPageHeading text="Leaderboards" />
        <div className={styles.centeredContainer}>
          <div className={styles.presentationContainer}>
            <h2>No Leaderboards Available</h2>
          </div>
        </div>
      </LeaderboardPageContainer>
    );
  }

  return (
    <LeaderboardPageContainer>
      <NewPageHeading text="Leaderboards" />
      {/* {!inProgressCards?.length && !completedCards?.length ? ( */}
      {!leaderboardCards || !leaderboardCards?.length ? (
        <div className={styles.centeredContainer}>
          <div className={styles.presentationContainer}>
            <h2>New challenges and leaderboards coming soon. Talk to your trainer to learn more.</h2>
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.section}>
            {/* <div className={styles.subHeading}>CURRENT</div> */}
            <ScrollableList items={leaderboardCards} itemHeight={96} />
          </div>
          {/* <div className={styles.section}>
            <PageSubHeading text="in progress" />
            {inProgressCards?.length ? (
              <ScrollableList items={inProgressCards} itemHeight={96} />
            ) : (
              <p className={styles.fallbackText}>
                New challenges and leaderboards coming soon. Talk to your trainer to learn more.
              </p>
            )}
          </div>
          <div className={styles.section}>
            <PageSubHeading text="completed" />
            {completedCards?.length ? (
              <ScrollableList items={completedCards} itemHeight={88} />
            ) : (
              <p className={styles.fallbackText}>
                Come show everyone your skills - talk to your trainer about challenges and leaderboards.
              </p>
            )}
          </div> */}
        </div>
      )}
    </LeaderboardPageContainer>
  );
};
