import {CroppedImageRequestPayload, CroppedImageResponse} from 'api/MLSChallenge/getCroppedImage';
import {Button} from 'components/MLS/Button/Button';
import Loader from 'components/MLS/Loader/Loader';
import React, {useState, useCallback, useMemo, memo} from 'react';
import Cropper from 'react-easy-crop';
import {Area} from 'react-easy-crop/types';
import {FaInfoCircle} from 'react-icons/fa';
import {toast} from 'react-toastify';

interface CropModalProps {
  isOpen: boolean;
  imageSrc: string;
  onClose: () => void;
  onCropComplete: (croppedImage: string) => void;
  getCroppedImage: (payload: CroppedImageRequestPayload) => Promise<CroppedImageResponse>;
  onRevertOriginal: (() => void) | null;
}

export const CropModal: React.FC<CropModalProps> = memo(
  ({isOpen, imageSrc, onClose, onCropComplete, getCroppedImage, onRevertOriginal}) => {
    const minZoom = 0.5;
    const maxZoom = 2;
    const [crop, setCrop] = useState({x: 0, y: 0});
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const revertCrop = useCallback(() => {
      setCrop({x: 0, y: 0});
      setZoom(1);
    }, []);

    const avatarChanged = useMemo(() => {
      console.log('croppedAreaPixels: ', croppedAreaPixels);
      console.log('crop: ', crop);
      console.log('zoom: ', zoom);
      return crop.x !== 0 || crop.y !== 0 || zoom !== 1;
    }, [crop, zoom, croppedAreaPixels]);

    const onCropCompleteCallback = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleApply = useCallback(async () => {
      console.log('handleApply: ', croppedAreaPixels);
      if (!croppedAreaPixels) {
        return;
      }
      try {
        setIsLoading(true);
        const croppedImage = await getCroppedImage({imageSrc, croppedArea: croppedAreaPixels});
        console.log('croppedImage: ', croppedImage);
        onCropComplete(croppedImage.croppedImageDataUrl);
      } catch (e) {
        console.error(e);
        toast.error('Failed to crop image. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }, [croppedAreaPixels, imageSrc, onCropComplete, getCroppedImage]);

    const increaseZoom = useCallback(() => {
      setZoom((prevZoom) => Math.min(prevZoom + 0.1, maxZoom));
    }, [maxZoom]);

    const decreaseZoom = useCallback(() => {
      setZoom((prevZoom) => Math.max(prevZoom - 0.1, minZoom));
    }, [minZoom]);

    if (!isOpen) {
      return null;
    }

    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
        <div className="relative mt-4 flex flex-col gap-4 rounded-lg bg-white p-4">
          {isLoading && (
            <div className="absolute inset-0 z-50 flex items-center justify-center bg-white bg-opacity-70">
              <Loader />
            </div>
          )}
          <div className="bg-gray-700 relative mx-auto flex h-[345px] w-[345px] items-center justify-center">
            <Cropper
              image={imageSrc}
              crop={crop}
              cropSize={{width: 250, height: 250}}
              zoom={zoom}
              minZoom={minZoom}
              maxZoom={maxZoom}
              // aspect={1}
              restrictPosition={false}
              cropShape="rect"
              showGrid
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropCompleteCallback}
              classes={{
                containerClassName: 'crop-container',
                cropAreaClassName: 'crop-area',
              }}
            />
          </div>
          <div className="text-center text-black">
            <FaInfoCircle className="mr-1 inline-block" />
            Drag the image to adjust its position. Use pinch gestures or the zoom buttons to scale for precise cropping.
          </div>
          <div className="flex justify-center gap-4">
            <Button variant="dark" size="md" label="-" onClick={decreaseZoom} />
            <Button variant="dark" size="md" label="+" onClick={increaseZoom} />
          </div>
          <div className="flex justify-center gap-2 xs:gap-4">
            <Button variant="dark" size="md" label="Cancel" onClick={onClose} />
            {avatarChanged && <Button variant="dark" size="md" label="Apply Edits" onClick={handleApply} />}
            {avatarChanged && <Button variant="dark" size="md" label="Revert Edits" onClick={revertCrop} />}
          </div>
          <div className="flex justify-center gap-4">
            {onRevertOriginal && (
              <Button variant="dark" size="md" label="Revert to Initial" onClick={onRevertOriginal} />
            )}
          </div>
        </div>
      </div>
    );
  }
);
CropModal.displayName = 'CropModal';
