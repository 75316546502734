import { ReactComponent as JerseyFrontSVG } from '../../../assets/img/mls/jersey-front.svg';
import { useColors } from 'hooks/MLSChallenge/usePatterns';

interface JerseyFrontProps {
  selectedColorIndex: number;
}

export const JerseyFront = ({ selectedColorIndex }: JerseyFrontProps) => {
  const colors = useColors();
  const color = colors[selectedColorIndex];

  return (
    <div
      className="mt-8 max-w-max"
      style={
        {
          '--jersey-color': color.lightColor,
          '--text-color': color.textColor,
        } as React.CSSProperties
      }
    >
      <JerseyFrontSVG />
    </div>
  );
};
