import {memo} from 'react';
import styles from './PageHeading.module.scss';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as ChevronLeft} from '../../../assets/img/icons/mls-icons/chevron-left.svg';

type NewPageHeadingProps = {
  backButton?: boolean;
  backDestination?: string;
  onClickOverride?: () => void;
  text: string;
};

export const NewPageHeading = memo(
  ({backButton = true, backDestination = '/home', onClickOverride, text}: NewPageHeadingProps) => {
    const navigate = useNavigate();

    return (
      <div className="my-10 flex w-full items-center">
        {backButton ? (
          <button
            onClick={onClickOverride ? onClickOverride : () => navigate(backDestination ?? -1)}
            className="padding-right-8 margin-right-4 grid place-content-center"
          >
            <ChevronLeft />
          </button>
        ) : null}
        <h2 className="flex-grow text-center text-lg font-semibold">{text}</h2>
      </div>
    );
  }
);
NewPageHeading.displayName = 'NewPageHeading';
