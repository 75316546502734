import {useProfiles} from '../../api/User/useProfiles';
import {PropsWithChildren} from 'react';
import {useLocation} from 'react-router-dom';

export default function WithCurrentProfileContainer({children}: PropsWithChildren) {
  const {currentProfile} = useProfiles();
  const {pathname} = useLocation();

  // Allow profile creation page to render even without a profile
  if (!currentProfile && !pathname.includes('/create-profile')) {
    return null;
  }

  return <>{children}</>;
}
