import {useCallback} from 'react';
import styles from './LeaderboardCard.module.scss';
import {useNavigate} from 'react-router-dom';
import {DateTime} from 'luxon';
import {isTvMode} from '../../../../utils/utils';
import {ReactComponent as FigurePassingIcon} from '../../../../assets/img/icons/redesign-icons/figure-passing.svg';
import {AthleteRankedLeaderboardEntry} from 'api/Leaderboards/leaderboard.types';

export const LeaderboardCard = ({leaderboard}: {leaderboard: AthleteRankedLeaderboardEntry}) => {
  const navigate = useNavigate();
  const tvModeEnabled = isTvMode();

  const {_id: id, name, startDateTime, endDateTime} = leaderboard.leaderboard;
  const {overallRank} = leaderboard.athlete;

  const startDateFormatted = DateTime.fromISO(startDateTime).toFormat('LLL d, h:mma');
  const endDateFormatted = DateTime.fromISO(endDateTime).toFormat('LLL d, h:mma');

  const onClick = useCallback(() => {
    navigate(`${tvModeEnabled ? '/tv' : ''}/leaderboards/${id.toString()}`);
  }, [navigate, id, tvModeEnabled]);

  return (
    // <button onClick={onClick} className={`${styles.container} ${status === 'completed' ? styles.completed : ''}`}>
    <button onClick={onClick} className={styles.container} data-test-id="leaderboard-card">
      <div className={styles.icon}>
        <FigurePassingIcon />
      </div>
      <div className={styles.section}>
        <p className={styles.name} data-test-id="leaderboard-card-name">
          {name}
        </p>
        <p className={styles.date} data-test-id="leaderboard-card-date">
          <span data-test-id="leaderboard-card-start-date">{startDateFormatted}</span> -{' '}
          <span data-test-id="leaderboard-card-end-date">{endDateFormatted}</span>
        </p>
      </div>
      {/* TODO animate loading */}
      {overallRank && (
        <p className={styles.rank} data-test-id="leaderboard-card-rank">
          {`#${overallRank}`}
        </p>
      )}
    </button>
  );
};
