import {memo, useCallback, useState} from 'react';
import styles from './ProfileModal.module.scss';
import {useProfiles} from '../../api/User/useProfiles';
import {Profile} from '../../user/player-info.interface';
import {useLogout} from '../../hooks/useLogout';
import {ReactComponent as CloseIcon} from '../../assets/img/mls/close-icon.svg';
import {useNavigate} from 'react-router-dom';
import {useTrainerContext} from '../../context/UserContextProvider';
import {useGetMboClientStatus} from '../../hooks/useGetMboClientStatus';
import {Modal} from 'components/Modal/Modal';
import {AddUpdatePhoneNumber} from './AddUpdatePhoneNumber';
import {Button} from 'components/MLS/Button/Button';
import {PlayerProfileForm, UserProfileFormInterface} from 'components/PlayerProfileForm/PlayerProfileForm';
import {isPlayer} from 'user/user-utils';
import {useCreateProfile} from 'hooks/useCreateProfile';

export const ProfileModal = memo(({isOpen, setClosed}: {isOpen: boolean; setClosed: () => void}) => {
  const navigate = useNavigate();
  const trainerContext = useTrainerContext();
  const {logout} = useLogout();
  const {currentProfile, otherProfiles} = useProfiles();
  const {isInactive} = useGetMboClientStatus();
  const [isCreateProfileModalOpen, setIsCreateProfileModalOpen] = useState(false);
  const {createProfile, isLoading: isProfileCreationLoading} = useCreateProfile();
  const [profileCreationErrorMessage, setProfileCreationErrorMessage] = useState('');
  const logoutClick = useCallback(() => {
    setClosed();
    logout();
  }, [logout, setClosed]);

  const profileClick = useCallback(() => {
    setClosed();
    navigate('/profile');
  }, [navigate, setClosed]);

  const endEmulation = useCallback(() => {
    setClosed();
    if (trainerContext) {
      trainerContext.endEmulateUser();
      navigate('/trainer');
    }
  }, [navigate, setClosed, trainerContext]);

  const handleCreateProfile = async (data: UserProfileFormInterface) => {
    try {
      await createProfile(data);
      setIsCreateProfileModalOpen(false);
    } catch (error) {
      console.error('Error creating profile:', error);
      setProfileCreationErrorMessage('Something went wrong, please try again.');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={setClosed} title="Profiles">
      <Modal
        isOpen={isCreateProfileModalOpen}
        onClose={() => setIsCreateProfileModalOpen(false)}
        title="Create New Profile"
        showHeader
        modalTestId="create-new-profile-modal"
      >
        <PlayerProfileForm
          isLoading={isProfileCreationLoading}
          onSubmit={handleCreateProfile}
          errorMessage={profileCreationErrorMessage}
        />
      </Modal>
      <div className={styles.container} data-test-id="profile-modal-container">
        <button className="absolute right-0 top-0" onClick={setClosed}>
          <CloseIcon />
        </button>
        <div className={styles.profileWrapper}>
          <div className={styles.profile}>
            <div className={styles.flexRow}>
              <p className={styles.name}>
                {currentProfile?.firstName} {currentProfile?.lastName}
              </p>
              {isInactive(currentProfile?.mboDetails?.clientId) ? (
                <p className={styles.inactiveLabel}>inactive</p>
              ) : null}
            </div>
            {currentProfile.email && <p>{currentProfile.email}</p>}
            <p>{currentProfile?.location?.name}</p>
            <AddUpdatePhoneNumber />
          </div>
          {/* <Button variant="dark" size="sm" label="My Profile" onClick={profileClick} /> */}
        </div>

        {otherProfiles.length > 0 ? (
          <>
            <h4 className={styles.subheading}>Switch to a different profile below</h4>
            <ul>
              {otherProfiles.map((profile, i) => (
                <ProfileCard profile={profile} key={`profilecard${i}`} setClosed={setClosed} />
              ))}
            </ul>
          </>
        ) : null}

        {isPlayer(currentProfile) && (
          <div className={styles.buttonContainer}>
            <Button
              variant="dark"
              size="sm"
              label="Create New Profile"
              onClick={() => setIsCreateProfileModalOpen(true)}
            />
          </div>
        )}

        {trainerContext && trainerContext.trainer.accountId !== currentProfile.accountId ? (
          <div className={styles.buttonContainer}>
            {/* <StandardButton variant="primary" text="End User Emulation" onClick={endEmulation} /> */}
            <Button variant="dark" size="sm" label="End User Emulation" onClick={endEmulation} />
          </div>
        ) : (
          <div className={styles.buttonContainer}>
            {/* <StandardButton variant="light" text="Log Out" onClick={logoutClick} /> */}
            <Button variant="dark" size="sm" label="Log Out" onClick={logoutClick} />
          </div>
        )}
      </div>
    </Modal>
  );
});
ProfileModal.displayName = 'ProfileModal';

const ProfileCard = memo(({profile, setClosed}: {profile: Profile; setClosed: () => void}) => {
  const {selectProfile} = useProfiles();
  const {isInactive} = useGetMboClientStatus();

  const onClick = useCallback(() => {
    selectProfile(profile._id);
    setClosed();
  }, [profile._id, selectProfile, setClosed]);

  return (
    <li className={styles.cardContainer} onClick={onClick}>
      <div className={styles.profileWrapper}>
        <div className={styles.profile}>
          <div className={styles.flexRow}>
            <p className={styles.name}>
              {profile.firstName} {profile.lastName}
            </p>
            {isInactive(profile.mboDetails?.clientId) ? <p className={styles.inactiveLabel}>inactive</p> : null}
          </div>
          <p>{profile.location?.name}</p>
        </div>
      </div>
    </li>
  );
});
ProfileCard.displayName = 'ProfileCard';
