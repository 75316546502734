import {PlayerCardBackground} from 'components/MLS/PlayerCardBackground/PlayerCardBackground';
import {memo} from 'react';
import {FaEdit} from 'react-icons/fa';

interface AvatarPreviewProps {
  photoUrl: string | null;
  colorIndex: number;
  patternIndex: number | null;
  onPreviewClick: () => void;
}

export const AvatarPreview = memo(({photoUrl, colorIndex, patternIndex, onPreviewClick}: AvatarPreviewProps) => {
  return (
    <PlayerCardBackground colorIndex={colorIndex} {...(patternIndex !== null ? {patternIndex} : {})}>
      <div className="relative flex h-full w-full flex-col items-center justify-center py-32 sm:py-40">
        <div className="relative">
          <div
            className="relative mb-4 h-48 w-48 rotate-45 transform cursor-pointer overflow-hidden"
            style={{border: `2px solid white`}}
            onClick={onPreviewClick}
          >
            {photoUrl && (
              <div className="absolute inset-0 -rotate-45 scale-[1.4] transform">
                <img src={photoUrl} className="h-full w-full object-cover" />
              </div>
            )}
          </div>
          {photoUrl && (
            <FaEdit
              className="hover:text-gray-300 absolute left-[60%] top-[102%] z-50 cursor-pointer text-white"
              size={24}
              onClick={onPreviewClick}
            />
          )}
        </div>
      </div>
    </PlayerCardBackground>
  );
});
AvatarPreview.displayName = 'AvatarPreview';
