import {ReactNode} from 'react';
import clsx from 'clsx';

type ButtonProps = {
  label?: string;
  onClick?: () => void;
  className?: string;
  variant?: 'default' | 'dark' | 'outline';
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  children?: ReactNode;
};

const SIZE_CLASSES = {
  sm: 'px-2 py-1 text-base',
  md: 'px-4 py-1 text-base font-medium',
  lg: 'px-6 py-2 text-lg font-medium',
};

const VARIANT_CLASSES = {
  dark: 'bg-black text-white',
  outline: 'bg-white text-black border border-black',
  default: 'bg-white text-black',
};

const DISABLED_CLASSES = 'opacity-30 cursor-not-allowed';

export const Button = ({
  label,
  onClick,
  className = '',
  variant = 'default',
  disabled = false,
  size = 'lg',
  children,
}: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        'min-w-fit flex-shrink-0 whitespace-nowrap rounded-lg',
        SIZE_CLASSES[size],
        VARIANT_CLASSES[variant],
        disabled && DISABLED_CLASSES,
        className
      )}
      disabled={disabled}
    >
      {label || children}
    </button>
  );
};

Button.displayName = 'Button';
