import {getUserExistsAndEligibility, UserExistsResponse} from 'api/api';
import {AxiosResponse} from 'axios';
import {useCallback, useRef} from 'react';

export type GetCachedUserExists = ({
  applyEligibilityCheck,
  email,
  sessionTypeNames,
  siteId,
}: {
  applyEligibilityCheck?: boolean;
  email: string;
  sessionTypeNames?: string[];
  siteId?: string;
}) => Promise<AxiosResponse<UserExistsResponse, any>>;

export const useGetCachedUserExists = () => {
  const cachedUserExists = useRef<{email: string; promise: Promise<AxiosResponse<UserExistsResponse, any>>} | null>(
    null
  );

  const getUserExistsAndEligibilityFromCacheOrApi: GetCachedUserExists = useCallback(
    async ({applyEligibilityCheck = true, email, sessionTypeNames, siteId}) => {
      if (cachedUserExists.current && cachedUserExists.current.email === email) {
        return await cachedUserExists.current.promise;
      }
      const checkUserPromise = getUserExistsAndEligibility({applyEligibilityCheck, email, sessionTypeNames, siteId});
      cachedUserExists.current = {email, promise: checkUserPromise};
      return await checkUserPromise;
    },
    []
  );

  return {getCachedUserExists: getUserExistsAndEligibilityFromCacheOrApi};
};
