import {ClassSchedule, Program} from 'api/Classes/useGetPrograms';
import {isDevelopmentEnvironment} from 'common/isTestEnvironment';
import {SelectOptions, StandardSelect} from 'components/Select/StandardSelect/StandardSelect';
import _ from 'lodash';
import {FormEventHandler, memo, useCallback} from 'react';
import {useSearchParams} from 'react-router-dom';
import {classScheduleAgeMatches, formatTimeVaguely, reformatWeekdayAbrv} from 'utils/programs-utils';

const AGE_OPTION_DEFAULTS: SelectOptions[] = [
  {
    value: 'All',
    displayValue: 'All',
  },
  {
    value: '1',
    displayValue: '1',
  },
  {
    value: '2',
    displayValue: '2',
  },
  {
    value: '3',
    displayValue: '3',
  },
  {
    value: '4',
    displayValue: '4',
  },
  {
    value: '5',
    displayValue: '5',
  },
  {
    value: '6',
    displayValue: '6',
  },
  {
    value: '7',
    displayValue: '7',
  },
  {
    value: '8',
    displayValue: '8',
  },
  {
    value: '9',
    displayValue: '9',
  },
  {
    value: '10',
    displayValue: '10',
  },
  {
    value: '11',
    displayValue: '11',
  },
  {
    value: '12',
    displayValue: '12',
  },
  {
    value: '13',
    displayValue: '13',
  },
  {
    value: '14',
    displayValue: '14',
  },
  {
    value: '15',
    displayValue: '15',
  },
  {
    value: '16',
    displayValue: '16',
  },
  {
    value: '17',
    displayValue: '17',
  },
  {
    value: '18',
    displayValue: '18',
  },
];

const DAY_OPTION_DEFAULTS = [
  {
    value: 'U',
    displayValue: 'Sun',
    disabled: false,
  },
  {
    value: 'M',
    displayValue: 'Mon',
  },
  {
    value: 'T',
    displayValue: 'Tue',
  },
  {
    value: 'W',
    displayValue: 'Wed',
  },
  {
    value: 'R',
    displayValue: 'Thu',
  },
  {
    value: 'F',
    displayValue: 'Fri',
  },
  {
    value: 'S',
    displayValue: 'Sat',
  },
];

const TIME_OPTION_DEFAULTS: SelectOptions[] = [
  {
    value: 'All',
    displayValue: 'All',
  },
  {
    value: 'Morning',
    displayValue: 'Morning',
  },
  {
    value: 'Afternoon',
    displayValue: 'Afternoon',
  },
  {
    value: 'Evening',
    displayValue: 'Evening',
  },
];

const PERMITTED_CLASS_SCHEDULE_NAMES = ['toca soccer class', 'free kids class day'];

export const useProgramFiltering = () => {
  const [searchParams] = useSearchParams();

  const programFiltering = useCallback(
    // (enrollment: Enrollment | ClassSchedule) => { // TODO CLASSES add compatability back
    (enrollment: ClassSchedule) => {
      const passesCoreRequirements =
        enrollment.capacity.max > 0 &&
        !!enrollment.ages.length &&
        // disallow enrollments with decimal age ranges, like "1.5-3"
        !enrollment.ages.find((age) => age.includes('.')) &&
        (isDevelopmentEnvironment ||
          (!!enrollment.name &&
            PERMITTED_CLASS_SCHEDULE_NAMES.some(
              (name) => enrollment.name && enrollment.name.toLowerCase().includes(name)
            )));

      if (!(searchParams as unknown as any)?.size) {
        return passesCoreRequirements;
      } else {
        let allChecks = true;

        allChecks = allChecks && passesCoreRequirements;

        if (searchParams.get('classType')) {
          allChecks = allChecks && enrollment.tags?.classType === searchParams.get('classType');
        }

        if (searchParams.get('age')) {
          // TODO enrollmentAgeMatches
          allChecks =
            allChecks && classScheduleAgeMatches({classSchedule: enrollment, age: Number(searchParams.get('age'))});
        }

        if (searchParams.get('time')) {
          allChecks =
            allChecks &&
            (enrollment.schedule.startTime
              ? formatTimeVaguely({timeString: enrollment.schedule.startTime})
              : 'timeError') === searchParams.get('time');
        }

        if (searchParams.get('wkday')) {
          allChecks =
            allChecks &&
            enrollment.schedule.daysArray.some((day) => searchParams.get('wkday')?.includes(reformatWeekdayAbrv(day)));
        }

        return allChecks;
      }
    },
    [searchParams]
  );
  return {programFiltering};
};

// TODO CLASSES this needs to work for Enrollments as well
const createProgramFilterOptions = ({program}: {program: Program}) => {
  const ages: string[] = ['All'];
  const times: string[] = ['All'];
  const days: string[] = [];

  program.classSchedules?.forEach((classSchedule) => {
    const vagueStartTime = formatTimeVaguely({timeString: classSchedule.schedule.startTime});
    ages.push(...(classSchedule.ages ?? []));
    times.push(vagueStartTime);
    days.push(...classSchedule.schedule.daysArray.map((day) => reformatWeekdayAbrv(day)));
  });

  const uniqueAges = _.uniq(ages);
  const uniqueTimes = _.uniq(times);
  const uniqueDays = _.uniq(days);

  const ageOptions = uniqueAges.length
    ? uniqueAges.sort((a, b) => Number(a) - Number(b)).map((age) => ({value: age, displayValue: age}))
    : AGE_OPTION_DEFAULTS;

  const timeOptions = uniqueTimes.length
    ? TIME_OPTION_DEFAULTS.map((opt) =>
        !uniqueTimes.includes(opt.displayValue as string) ? {...opt, disabled: true} : opt
      )
    : TIME_OPTION_DEFAULTS;

  const dayOptions = uniqueDays.length
    ? DAY_OPTION_DEFAULTS.map((opt) => (!uniqueDays.includes(opt.value as string) ? {...opt, disabled: true} : opt))
    : DAY_OPTION_DEFAULTS;

  return {ageOptions, timeOptions, dayOptions};
};

export const ProgramFilters = memo(({program}: {program: Program}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {timeOptions, ageOptions, dayOptions} = createProgramFilterOptions({program});

  const clearFilter = (filter: string) => {
    searchParams.delete(filter);
    setSearchParams(searchParams, {replace: true});
  };

  const setFilter = (filter: string, value: string) => {
    if (value !== 'All') {
      searchParams.set(filter, value);
      setSearchParams(searchParams, {replace: true});
    } else {
      clearFilter(filter);
    }
  };

  // const setTypeFilter: FormEventHandler<SelectOptions> = (e) => {
  //   setFilter('classType', e.currentTarget.value.toString());
  // };
  const setAgeFilter: FormEventHandler<SelectOptions> = (e) => {
    setFilter('age', e.currentTarget.value.toString());
  };
  const setTimeFilter: FormEventHandler<SelectOptions> = (e) => {
    setFilter('time', e.currentTarget.value.toString());
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        {/* TODO Nothing currently sets the Indoor/Outdoor values */}
        {/* <div>
          <div className="flex justify-between items-center font-poppins text-sm mb-1">
            <label htmlFor="type_select" className="font-medium">
              Activity
            </label>
            <p onClick={() => clearFilter('type')} className="text-grey-xdark cursor-pointer hover:underline">
              Clear
            </p>
          </div>
          <StandardSelect
            options={typeOptions}
            onChange={setTypeFilter}
            value={searchParams.get('type') ?? 'All'}
            id="type_select"
            variant="normal"
          />
        </div> */}

        <div>
          <div className="flex justify-between items-center font-poppins text-sm mb-1">
            <label htmlFor="age_select" className="font-medium">
              Age
            </label>
            <p onClick={() => clearFilter('age')} className="text-grey-xdark cursor-pointer hover:underline">
              Clear
            </p>
          </div>
          <StandardSelect
            options={ageOptions}
            onChange={setAgeFilter}
            value={searchParams.get('age') ?? 'All'}
            id="age_select"
            variant="normal"
          />
        </div>

        <div>
          <div className="flex justify-between items-center font-poppins text-sm mb-1">
            <label htmlFor="time_select" className="font-medium">
              Time
            </label>
            <p onClick={() => clearFilter('time')} className="text-grey-xdark cursor-pointer hover:underline">
              Clear
            </p>
          </div>
          <StandardSelect
            options={timeOptions}
            onChange={setTimeFilter}
            value={searchParams.get('time') ?? 'All'}
            id="time_select"
            variant="normal"
          />
        </div>

        <div>
          <div className="flex justify-between items-center font-poppins text-sm mb-1">
            <label htmlFor="weekday_buttons" className="font-medium">
              Days
            </label>
            <p onClick={() => clearFilter('wkday')} className="text-grey-xdark cursor-pointer hover:underline">
              Clear
            </p>
          </div>
          <div id="weekday_buttons" className="flex flex-wrap gap-2">
            {dayOptions.map((day) => (
              <WeekdayToggleButton
                key={day.value}
                day={day.value}
                displayDay={day.displayValue}
                disabled={day.disabled}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
});
ProgramFilters.displayName = 'ProgramFilters';

type WeekdayToggleButtonProps = {
  day: string;
  displayDay: string;
  disabled?: boolean;
};
const WeekdayToggleButton = memo(({day, displayDay, disabled}: WeekdayToggleButtonProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const active = !!searchParams.get('wkday')?.includes(day);

  const setWeekdayFilter = (day: string) => {
    const weekdays = (searchParams.get('wkday') ?? '').split('');
    let newWeekdays: string[] = [];

    if (active) {
      newWeekdays = weekdays.filter((wkday) => wkday !== day);
    } else {
      newWeekdays = [...weekdays, day];
    }

    if (newWeekdays.length) {
      searchParams.set('wkday', newWeekdays.join(''));
      setSearchParams(searchParams, {replace: true});
    } else {
      searchParams.delete('wkday');
      setSearchParams(searchParams, {replace: true});
    }
  };

  return (
    <div
      className={`cursor-pointer border border-grey rounded-md aspect-square grid content-center h-16 w-16 text-center font-poppins font-semibold text-base transition-colors ${
        disabled ? 'opacity-40 bg-grey-light' : active ? 'bg-secondary text-white' : 'bg-white text-grey-xdark'
      }`}
      onClick={() => (disabled ? null : setWeekdayFilter(day))}
    >
      {displayDay}
    </div>
  );
});
WeekdayToggleButton.displayName = 'WeekdayToggleButton';
