import {memo, useCallback, useState} from 'react';
import {saveErrorDetails} from '../../api/api';
import {LoadingAnimation} from 'components/Loader/LoadingAnimation';
import {TrainerStudioSelectionType, useStudioSelection} from 'api/Trainer/useStudioSelection';
import moment from 'moment';
import {useProfiles} from '../../api/User/useProfiles';
import {getUserDisplayName} from '../../utils/utils';
import Fuse from 'fuse.js';

export const TrainerStudioSelection = memo(({trainerStaffId, siteId}: {trainerStaffId: string; siteId: string}) => {
  const {currentProfile} = useProfiles();
  const [showSelection, setShowSelection] = useState(false);
  const [showComboboxDropdown, setShowComboboxDropdown] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const {currentSelection, referenceDate, availableStudios, selectStudio, isLoading, isLoadingSelectStudio, isError} =
    useStudioSelection({
      trainerStaffId,
      siteId,
    });

  if (!trainerStaffId || !siteId) {
    saveErrorDetails({
      key: 'trainer-studio-selection',
      severity: 'medium',
      message: 'Missing required params for studio selection',
      context: 'trainer',
      siteId,
      url: window.location.href,
      data: {
        trainerStaffId,
      },
    });
  }

  // TODO need to ensure trainer profiles have staffId property!

  const userDisplayName = currentProfile?.firstName
    ? getUserDisplayName(currentProfile.firstName, currentProfile.lastName)
    : 'Trainer';

  const appointmentErrors: TrainerStudioSelectionType['appointmentErrors'] = [];
  currentSelection?.appointmentErrors?.forEach((error) => {
    if (!error.appointment?.startDateTime || moment(error.appointment?.startDateTime).isAfter(moment())) {
      appointmentErrors.push(error);
    }
  });

  const fuse = new Fuse(availableStudios || [], {
    keys: ['name'],
    threshold: 0.3,
    includeScore: true,
  });

  const getFilteredStudios = useCallback(
    (searchTerm?: string) =>
      searchTerm?.trim() ? fuse.search(searchTerm).map((result) => result.item) : availableStudios || [],
    [availableStudios, fuse]
  );

  return (
    <div className="my-2 px-6 py-5 border border-primary rounded-lg bg-white shadow-flat-lg shadow-primary/30">
      <div className="text-xl font-semibold mb-5">Welcome {userDisplayName}!</div>
      {!trainerStaffId || !siteId || isError ? (
        <div className="flex flex-col p-2 gap-2">
          <p className="text-base text-alert/90 uppercase font-medium font-roboto">Something Went Wrong</p>
          {isError ? (
            <p className="text-base text-blue-dark/90">An error occurred selecting a studio.</p>
          ) : (
            <p className="text-base text-blue-dark/90">Unable to verify your trainerId or siteId.</p>
          )}
        </div>
      ) : isLoading ? (
        <>
          <LoadingAnimation />
          {isLoadingSelectStudio && (
            <p className="text-base text-primary/90 text-center">
              Updating MBO appointments. This might take a while...
            </p>
          )}
        </>
      ) : (
        <>
          <div className="flex flex-col items-start gap-8">
            {/* Current StudioSelection and re-select button */}
            <div className="flex flex-col xs:flex-row items-center w-full justify-between gap-2">
              <div className="shrink flex items-end flex-wrap">
                {currentSelection ? (
                  <>
                    <p
                      data-test-id="selected-studio"
                      className="mr-2 text-base text-blue-dark/80 uppercase font-medium font-roboto leading-normal xs:leading-5"
                    >
                      For today{' '}
                      <span data-test-id="selected-studio-date" className="text-secondary/90">
                        {moment(referenceDate).format('MMM Do')}
                      </span>
                      ,
                      <br />
                      your selected studio is:
                    </p>
                    <p className="text-xl text-primary font-semibold leading-normal xs:leading-6 whitespace-nowrap">
                      {currentSelection.studioName}
                    </p>
                  </>
                ) : (
                  <p className="text-base text-primary uppercase font-medium font-roboto">
                    Select A Studio For Today,{' '}
                    <span className="text-secondary/90">{moment(referenceDate).format('MMM Do')}</span>
                  </p>
                )}
              </div>

              <button
                data-test-id={showSelection ? 'Cancel Button' : 'Change Button'}
                className={`px-3 py-1 w-full xs:w-[100px] rounded text-lg font-medium ${
                  showSelection ? 'text-primary bg-grey-light' : 'text-white bg-primary/80'
                } transition-all`}
                onClick={() => {
                  setShowSelection((prev) => !prev);
                  if (!showSelection) {
                    setSearchInput('');
                    setShowComboboxDropdown(true);
                    setTimeout(() => {
                      document.querySelector<HTMLInputElement>('[data-test-id="available-studios-input"]')?.focus();
                    }, 0);
                  }
                }}
              >
                {showSelection ? 'Cancel' : currentSelection ? 'Change' : 'Select'}
              </button>
            </div>

            {/* Combobox/dropdown of available studios */}
            {showSelection || !currentSelection ? (
              <div className="px-1 w-full flex flex-col items-stretch gap-2">
                <p className="h-4 text-base text-blue-dark/90 text-center">Available studios</p>
                <div className="mb-1 mx-[-8px] border-b border-primary/50" />

                <div className="relative w-full">
                  <input
                    type="text"
                    data-test-id="available-studios-input"
                    className="w-full p-2 text-lg font-medium text-primary/90 border-2 border-primary/20 rounded"
                    placeholder="Select or search for a studio:"
                    value={searchInput}
                    onChange={(e) => {
                      setSearchInput(e.target.value);
                      setSelectedIndex(0);
                    }}
                    onFocus={(e) => {
                      setShowComboboxDropdown(true);
                      setSearchInput(e.target.value);
                      setSelectedIndex(0);
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        setShowComboboxDropdown(false);
                        setSelectedIndex(0);
                      }, 200);
                    }}
                    onKeyDown={(e) => {
                      const filteredStudios = getFilteredStudios(searchInput);
                      switch (e.key) {
                        case 'ArrowDown':
                          e.preventDefault();
                          setSelectedIndex((prev) => (prev < filteredStudios.length - 1 ? prev + 1 : prev));
                          break;
                        case 'ArrowUp':
                          e.preventDefault();
                          setSelectedIndex((prev) => (prev > 0 ? prev - 1 : prev));
                          break;
                        case 'Enter':
                          if (filteredStudios?.[selectedIndex]) {
                            selectStudio({studioId: filteredStudios[selectedIndex].studioId});
                            setShowSelection(false);
                            setShowComboboxDropdown(false);
                          }
                          break;
                        case 'Escape':
                          setShowComboboxDropdown(false);
                          setShowSelection(false);
                          break;
                      }
                    }}
                  />

                  {showComboboxDropdown && (
                    <ul
                      className="absolute z-10 w-full mt-1 max-h-60 overflow-auto bg-white border-2 border-primary/50 rounded shadow-xl"
                      data-test-id="available-studios-dropdown"
                    >
                      {getFilteredStudios(searchInput).map(({studioId, name}, index) => (
                        <li
                          key={studioId}
                          data-test-id={`studio-${studioId}`}
                          className={`text-base px-3 py-2 cursor-pointer ${
                            index === selectedIndex ? 'bg-primary text-white' : 'hover:bg-primary/10'
                          } ${studioId === currentSelection?.studioId ? 'font-semibold text-secondary' : ''}`}
                          onClick={() => {
                            selectStudio({studioId});
                            setShowSelection(false);
                            setShowComboboxDropdown(false);
                          }}
                          onMouseEnter={() => setSelectedIndex(index)}
                        >
                          {name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            ) : null}

            {/* Any errors that occurred when attempting to reserve the studio */}
            {appointmentErrors.length ? (
              <div className="w-full flex flex-col gap-5 px-6 py-4 border-4 border-alert/40 rounded">
                <p className="shrink mx-auto text-base text-alert/90 font-roboto text-center sm:px-8">
                  There were errors setting your studio selection on some of your appointments.
                </p>

                <ul className="grid grid-cols-1 gap-2">
                  {appointmentErrors.map((error) => (
                    <li className="p-2 border-b border-grey">
                      <p className="text-base font-semibold text-primary">
                        {`"${error.appointment?.sessionTypeName ?? 'Unknown Appointment'}"`}
                        <span className="ml-1">(ID: {error.appointment?.appointmentId})</span>
                        <span className="mx-1 font-light text-blue-dark/80">at</span>
                        {error.appointment?.startDateTime
                          ? moment(error.appointment?.startDateTime).format('h:MM a')
                          : '--:--'}
                      </p>
                      <p className="text-base text-grey-xdark">{error.message}</p>
                    </li>
                  ))}
                </ul>

                <p className="px-2 text-sm font-normal text-primary/80">
                  If you believe any issues may have been resolved, you can click &quot;Change&quot; and re-select your
                  studio above to try to apply the studio selection to these appointments again.
                </p>
              </div>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
});
TrainerStudioSelection.displayName = 'TrainerStudioSelection';
