import {useCallback} from 'react';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {
  getCroppedImage as getCroppedImageApi,
  CroppedImageRequestPayload,
  CroppedImageResponse,
} from './getCroppedImage';

export const useGetCroppedImage = () => {
  //   const queryClient = useQueryClient();

  const mutateGetCroppedImage = useMutation<CroppedImageResponse, unknown, CroppedImageRequestPayload>({
    mutationFn: (payload: CroppedImageRequestPayload) => getCroppedImageApi(payload.imageSrc, payload.croppedArea),
  });

  const getCroppedImage = useCallback(
    async (payload: CroppedImageRequestPayload) => {
      const response = await mutateGetCroppedImage.mutateAsync(payload);
      //   queryClient.invalidateQueries({queryKey: ['player-card-details']});
      return response;
    },
    [mutateGetCroppedImage]
  );

  return {getCroppedImage};
};
