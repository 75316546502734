import {impersonatePlayer} from 'api/api';
import {signInWithCustomToken, UserCredential} from 'firebase/auth';
import {FormEvent, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {setAccessToken, setCurrentAccountId} from 'user/user-utils';
import {auth as fireBaseAuth} from '../../firebase/config';

export function PlayerImpersonation() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    token: '',
  });

  const setupAccountCredentials = async (userCredential: UserCredential) => {
    setCurrentAccountId(userCredential.user.uid);
    setAccessToken((await userCredential.user.getIdTokenResult()).token);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {authToken} = await impersonatePlayer(formData);
    const userCredential = await signInWithCustomToken(fireBaseAuth, authToken);
    setupAccountCredentials(userCredential);
    navigate('/');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">Player Impersonation</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="email" className="block mb-1">
            Email
          </label>
          <input
            type="text"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border rounded-md"
            required
          />
        </div>
        <div>
          <label htmlFor="token" className="block mb-1">
            Secret Token
          </label>
          <input
            type="password"
            id="token"
            name="token"
            value={formData.token}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border rounded-md"
            required
          />
        </div>
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
          Submit
        </button>
      </form>
    </div>
  );
}
