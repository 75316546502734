import {getLocationBySiteId} from 'constants/locations';
import {Appointment} from 'api/MLSChallenge/useGetPlayerAppointments';

export function formatTime(timestamp: string): string {
  const date = new Date(timestamp.replace('Z', ''));
  if (isNaN(date.getTime())) {
    console.log(`Invalid timestamp: ${timestamp}`);
    return 'Invalid Time';
  }
  return date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
}

export function formatDate(timestamp: string): string {
  const date = new Date(timestamp.replace('Z', ''));
  if (isNaN(date.getTime())) {
    console.log(`Invalid timestamp: ${timestamp}`);
    return 'Invalid Date';
  }
  return date.toLocaleString('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
  });
}

const UpcomingSessionItem = ({appointment}: {appointment: Appointment}) => {
  if (!appointment) {
    return null;
  }
  const {startDateTime, siteId, durationMinutes, sessionTypeName} = appointment;
  const date = formatDate(startDateTime);
  const location = getLocationBySiteId(siteId);
  const time = formatTime(startDateTime);

  return (
    <div className="flex items-center gap-6 py-8 border-t border-mls-grey-dark">
      <div className="flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-full bg-mls-primary text-white">
        <span className="text-xs font-medium" data-test-id="session-time">
          {time}
        </span>
      </div>
      <div className="space-y-1">
        <h3 className="text-base font-medium">
          <span data-test-id="session-type">{sessionTypeName || 'Session'} </span>-{' '}
          <span data-test-id="session-date">{date}</span>
        </h3>
        <p className="text-base text-mls-secondary">
          <span data-test-id="session-location">{location?.name} </span> •{' '}
          <span data-test-id="session-duration">{durationMinutes} min</span>
        </p>
      </div>
    </div>
  );
};

const UpcomingSessionBlank = () => {
  return (
    <div className="flex items-center gap-6 py-8 border-t border-mls-grey-dark">
      <div className="flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-full bg-mls-primary text-white">
        <span className="text-2xl font-medium" data-test-id="session-time">
          ?
        </span>
      </div>
      <div className="space-y-1">
        <h3 className="text-base font-medium">
          <span data-test-id="session-type">Oh no! No session booked.</span>
        </h3>
      </div>
    </div>
  );
};

export const UpcomingSessions = ({appointments}: {appointments: Appointment[]}) => {
  // always show exactly 6 session cards, using blanks when missing appointments.
  const sessionsToShow = 6;
  const upcomingSessionsAndPlaceholders = Array(sessionsToShow)
    .fill(null)
    .map((sessionItem, i) => appointments?.[i] ?? null);

  return (
    <div className="rounded-lg bg-mls-grey p-6" data-test-id="upcoming-session-container">
      <h2 className="pb-6 text-lg font-medium">Upcoming</h2>
      {upcomingSessionsAndPlaceholders.map((appointment, i) =>
        appointment ? (
          <UpcomingSessionItem key={appointment._id} appointment={appointment} />
        ) : (
          <UpcomingSessionBlank key={`session_blank_${i}`} />
        )
      )}
    </div>
  );
};
