import clsx from 'clsx';
import React, {useMemo} from 'react';
import {useColors, usePatterns} from 'hooks/MLSChallenge/usePatterns';

type PlayerCardBackgroundProps = {
  patternIndex?: number;
  colorIndex: number;
  children?: React.ReactNode;
  containerClassNames?: string;
};

export const PlayerCardBackground = React.memo(
  ({patternIndex, colorIndex, children, containerClassNames = ''}: PlayerCardBackgroundProps) => {
    const colors = useColors();
    const patterns = usePatterns();

    const patternData = useMemo(() => {
      if (patternIndex === undefined || patternIndex < 0 || patternIndex >= patterns.length) {
        return null;
      }
      return patterns.find((p) => p.patternIndex === patternIndex) || null;
    }, [patterns, patternIndex]);

    // Only apply color if the pattern allows it
    const shouldApplyColor = !patternData?.disableColorSelection && colorIndex >= 0;
    const color = useMemo(
      () => (shouldApplyColor ? colors[colorIndex] || colors[0] : null),
      [colorIndex, shouldApplyColor, colors]
    );

    return (
      <div
        className={clsx('relative h-full w-full', containerClassNames)}
        style={
          shouldApplyColor
            ? ({
                backgroundColor: color?.lightColor,
                '--light-color': color?.lightColor,
                '--dark-color': color?.darkColor,
              } as React.CSSProperties)
            : undefined
        }
      >
        {patternData &&
          (patternData.type === 'svg' ? (
            <patternData.pattern className="absolute inset-0 z-0 h-full w-full" />
          ) : (
            <img
              src={patternData.pattern}
              alt="Pattern background"
              className="absolute inset-0 z-0 h-full w-full object-cover"
            />
          ))}
        {children}
      </div>
    );
  }
);

PlayerCardBackground.displayName = 'PlayerCardBackground';
