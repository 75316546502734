import {useMutation, useQueryClient} from '@tanstack/react-query';
import {
  updatePlayerDetails as updatePlayerDetailsApi,
  updatePlayerAvatars as updatePlayerAvatarsApi,
  UpdatePlayerDetailsRequestPayload,
  UpdatePlayerAvatarsRequestPayload,
  UpdatePlayerDetailsResponse,
  UpdatePlayerAvatarResponse,
} from './updatePlayerCard';
import {toast} from 'react-toastify';
import {useCallback} from 'react';

const useUpdatePlayerCard = ({
  playerProfileId,
  onSuccessHandler,
  onErrorHandler,
}: {
  onSuccessHandler?: () => void;
  onErrorHandler?: () => void;
  playerProfileId: string;
}) => {
  const queryClient = useQueryClient();

  const mutateUpdateDetails = useMutation<UpdatePlayerDetailsResponse, unknown, UpdatePlayerDetailsRequestPayload>({
    mutationFn: updatePlayerDetailsApi,
  });

  const mutateUpdateAvatars = useMutation<UpdatePlayerAvatarResponse, unknown, UpdatePlayerAvatarsRequestPayload>({
    mutationFn: updatePlayerAvatarsApi,
  });
  const updatePlayerCard = useCallback(
    async (payload: UpdatePlayerDetailsRequestPayload & UpdatePlayerAvatarsRequestPayload) => {
      const {
        patternIndex,
        playerCardLightColor,
        playerCardDarkColor,
        playerCardTextColor,
        selectedCartoonIndex,
        cartoonUrls,
        croppedCartoonUrls,
        imageSrc,
      } = payload;
      try {
        console.log('payload', payload);
        let updatedAvatarUrls: string[] | null = null;
        let updatedCroppedAvatarUrls: (string | null)[] | null = null;
        if (patternIndex || patternIndex === 0) {
          if (!playerCardLightColor || !playerCardDarkColor || !playerCardTextColor) {
            throw new Error('Missing required color values');
          }

          await mutateUpdateDetails.mutateAsync({
            patternIndex,
            playerCardLightColor,
            playerCardDarkColor,
            playerCardTextColor,
          });
          queryClient.invalidateQueries({queryKey: ['player-card-details', playerProfileId]});
        }
        if (croppedCartoonUrls.length > 0) {
          const {cartoonPublicUrls: uploadedAvatarUrls, croppedCartoonPublicUrls: uploadedCroppedAvatarUrls} =
            await mutateUpdateAvatars.mutateAsync({
              cartoonUrls,
              croppedCartoonUrls,
              selectedCartoonIndex,
              imageSrc,
            });
          queryClient.invalidateQueries({queryKey: ['player-card-avatars', playerProfileId]});
          updatedAvatarUrls = uploadedAvatarUrls;
          updatedCroppedAvatarUrls = uploadedCroppedAvatarUrls;
        }
        onSuccessHandler?.();
        return {updatedAvatarUrls, updatedCroppedAvatarUrls};
      } catch (error) {
        // toast.error('Failed to update Player Card details');
        onErrorHandler?.();
      }
    },
    [mutateUpdateDetails, mutateUpdateAvatars, queryClient, onSuccessHandler, onErrorHandler, playerProfileId]
  );

  return {
    updatePlayerCard,
    isLoading: mutateUpdateDetails.isLoading || mutateUpdateAvatars.isLoading,
  };
};

export default useUpdatePlayerCard;
