import {useCallback, useEffect, useState} from 'react';
import {PhoneAuthProvider, updatePhoneNumber} from 'firebase/auth';
import {InternationalPhoneInput} from 'components/InternationalPhoneInput/InternationalPhoneInput';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {auth as firebaseAuth} from '../../firebase/config';
import {useUpdateProfile} from 'api/User/useUpdateProfile';
import {useProfiles} from 'api/User/useProfiles';
import {updateUserAccount} from 'api/api';
import {BasePhoneVerification} from 'pages/SignInSignUp/PhoneNumberAddVerification/BasePhoneVerification';
import {Button} from 'components/MLS/Button/Button';
import {isDevelopmentEnvironment} from 'common/isTestEnvironment';

export const AddUpdatePhoneNumber = () => {
  const {currentProfile} = useProfiles();
  const {updateProfile} = useUpdateProfile();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [showPhoneInput, setShowPhoneInput] = useState(false);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const user = firebaseAuth.currentUser;
    if (user?.phoneNumber) {
      setPhoneNumber(user.phoneNumber);
    }
  }, []);

  const handleVerificationComplete = useCallback(
    async (phone: string, verificationId: string, verificationCode: string) => {
      const user = firebaseAuth.currentUser;
      if (!user) {
        throw new Error('Missing user information');
      }

      const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
      await updatePhoneNumber(user, credential);

      await Promise.all([
        updateProfile({
          profileId: currentProfile._id,
          email: currentProfile.email ?? '',
          update: {phoneNumber: phone},
        }),
        updateUserAccount({phoneNumber: phone}),
      ]);

      setPhoneNumber(phone);
      setShowCodeInput(false);
      setShowPhoneInput(false);
    },
    [currentProfile, updateProfile]
  );

  const handleSendVerificationCode = () => {
    if (!phoneNumber) {
      setError('Phone number is required');
      return;
    }

    const user = firebaseAuth.currentUser;
    if (user?.phoneNumber === phoneNumber) {
      setError('This phone number is already associated with your account.');
      return;
    }

    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const isValid = isDevelopmentEnvironment
        ? true
        : phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phoneNumber));

      if (!isValid) {
        setError('Invalid phone number');
        return;
      }

      setError('');
      setShowCodeInput(true);
    } catch (error) {
      setError('Invalid phone number');
    }
  };

  if (!currentProfile.accountHolder || process.env.REACT_APP_PHONE_ADD_SIGN_IN_ENABLED === 'false') {
    return null;
  }

  return (
    <>
      {phoneNumber && (
        <p className="text-md text-gray-600 my-2">
          Current phone number: <span className="font-semibold">{phoneNumber}</span>
        </p>
      )}

      {!showPhoneInput && (
        <Button
          variant="dark"
          size="sm"
          className="p-2 md:w-52"
          label={phoneNumber ? 'Update Phone Number' : 'Add Phone Number'}
          onClick={() => setShowPhoneInput(true)}
        />
      )}

      {showPhoneInput && !showCodeInput && (
        <div className="flex flex-col">
          <label htmlFor="phone-number" className="mb-4 text-sm font-semibold text-gray-700">
            {phoneNumber ? 'Update Phone Number' : 'Add Your Phone Number'}
          </label>

          <InternationalPhoneInput
            value={phoneNumber}
            onChange={(phone) => {
              setPhoneNumber(phone);
              setError('');
            }}
            defaultCountry="us"
            className="my-2"
          />
          {error && <p className="!text-mls-error !text-sm mt-1 !whitespace-normal max-w-full line-clamp-2">{error}</p>}

          <div className="flex gap-2 mt-4">
            <Button
              variant="default"
              size="sm"
              className="p-2 border-[1px] border-black rounded-md"
              label="Cancel"
              onClick={() => {
                setShowPhoneInput(false);
                setError('');
              }}
            />
            <Button
              variant="dark"
              size="sm"
              className="p-2 ml-2 rounded-md"
              label="Send Verification Code"
              onClick={handleSendVerificationCode}
            />
          </div>
        </div>
      )}

      {showCodeInput && (
        <BasePhoneVerification
          phoneNumber={phoneNumber}
          spinnerDarkMode={false}
          onVerificationComplete={handleVerificationComplete}
          buttonText="Verify Phone Number"
          verificationCodeHeader={({phoneNumber}) => (
            <p className="text-md text-gray-600 my-2">
              A verification code was sent to <span className="font-semibold">{phoneNumber}</span>
            </p>
          )}
        />
      )}
    </>
  );
};

AddUpdatePhoneNumber.displayName = 'AddUpdatePhoneNumber';
