import {useGetPlayerAppointments} from 'api/MLSChallenge/useGetPlayerAppointments';
import {useProfiles} from 'api/User/useProfiles';
import Loader from 'components/MLS/Loader/Loader';
import {NewPageHeading} from 'components/MLS/NewPageHeading/NewPageHeading';
import {Pagination} from 'components/MLS/Pagination/Pagination';
import {Session} from 'pages/PlayerHome/MySessions/Session';
import {useState} from 'react';

export const MySessions = () => {
  const {currentProfile} = useProfiles();
  const [currentPage, setCurrentPage] = useState(1);
  const SESSIONS_PER_PAGE = 10;

  const {data, isLoading, isFetching} = useGetPlayerAppointments({
    playerProfileId: currentProfile?._id,
    includeFutureAppointments: false,
    sessionSummariesLimit: SESSIONS_PER_PAGE,
    sessionSummariesSkip: (currentPage - 1) * SESSIONS_PER_PAGE,
  });

  const {sessionSummaries: {summaries = [], totalCount = 0} = {}} = data || {};
  const showLoader = isLoading || isFetching;
  const count = summaries.length;
  const totalPages = Math.ceil(totalCount / SESSIONS_PER_PAGE);

  return (
    <>
      <NewPageHeading text="My Sessions" />
      {showLoader ? (
        <div className="flex min-h-[60vh] items-center justify-center">
          <Loader text="Loading..." />
        </div>
      ) : count > 0 ? (
        <div className="pb-4">
          <div data-test-id="session-list">
            {summaries.map((summary) => (
              <Session key={summary._id} session={summary} />
            ))}
          </div>
          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
        </div>
      ) : (
        <div className="flex justify-center">
          <div className="text-base text-mls-secondary">No sessions found</div>
        </div>
      )}
    </>
  );
};

MySessions.displayName = 'MySessions';
