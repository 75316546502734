import {memo, useEffect, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useProfiles} from '../../api/User/useProfiles';
import AvatarProfile from '../../assets/img/avatar-profile.png';
import {useNavigate} from 'react-router-dom';
import {getUserDisplayName} from 'utils/utils';
import {ReactComponent as CheckmarkIcon} from '../../assets/img/icons/redesign-icons/checked-circle-filled.svg';
import {Profile} from 'user/player-info.interface';
import {Modal} from 'components/Modal/Modal';
import {PlayerProfileForm, UserProfileFormInterface} from 'components/PlayerProfileForm/PlayerProfileForm';
import {isPlayer} from 'user/user-utils';
import {useCreateProfile} from 'hooks/useCreateProfile';

const PlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="size-6"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
  </svg>
);

export const Profiles = memo(() => {
  const navigate = useNavigate();
  const {currentProfile, otherProfiles, selectProfile} = useProfiles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {createProfile, isLoading: isProfileCreationLoading} = useCreateProfile();
  const [profileCreationErrorMessage, setProfileCreationErrorMessage] = useState('');
  const stableProfiles = useMemo(() => {
    const allProfiles = [...(currentProfile ? [currentProfile] : []), ...(otherProfiles ?? [])];

    // sorting them to preserve the order of profiles in rendering on profile selection for better UX only
    return allProfiles.sort((a, b) => a._id.localeCompare(b._id));
  }, [currentProfile, otherProfiles]);

  useEffect(() => {
    if (stableProfiles.length === 0) {
      navigate('/create-profile');
    } else if (stableProfiles.length === 1) {
      // Automatically navigate to homepage
      navigate('/home');
    }
  }, [stableProfiles, navigate, selectProfile]);

  const renderProfile = (profile: Profile) => (
    <div
      className="flex flex-row items-center justify-between px-4 py-2 rounded-md border-[1px] my-2 border-[1px]cursor-pointer"
      key={profile._id}
      onClick={() => selectProfile(profile._id)}
    >
      <img
        className="object-cover w-16 h-16 rounded-full"
        src={profile?.playerCardInfo?.imageSrc || profile?.avatarUrl || AvatarProfile}
        alt={getUserDisplayName(profile.firstName, profile.lastName)}
      />

      <div className="flex flex-col justify-between ml-4 w-[80%] text-left">
        <p className="mb-2 text-lg font-semibold">{getUserDisplayName(profile.firstName, profile.lastName)}</p>
        {profile.dob && <p className="text-sm text-gray-300">{profile.dob}</p>}
        {profile.location?.name && <p className="text-sm text-gray-300">{profile.location.name}</p>}
      </div>

      <div className="h-[24px] w-[24px]">
        {currentProfile?._id === profile._id && <CheckmarkIcon className="h-full w-full rounded-full fill-[green]" />}
      </div>
    </div>
  );

  const handleCreateNewProfile = async (data: UserProfileFormInterface) => {
    setIsModalOpen(true);
    try {
      await createProfile(data);
      setIsModalOpen(false);
      setProfileCreationErrorMessage('');
    } catch (error) {
      console.error('Error creating profile:', error);
      setProfileCreationErrorMessage('Something went wrong, please try again.');
    }
  };

  return (
    <>
      <Helmet>
        <title>MyTOCA | Profiles</title>
      </Helmet>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Create New Profile"
        showHeader
        modalTestId="create-new-profile-modal"
      >
        <PlayerProfileForm
          isLoading={isProfileCreationLoading}
          onSubmit={handleCreateNewProfile}
          errorMessage={profileCreationErrorMessage}
        />
      </Modal>

      <div className="flex flex-col min-h-screen bg-black">
        <div className="flex relative flex-col flex-1 items-center px-4 mx-auto w-full max-w-lg min-h-screen text-center text-white pb-8">
          <h1 className="mt-8 mb-8 text-5xl font-semibold font-teko md:mt-16 md:mb-12">Welcome Back</h1>

          {currentProfile && currentProfile.playerCardInfo?.imageSrc && (
            <div className="flex flex-col justify-center items-center mt-6 w-full md:mt-12">
              {/* Diamond Image */}
              <div className="overflow-hidden relative mb-4 w-36 h-36 transform rotate-45 md:h-48 md:w-48">
                <div className="absolute inset-0 transform scale-150 -rotate-45">
                  <img
                    src={currentProfile.playerCardInfo.imageSrc}
                    alt={getUserDisplayName(currentProfile.firstName, currentProfile.lastName)}
                    className="object-cover w-full h-full"
                  />
                </div>
              </div>
              <p className="mt-8 text-4xl font-semibold font-teko md:mt-12">
                {currentProfile?.firstName} <br />
                {currentProfile?.lastName}
              </p>
            </div>
          )}
          <p className="mt-4 text-sm font-light opacity-80 md:mt-12">Select your profile to continue</p>
          <div className="flex flex-col mt-2 w-full">{stableProfiles.map(renderProfile)}</div>

          <div className="flex flex-row gap-4 justify-center items-center mt-8">
            {isPlayer(currentProfile) && (
              <button className="p-1 rounded-full border-2 border-white" onClick={() => setIsModalOpen(true)}>
                <PlusIcon />
              </button>
            )}
            <button
              onClick={() => navigate('/home')}
              className="px-6 py-3 ml-6 text-lg font-semibold text-black bg-white rounded-md"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
});
Profiles.displayName = 'Profiles';
