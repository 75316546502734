import {useCallback} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {ExplicitImageCheckResponse, getExplicitImageCheck as getExplicitImageCheckApi} from './getExplicitImageCheck';

export interface ExplicitImageCheckRequestPayload {
  imageUrl: string;
}

export const useGetExplicitImageCheck = () => {
  const queryClient = useQueryClient();

  const checkExplicitImage = useCallback(
    async (payload: ExplicitImageCheckRequestPayload): Promise<ExplicitImageCheckResponse> => {
      try {
        const response = await queryClient.fetchQuery({
          queryKey: ['explicit-check', payload.imageUrl],
          queryFn: () => getExplicitImageCheckApi(payload.imageUrl),
        });
        return response;
      } catch (error) {
        console.log('Failed to check explicit image', error);
        return {isExplicit: false};
      }
    },
    [queryClient]
  );

  return {checkExplicitImage};
};
