import {useGetSport} from 'common/useGetSport';
import {GetAvailableSessionsParams, useGetAvailableSessions} from './useGetAvailableSessions';
import {getPresetSessionTypeNamesBySport} from 'common/fko-utils';

/**
 * Gets FKO available sessions, providing preconfigured sessionTypeNames.
 */
export const useGetFkoAvailableSessions = (params: GetAvailableSessionsParams & {hasInitialDateParam?: boolean}) => {
  const {sport} = useGetSport();
  const {sessionTypeNames, ..._params} = params;
  const presetSessionTypeNames = getPresetSessionTypeNamesBySport({sport});

  const fkoSessionTypeNames = sessionTypeNames
    ? Array.isArray(sessionTypeNames)
      ? sessionTypeNames
      : [sessionTypeNames]
    : presetSessionTypeNames;

  return useGetAvailableSessions({..._params, sessionTypeNames: fkoSessionTypeNames});
};
