import clsx from 'clsx';
import {useMemo} from 'react';
import {NavHeading} from '../NavHeading/NavSection';
import {ReactComponent as FirstRankMedal} from '../../../assets/img/mls/first-rank-medal.svg';
import {ReactComponent as SecondRankMedal} from '../../../assets/img/mls/second-rank-medal.svg';
import {ReactComponent as ThirdRankMedal} from '../../../assets/img/mls/third-rank-medal.svg';
import {useProfiles} from 'api/User/useProfiles';
import {useGetLeaderboardList} from 'api/Leaderboards/useGetLeaderboardList';
import {useGetLeaderboardRankings} from 'api/Leaderboards/useGetLeaderboardRankings';
import Loader from 'components/MLS/Loader/Loader';
import MyTocaLogo from '../../../assets/img/mls/my-toca-logo-black.svg';

const rankBasedEntryStyles = {
  1: {
    mainContainerCustomClassNames: 'ml-2',
    diamondContainerCustomClassNames: 'w-28 h-28 mb-2 sm:w-40 sm:h-40 sm:mb-4 md:w-52 md:h-52 md:mb-8',
    innerBorderCustomClassNames: 'w-24 h-24 sm:w-36 sm:h-36 md:w-48 md:h-48',
    imageContainerCustomClassNames: 'w-20 h-20 sm:w-32 sm:h-32 md:w-44 md:h-44',
    borderColor: '#C0A869',
    medal: FirstRankMedal,
    customNameTextStyles: 'text-mls-primary w-32 sm:w-40 md:w-60',
    dataTestId: 'leaderboard-entry-1',
  },
  2: {
    mainContainerCustomClassNames: 'mt-2',
    diamondContainerCustomClassNames: 'w-20 h-20 sm:w-28 sm:h-28 sm:mb-2 md:w-36 md:h-36 md:mb-4',
    innerBorderCustomClassNames: 'w-16 h-16 sm:w-24 sm:h-24 md:w-32 md:h-32',
    imageContainerCustomClassNames: 'w-12 h-12 sm:w-20 sm:h-20 md:w-28 md:h-28',
    borderColor: '#A6A6AE',
    medal: SecondRankMedal,
    dataTestId: 'leaderboard-entry-2',
    customNameTextStyles: 'text-mls-secondary w-24 sm:w-28 md:w-36',
  },
  3: {
    mainContainerCustomClassNames: '',
    diamondContainerCustomClassNames: 'w-16 h-16 sm:w-20 sm:h-20 sm:mb-2 md:w-28 md:h-28 md:mb-4',
    innerBorderCustomClassNames: 'w-12 h-12 sm:w-16 sm:h-16 md:w-24 md:h-24',
    imageContainerCustomClassNames: 'w-10 h-10 sm:w-12 sm:h-12 md:w-20 md:h-20',
    borderColor: '#AE7D5E',
    medal: ThirdRankMedal,
    dataTestId: 'leaderboard-entry-3',
    customNameTextStyles: 'text-mls-secondary w-16 sm:w-20 md:w-28',
  },
};

const LeaderboardEntry = ({imageUrl, rank, name}: {imageUrl: string; name: string; rank: 1 | 2 | 3}) => {
  const {
    mainContainerCustomClassNames,
    diamondContainerCustomClassNames,
    borderColor,
    innerBorderCustomClassNames,
    imageContainerCustomClassNames,
    medal: Medal,
    dataTestId,
    customNameTextStyles,
  } = rankBasedEntryStyles[rank];

  return (
    <div className={clsx('flex flex-col items-center', mainContainerCustomClassNames)}>
      <div className={clsx('relative flex items-center justify-center', diamondContainerCustomClassNames)}>
        <div
          className={clsx('absolute rotate-45 border-4 bg-white', innerBorderCustomClassNames)}
          style={{borderColor}}
        ></div>
        {imageUrl ? (
          <div className={clsx('absolute rotate-45 overflow-hidden', imageContainerCustomClassNames)}>
            <img src={imageUrl} className="h-full w-full -rotate-45 scale-[1.4] object-cover" />
          </div>
        ) : (
          <div
            className={clsx(
              'absolute flex rotate-45 items-center justify-center overflow-hidden',
              imageContainerCustomClassNames
            )}
          >
            <img src={MyTocaLogo} className="w-[85%] -rotate-45 object-cover" />
          </div>
        )}
      </div>
      <div className="z-10 flex flex-col items-center">
        <Medal className="mt-3 h-10 w-10" />
        <div
          className={clsx('mt-2 text-center text-sm font-semibold truncate', customNameTextStyles)}
          data-test-id={dataTestId}
        >
          {name}
        </div>
      </div>
    </div>
  );
};

export const Leaderboard = () => {
  const {currentProfile} = useProfiles();
  const {clientId, siteId: locationId} = currentProfile?.mboDetails || {};
  const {data, isLoading: leaderboardLoading} = useGetLeaderboardList({mboClientId: clientId, locationId});

  const leaderboardId = data?.[0]?.leaderboard?._id;
  const {data: firstLeaderboardRankings, isLoading: rankingsLoading} = useGetLeaderboardRankings({leaderboardId});

  const isPlayerInMultipleLeaderboards = (data?.length ?? 0) > 1;
  const isLoading = clientId && locationId && (leaderboardLoading || ((data?.length ?? 0) > 0 && rankingsLoading));

  const href = isPlayerInMultipleLeaderboards || !leaderboardId ? '/leaderboards' : `/leaderboards/${leaderboardId}`;

  const topThreePlayersInDisplayOrder = useMemo(() => {
    if (!firstLeaderboardRankings?.rankings) {
      return [];
    }

    const topThreeRankings = [...firstLeaderboardRankings.rankings]
      .sort((a, b) => a.overallRank - b.overallRank)
      .slice(0, 3);

    return (
      topThreeRankings.length === 3 ? [topThreeRankings[1], topThreeRankings[0], topThreeRankings[2]] : topThreeRankings
    ).map((ranking) => ({
      imageUrl:
        ranking.clientId === clientId ? currentProfile?.playerCardInfo?.imageSrc || '' : ranking?.avatarUrl || '',
      name: ranking.athleteName,
      rank: ranking.overallRank as 1 | 2 | 3,
    }));
  }, [firstLeaderboardRankings, clientId, currentProfile]);

  return (
    <div className="w-full">
      <NavHeading title={isPlayerInMultipleLeaderboards ? 'Leaderboards' : 'Leaderboard'} href={href} />
      {isLoading ? (
        <div className="flex min-h-[310px] items-center justify-center">
          <Loader />
        </div>
      ) : topThreePlayersInDisplayOrder.length > 0 ? (
        <div
          className="flex w-full items-end py-2 sm:px-16 sm:pt-8"
          style={{
            justifyContent: topThreePlayersInDisplayOrder.length === 1 ? 'center' : 'space-between',
          }}
        >
          {topThreePlayersInDisplayOrder.map((entry, index) => (
            <LeaderboardEntry imageUrl={entry.imageUrl} rank={entry.rank} name={entry.name} key={index} />
          ))}
        </div>
      ) : (
        <p className="mt-4 min-h-20 rounded-lg bg-mls-grey p-6 text-center text-base">
          TOCA Skills Showcase Coming Soon!
        </p>
      )}
    </div>
  );
};
