import React from 'react';
import {usePhoneInput, CountrySelector, DialCodePreview} from 'react-international-phone';
import 'react-international-phone/style.css';

interface InternationalPhoneInputProps {
  value: string;
  onChange: (phone: string) => void;
  defaultCountry?: string;
  disabled?: boolean;
  className?: string;
}

export const InternationalPhoneInput: React.FC<InternationalPhoneInputProps> = ({
  value,
  onChange,
  defaultCountry = 'us',
  disabled = false,
  className = '',
}) => {
  const {country, setCountry, inputRef} = usePhoneInput({
    defaultCountry,
    value,
    onChange: ({phone}) => onChange(phone),
  });

  return (
    <div className={className}>
      <div className="flex gap-3 items-center">
        <div
          data-test-id="country-selector"
          className="flex items-center justify-center border border-gray-300 rounded-md pr-3 bg-white"
        >
          <DialCodePreview dialCode={country.dialCode} prefix="+" className="!border-0 !text-[14px]" />
          <CountrySelector
            selectedCountry={country.iso2}
            onSelect={(selected) => setCountry(selected.iso2)}
            disabled={disabled}
            buttonStyle={{border: 'none', background: 'transparent'}}
            className="ml-1"
            flagStyle={{width: '24px', height: '24px'}}
          />
        </div>

        <input
          data-test-id="phone-input"
          ref={inputRef}
          type="tel"
          value={(value || '').replace(`+${country.dialCode}`, '')}
          onChange={(e) => {
            onChange(`+${country.dialCode}${e.target.value}`);
          }}
          disabled={disabled}
          className="border text-base text-black rounded-md p-2 w-full"
        />
      </div>
    </div>
  );
};
