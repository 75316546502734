import * as tf from '@tensorflow/tfjs';
import '@tensorflow/tfjs-backend-wasm';
import {setWasmPaths} from '@tensorflow/tfjs-backend-wasm';

/**
 * Initialize TensorFlow.js backend (WebGL, WASM, or CPU)
 */
export async function initializeTFBackend(): Promise<void> {
  setWasmPaths('/tfmodel/');
  try {
    await tf.setBackend('wasm');
  } catch (error) {
    try {
      await tf.setBackend('webgl');
    } catch (wasmError) {
      console.warn('WebGL and WASM backends failed, falling back to CPU:', wasmError);
      await tf.setBackend('cpu');
    }
  }
  await tf.ready();
  console.log('Using TensorFlow.js backend:', tf.getBackend());
}

/**
 * Convert a File to an HTMLImageElement
 */
export function fileToHTMLImage(file: File): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    const reader = new FileReader();
    reader.onload = () => {
      img.src = reader.result as string;
      img.onload = () => resolve(img);
      img.onerror = (err) => reject(err);
    };
    reader.onerror = (err) => reject(err);
    reader.readAsDataURL(file);
  });
}

/**
 * Convert a URL to a File object
 */
export async function urlToFile(url: string): Promise<File> {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], 'image.jpg', {type: 'image/jpeg'});
}

/**
 * Format error message with consistent pattern
 */
export function formatError(error: unknown, context: string): string {
  return `Failed to ${context}: ${error instanceof Error ? error.message : 'Unknown error'}`;
}
