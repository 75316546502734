import {memo, useState, useCallback, useRef, useEffect} from 'react';
import styles from './LeaderboardInfoPane.module.scss';
import {ReactComponent as AccordionIcon} from '../../../../assets/img/icons/accordion-arr-down.svg';
import {DateTime} from 'luxon';
import {LeaderboardRankings} from 'api/Leaderboards/leaderboard.types';

export const LeaderboardInfoPane = memo(({leaderboard}: {leaderboard: LeaderboardRankings}) => {
  const [isOpen, setIsOpen] = useState(false);
  const heightRef = useRef<HTMLDivElement>(null);
  // const responsiveHeight = window.innerWidth < 1800 ? '64px' : '112px';
  const responsiveHeight = '64px';

  useEffect(() => {
    if (window.innerWidth >= 768) {
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  const toggleOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const metric = 'Total Score'; // TODO add to leaderboard data type?
  const {name, startAge, endAge, startDateTime, endDateTime, locations, description, rankingOrder} =
    leaderboard.leaderboard;

  const startDateFormatted = DateTime.fromISO(startDateTime).toFormat('LLL d, h:mma');
  const endDateFormatted = DateTime.fromISO(endDateTime).toFormat('LLL d, h:mma');

  return (
    <div
      ref={heightRef}
      className={styles.container}
      style={isOpen && heightRef.current ? {height: `${heightRef.current.scrollHeight}px`} : {height: responsiveHeight}}
    >
      <div className={styles.collapsedContent}>
        <button onClick={toggleOpen} className={styles.toggle}>
          <div className={`${styles.adornment} ${isOpen && styles.isOpen}`}>
            <AccordionIcon />
          </div>
        </button>
        <h1>{name}</h1>
      </div>
      <div className={styles.expandedContent}>
        <p>
          <span data-test-id="leaderboard-start-date" className={styles.value}>
            {startDateFormatted}
          </span>{' '}
          -{' '}
          <span data-test-id="leaderboard-end-date" className={styles.value}>
            {endDateFormatted}
          </span>
        </p>
        <p className={styles.description}>
          <span>Description: </span>
          <span className={styles.value} data-test-id="leaderboard-description">
            {description}
          </span>
        </p>
        <p>
          <span>Centers: </span>
          <span className={styles.value} data-test-id="leaderboard-locations">
            {locations && locations.map((loc) => loc.name).join(', ')}
          </span>
        </p>
        <p className={styles.metric}>
          <span>Metric: </span>
          <span className={styles.value} data-test-id="leaderboard-metric">
            {metric}
          </span>
        </p>
        <p>
          <span>Ranking Order: </span>
          <span data-test-id="leaderboard-ranking-order" className={styles.value}>
            {rankingOrder === 'desc' ? `Highest First` : `Lowest First`}
          </span>
        </p>
        <p>
          <span>Age: </span>
          <span data-test-id="leaderboard-age" className={styles.value}>
            <span className={styles.value}>{startAge}</span> to <span className={styles.value}>{endAge}</span>
          </span>
        </p>
      </div>
    </div>
  );
});
LeaderboardInfoPane.displayName = 'LeaderboardInfoPane';
