import {useEffect, useRef, useMemo, memo} from 'react';
import {useColors} from 'hooks/MLSChallenge/usePatterns';
import {ReactComponent as JerseyBackSVG} from '../../../assets/img/mls/jersey-back.svg';

const PLAYER_CARD_CONFIG = {
  fontSize: 6,
  nameTop: 14,
  height: '56px',
  width: '40px',
  numberFontSize: '12px',
  numberTop: '20px',
  maxWidth: 24,
  reduceSize: 0.5,
  maxNameLength: 10,
};

const DEFAULT_CONFIG = {
  fontSize: 28,
  nameTop: 86,
  height: '369px',
  width: '264px',
  numberFontSize: '88px',
  numberTop: '110px',
  maxWidth: 160,
  reduceSize: 2,
  maxNameLength: 10,
};

interface JerseyBackProps {
  name: string;
  number: string;
  selectedColorIndex: number;
  isPlayerCard?: boolean;
}

export const JerseyBack = memo(({name, number, selectedColorIndex = 0, isPlayerCard = false}: JerseyBackProps) => {
  const colors = useColors();
  const color = colors[selectedColorIndex];
  console.log('selectedColorIndex', selectedColorIndex);
  console.log('color', color);

  // Select configuration based on the type
  const config = useMemo(() => (isPlayerCard ? PLAYER_CARD_CONFIG : DEFAULT_CONFIG), [isPlayerCard]);

  const textRef = useRef<HTMLSpanElement>(null);

  // Adjust font size dynamically based on name length
  useEffect(() => {
    if (!textRef.current) {
      return;
    }

    let newFontSize = config.fontSize;
    let newNameTop = config.nameTop;

    textRef.current.style.fontSize = `${newFontSize}px`;
    textRef.current.style.top = `${newNameTop}px`;

    while (textRef.current.scrollWidth > config.maxWidth && name.length <= config.maxNameLength) {
      newFontSize -= config.reduceSize;
      newNameTop += config.reduceSize;
      textRef.current.style.fontSize = `${newFontSize}px`;
      textRef.current.style.top = `${newNameTop}px`;
    }
  }, [name, config]);

  return (
    <div
      className="relative flex max-w-fit justify-center"
      style={
        {
          '--jersey-color': color?.lightColor,
          '--stripe-color': color?.darkColor,
          '--text-color': color?.textColor,
        } as React.CSSProperties
      }
    >
      <JerseyBackSVG height={config.height} width={config.width} />

      {/* Name */}
      <span
        ref={textRef}
        data-test-id="jersey-back-name"
        className="absolute text-center font-teko font-bold"
        style={{
          color: color.textColor,
        }}
      >
        {name}
      </span>

      {/* Number */}
      <div
        className="absolute w-full text-center font-teko font-bold"
        data-test-id="jersey-back-number"
        style={{
          color: color.textColor,
          fontSize: config.numberFontSize,
          top: config.numberTop,
        }}
      >
        {number}
      </div>
    </div>
  );
});

JerseyBack.displayName = 'JerseyBack';
