import {player} from 'api/api';
import axios from 'axios';
import {Area} from 'react-easy-crop/types';

export interface CroppedImageRequestPayload {
  imageSrc: string;
  croppedArea: Area;
}

export interface CroppedImageResponse {
  croppedImageDataUrl: string;
}

export const getCroppedImage = async (imageSrc: string, croppedArea: Area): Promise<CroppedImageResponse> => {
  const {data} = await axios.post(
    `${player}/player-card/avatar/crop`,
    {
      imageSrc,
      croppedArea,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  return data as CroppedImageResponse;
};
