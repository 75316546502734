import {useQuery} from '@tanstack/react-query';
import {getPlayerSession} from 'api/api';
import {SessionData} from 'components/Baseline/SessionTable/MobileBaselineSessionTable';

export type GetPlayerSessionParams = {clientId?: string | null; siteId?: number | null; sessionId?: string | null};

type ResponseData = SessionData & {
  totalBallsFired: number;
  repetitions: number;
  isBaselineSession: boolean;
  startTime: string;
};

export const useGetPlayerSession = ({sessionId, clientId, siteId}: GetPlayerSessionParams) => {
  return useQuery<ResponseData>({
    queryKey: ['player-session', clientId, siteId, sessionId],
    queryFn: () => getPlayerSession({clientId, siteId, sessionId}),
    enabled: !!clientId && !!siteId && !!sessionId,
  });
};
