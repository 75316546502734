import {useState} from 'react';
import {useSetGlobalLoading} from '../../hooks/useGlobalLoading';
import {Link, useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {ForgetPasswordForm} from './ForgetPasswordForm';
import {auth as fireBaseAuth} from '../../firebase/config';
import {sendPasswordResetEmail} from 'firebase/auth';
import {logAnalyticsEvent} from '../../common/analytics-events';
import {ReactComponent as ChevronLeft} from '../../assets/img/icons/redesign-icons/chevron-left.svg';

export const ForgetPassword = () => {
  const navigate = useNavigate();
  const {setGlobalLoading} = useSetGlobalLoading();
  const [errMes, setErrMes] = useState<string>('');
  const [sendToEmail, setSendToEmail] = useState<string>('');
  const [successMes, setSuccessMes] = useState<boolean>(false);

  const onSubmitHandler = (data: any) => {
    setGlobalLoading(true);
    logAnalyticsEvent('passwordReset', {
      email: data.email,
    });
    sendPasswordResetEmail(fireBaseAuth, data.email)
      .then(() => {
        setSuccessMes(true);
        setSendToEmail(data.email);
      })
      .catch((error) => {
        if (error.code === 'auth/user-not-found') {
          setErrMes('The email does not exist');
        } else {
          setErrMes('Operation failed');
        }
      })
      .finally(() => setGlobalLoading(false));
  };

  return (
    <>
      <Helmet>
        <title>MyTOCA | Forgot Password</title>
      </Helmet>

      <div className="bg-black">
        <div className="flex relative flex-col min-h-screen max-w-lg mx-auto flex-1 text-white items-start md:items-start justify-start px-6 bg-rsed-500">
          <div className="flex flex-row items-center justify-center mt-16">
            <button onClick={() => navigate(-1)} className="text-white">
              <ChevronLeft fill="white" className="w-4 h-4" />
            </button>
            <h2 className="ml-2 text-white uppercase text-xl md:text-2xl font-boldmd:mt-28">Reset Password</h2>
          </div>

          <div className="w-full">
            {!successMes ? (
              <div className="flex flex-col mt-4">
                <p className="text-white text-lg my-4">Forgot your password?</p>
                <ForgetPasswordForm errorMessage={errMes} onSubmitHandler={onSubmitHandler} />

                <p className="mt-8 text-sm">
                  Don’t have an account?{' '}
                  <Link className="underline" to={'/auth/signup'}>
                    {' '}
                    Sign up for free
                  </Link>
                </p>
              </div>
            ) : (
              <div className="flex flex-col  gap-6">
                <p className="mt-8 text-lg">Check your email</p>
                <div className="flex flex-col text-sm">
                  <p>
                    We sent instructions to reset your password to <span>{sendToEmail}</span>
                  </p>
                  <p>If you don’t see it, you may have to check your spam folder.</p>
                </div>
                <p className="underline text-base">
                  <Link to={'/auth/signin'}> Sign in</Link>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
