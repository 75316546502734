import {useCallback, useEffect, useMemo, useState} from 'react';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {merge} from 'lodash';
import {JerseyFront} from './JerseyFront/JerseyFront';
import {JerseyBack} from './JerseyBack/JerseyBack';
import Tabs from 'components/MLS/Tabs/Tabs';
import {useColors} from 'hooks/MLSChallenge/usePatterns';
import {StandardInput} from 'components/Input/StandardInput/StandardInput';
import {ReactComponent as CloseIcon} from '../../assets/img/mls/close-icon.svg';
import {Button} from 'components/MLS/Button/Button';
import {useProfiles} from 'api/User/useProfiles';
import {JerseyInfo, Profile} from 'user/player-info.interface';
import {updateJerseyInfo} from 'api/api';

export const CustomizeJersey = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const colors = useColors();
  const {currentProfile, refetchUserAndProfiles} = useProfiles();
  const {jerseyInfo, _id: currentProfileId} = currentProfile || {};

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedColorIndex, setSelectedColorIndex] = useState(0);
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');

  const mutateUpdateJersey = useMutation<void, unknown, JerseyInfo, {prevProfiles: Profile[]}>({
    mutationFn: (updatedJerseyInfo) => updateJerseyInfo(updatedJerseyInfo).then((res: any) => res.data),
    onMutate: (updatedJerseyInfo) => {
      const prevProfiles = queryClient.getQueryData<Profile[]>(['user', 'profiles']) || [];
      const profiles = [...prevProfiles];
      const profileToBeUpdated = profiles.find((profile: Profile) => profile._id === currentProfileId);

      if (profileToBeUpdated) {
        merge(profileToBeUpdated, {jerseyInfo: updatedJerseyInfo});
        queryClient.setQueryData(['user', 'profiles'], profiles);
      }

      return {prevProfiles};
    },
    onError: (error, _, context) => {
      toast.error('Failed to update jersey info');
      if (context?.prevProfiles) {
        queryClient.setQueryData(['user', 'profiles'], context.prevProfiles);
      }
      console.error('Error updating user', error);
    },
    onSuccess: () => {
      refetchUserAndProfiles();
      navigate('/');
    },
  });

  const handleTabChange = useCallback((index: number) => {
    setSelectedTab(index);
  }, []);

  const handleColorSelect = useCallback((index: number) => {
    setSelectedColorIndex(index);
  }, []);

  useEffect(() => {
    if (jerseyInfo) {
      setName(jerseyInfo.jerseyName);
      setNumber(jerseyInfo.jerseyNumber.toString());

      const colorIndex = colors.findIndex((color) => color.lightColor === jerseyInfo.jerseyLightColor);
      if (colorIndex !== -1) {
        setSelectedColorIndex(colorIndex);
      }
    }
  }, [jerseyInfo, colors]);

  const onClickSaveHandler = useCallback(() => {
    if (!name || !number) {
      toast.error('Please enter a name and number');
      return;
    }

    const updatedJerseyInfo: JerseyInfo = {
      jerseyName: name,
      jerseyNumber: parseInt(number, 10),
      jerseyLightColor: colors[selectedColorIndex].lightColor,
      jerseyDarkColor: colors[selectedColorIndex].darkColor,
      jerseyTextColor: colors[selectedColorIndex].textColor,
    };

    mutateUpdateJersey.mutate(updatedJerseyInfo);
  }, [name, number, selectedColorIndex, colors, mutateUpdateJersey]);

  const tabs = useMemo(
    () => [
      {label: 'Front', content: <JerseyFront selectedColorIndex={selectedColorIndex} />},
      {label: 'Back', content: <JerseyBack name={name} number={number} selectedColorIndex={selectedColorIndex} />},
    ],
    [selectedColorIndex, name, number]
  );

  return (
    <div data-test-id="jersey-customization-container" className="min-h-[100dvh]">
      <div className="flex w-full flex-col items-center bg-mls-grey p-4">
        <div className="mb-4 flex w-full items-center justify-between">
          <CloseIcon className="cursor-pointer" onClick={() => navigate('/')} />
          <Button label="Save" aria-label="Save Jersey" onClick={onClickSaveHandler} />
        </div>

        <div className="flex justify-center">{tabs[selectedTab].content}</div>

        <div className="mt-4 flex w-[65%] justify-center sm:w-[50%]">
          <Tabs options={tabs} defaultIndex={0} hideContent onChange={handleTabChange} containerClassName="bg-white" />
        </div>
      </div>

      <div className="bg-white">
        {selectedTab === 0 && (
          <div className="flex flex-col items-center justify-center p-8">
            <div className="mb-4 text-xl font-semibold">Select Color</div>
            <div className="scrollbar-hide mt-4 flex max-w-[100%] overflow-x-auto">
              <div className="flex gap-4 text-center">
                {colors.map((color, index) => (
                  <div
                    key={index}
                    className="flex h-12 w-12 flex-shrink-0 cursor-pointer items-center justify-center rounded-full sm:h-20 sm:w-20"
                    onClick={() => handleColorSelect(index)}
                    style={{
                      border: `1px solid ${index === selectedColorIndex ? 'black' : 'lightgray'}`,
                    }}
                    aria-label={`Select color ${color.lightColor}`}
                  >
                    <div
                      className="h-6 w-6 rounded-full sm:h-10 sm:w-10"
                      style={{backgroundColor: color.lightColor}}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {selectedTab === 1 && (
          <div className="flex flex-col items-center justify-center gap-4 p-8">
            <div className="mb-4 text-xl font-semibold">Enter Your Name & Number</div>

            <StandardInput
              placeholder="Jersey Name"
              value={name}
              onChange={(e) => {
                const inputValue = (e.target as HTMLInputElement).value;
                const alphabeticValue = inputValue.replace(/[^a-zA-Z]/g, '');
                setName(alphabeticValue);
              }}
              maxLength={10}
              aria-label="Jersey Name Input"
            />
            <StandardInput
              placeholder="Jersey Number"
              value={number}
              onChange={(e) => setNumber((e.target as HTMLInputElement).value)}
              maxLength={2}
              aria-label="Jersey Number Input"
            />
          </div>
        )}
      </div>
    </div>
  );
};
