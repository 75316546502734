import {auth as fireBaseAuth} from '../firebase/config';
import {Profile} from './player-info.interface';
import {User} from './user.interface';

const localStorageKeys = {
  accessToken: 'tocaToken',
  currentProfile: {
    accountId: 'currentAccountId',
    profileId: 'currentProfileId',
    pathwayId: 'playerPathwayId',
    activePathway: 'activePathway',
    currentSession: 'curSession',
    accountHolderProfileId: 'accountHolderProfileId',
  },
  postLoginUri: 'postLoginUri',
};

export function getAccessToken() {
  return localStorage.getItem(localStorageKeys.accessToken);
}

export function setAccessToken(token: any) {
  if (token) {
    localStorage.setItem(localStorageKeys.accessToken, token);
  }
}

export async function refreshAccessToken() {
  const refreshedToken = await fireBaseAuth.currentUser?.getIdToken(true);
  if (refreshedToken) {
    setAccessToken(refreshedToken);
  }
  return refreshedToken;
}

export function setCurrentAccountId(accountId: string) {
  localStorage.setItem(localStorageKeys.currentProfile.accountId, accountId);
}

export function getCurrentAccountId() {
  return localStorage.getItem(localStorageKeys.currentProfile.accountId);
}

export function setCurrentAccountHolderProfileId(profileId: string) {
  localStorage.setItem(localStorageKeys.currentProfile.accountHolderProfileId, profileId);
}

export function getCurrentAccountHolderProfileId() {
  return localStorage.getItem(localStorageKeys.currentProfile.accountHolderProfileId);
}

export function getCurrentProfileId() {
  return localStorage.getItem(localStorageKeys.currentProfile.profileId);
}

export function setCurrentProfileId(value: string) {
  localStorage.setItem(localStorageKeys.currentProfile.profileId, value);
}

export function getCurrentPlayerPathwayId() {
  return localStorage.getItem(localStorageKeys.currentProfile.pathwayId);
}

export function setCurrentPlayerPathwayId(value: any) {
  localStorage.setItem(localStorageKeys.currentProfile.pathwayId, value);
}

export function getCurrentPlayerSession() {
  return localStorage.getItem(localStorageKeys.currentProfile.currentSession);
}

export function setCurrentPlayerSession(value: any) {
  localStorage.setItem(localStorageKeys.currentProfile.currentSession, value);
}

export function removeAllPlayerDataFromLocalStorage() {
  localStorage.removeItem(localStorageKeys.currentProfile.pathwayId);
  localStorage.removeItem(localStorageKeys.currentProfile.activePathway);
  localStorage.removeItem(localStorageKeys.currentProfile.currentSession);
}

export function isTrainer(profile?: Profile) {
  return Boolean(profile?.role?.includes('trainer'));
}

export function isTrainerUser(user?: User) {
  return user?.profiles?.find((p) => isTrainer(p));
}

export function isCoach(profile?: Profile) {
  return Boolean(profile?.role?.includes('coach'));
}

export function isCoachUser(user?: User) {
  return user?.profiles?.find((p) => isCoach(p));
}

export function isDirector(profile?: Profile) {
  return Boolean(profile?.role?.includes('director'));
}

export function isDirectorUser(user?: User) {
  return user?.profiles?.find((p) => isDirector(p));
}

export function isPlayer(profile?: Profile) {
  return Boolean(profile?.role?.includes('player'));
}

export function isPlayerUser(user?: User) {
  return user?.profiles?.find((p) => isPlayer(p));
}

export function isAdmin(profile?: Profile) {
  return Boolean(profile?.role?.includes('admin'));
}

export function isAdminUser(user?: User) {
  return user?.profiles?.find((p) => isAdmin(p));
}

export function isAdminOrTrainer(profile?: Profile) {
  return isTrainer(profile) || isAdmin(profile);
}

export function setPostLoginUri(uri: string | null | undefined) {
  if (uri) {
    localStorage.setItem(localStorageKeys.postLoginUri, uri);
  } else {
    localStorage.removeItem(localStorageKeys.postLoginUri);
  }
}

export function getPostLoginUri() {
  return localStorage.getItem(localStorageKeys.postLoginUri);
}

/**
 * Returns the stored URI that the user originally navigated to before they got redirected to the login screen.
 * If no original URI is stored, the default URI is returned.
 * This function also clears the stored original URI.
 *
 * @param defaultUri Default URI to redirect the user to after login, if no original URI is stored in local storage.
 * @returns The URI/path to which to redirect the user to after login.
 */
export function getPostLoginRedirectUri(defaultUri = '/home') {
  const storedUri = getPostLoginUri();
  setPostLoginUri(null);
  return storedUri ?? defaultUri;
}

export function getAge(birthDate: string | Date | undefined) {
  if (!birthDate) {
    return undefined;
  }
  let date;
  if ('string' === typeof birthDate) {
    try {
      date = new Date(birthDate);
    } catch (err) {
      console.error('Failed to convert given birthDate string into a Date. Given:', birthDate);
      return undefined;
    }
  } else if (birthDate instanceof Date) {
    date = birthDate;
  }
  if (!date) {
    return undefined;
  }
  const today = new Date();
  let age = today.getFullYear() - date.getFullYear();
  const m = today.getMonth() - date.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
    age--;
  }
  return age;
}

export const createRandomPassword = (length = 12) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$*&';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};
