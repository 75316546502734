import {ReactComponent as Pattern1} from '../../assets/img/mls/player-card-pattern1.svg';
import {ReactComponent as Pattern2} from '../../assets/img/mls/player-card-pattern2.svg';
import {ReactComponent as Pattern3} from '../../assets/img/mls/player-card-pattern3.svg';
import {ReactComponent as Pattern4} from '../../assets/img/mls/player-card-pattern4.svg';
import {ReactComponent as Pattern5} from '../../assets/img/mls/player-card-pattern5.svg';
import {ReactComponent as Pattern6} from '../../assets/img/mls/player-card-pattern6.svg';
import {ReactComponent as Pattern7} from '../../assets/img/mls/player-card-pattern7.svg';
import {ReactComponent as Pattern8} from '../../assets/img/mls/player-card-pattern8.svg';
import {ReactComponent as Pattern9} from '../../assets/img/mls/player-card-pattern9.svg';
import {ReactComponent as Pattern10} from '../../assets/img/mls/player-card-pattern10.svg';
import BBackground1 from '../../assets/img/mls/branded-player-card-bg/b-background-1.png';
import BBackground2 from '../../assets/img/mls/branded-player-card-bg/b-background-2.png';
import BBackground3 from '../../assets/img/mls/branded-player-card-bg/b-background-3.png';
import BBackground4 from '../../assets/img/mls/branded-player-card-bg/b-background-4.png';
import BBackground5 from '../../assets/img/mls/branded-player-card-bg/b-background-5.png';
import BBackground6 from '../../assets/img/mls/branded-player-card-bg/b-background-6.png';
import BBackground7 from '../../assets/img/mls/branded-player-card-bg/b-background-7.png';
import BBackground8 from '../../assets/img/mls/branded-player-card-bg/b-background-8.jpeg';
import BBackground9 from '../../assets/img/mls/branded-player-card-bg/b-background-9.png';

interface PatternMetadata {
  pattern: any; // SVG component or PNG image path
  type: 'svg' | 'png';
  showIndex: number; // For controlling display order
  patternIndex: number; // For backend storage, maintaining existing indices
  disableColorSelection: boolean; // Whether color selection should be disabled for this pattern, it's disabled for branded backgrounds/png
  defaultColors?: {
    // Default colors for patterns with disabled color selection
    lightColor: string;
    darkColor: string;
    textColor: string;
  };
}

export const usePatterns = () => {
  const patterns: PatternMetadata[] = [
    // SVG patterns - color selection enabled, colors come from user selection
    {pattern: Pattern1, type: 'svg', showIndex: 0, patternIndex: 0, disableColorSelection: false},
    {pattern: Pattern2, type: 'svg', showIndex: 1, patternIndex: 1, disableColorSelection: false},
    {pattern: Pattern3, type: 'svg', showIndex: 2, patternIndex: 2, disableColorSelection: false},
    {pattern: Pattern4, type: 'svg', showIndex: 3, patternIndex: 3, disableColorSelection: false},
    {pattern: Pattern5, type: 'svg', showIndex: 4, patternIndex: 4, disableColorSelection: false},
    {pattern: Pattern6, type: 'svg', showIndex: 5, patternIndex: 5, disableColorSelection: false},
    {pattern: Pattern7, type: 'svg', showIndex: 6, patternIndex: 6, disableColorSelection: false},
    {pattern: Pattern8, type: 'svg', showIndex: 7, patternIndex: 7, disableColorSelection: false},
    {pattern: Pattern9, type: 'svg', showIndex: 8, patternIndex: 8, disableColorSelection: false},
    {pattern: Pattern10, type: 'svg', showIndex: 9, patternIndex: 9, disableColorSelection: false},

    // PNG patterns - color selection disabled, using default colors, light and darkColor for png backgrounds aren't used, they exist for compatibility with the svg patterns, text color is used for text on the png backgrounds
    {
      pattern: BBackground1,
      type: 'png',
      showIndex: 10,
      patternIndex: 10,
      disableColorSelection: true,
      defaultColors: {
        lightColor: '#FFFFFF',
        darkColor: '#FFFFFF',
        textColor: '#CDA349',
      },
    },
    {
      pattern: BBackground2,
      type: 'png',
      showIndex: 11,
      patternIndex: 11,
      disableColorSelection: true,
      defaultColors: {
        lightColor: '#FFFFFF',
        darkColor: '#FFFFFF',
        textColor: '#F54D5E',
        // textColor: '#CDA349',
      },
    },
    {
      pattern: BBackground3,
      type: 'png',
      showIndex: 12,
      patternIndex: 12,
      disableColorSelection: true,
      defaultColors: {
        lightColor: '#FFFFFF',
        darkColor: '#FFFFFF',
        textColor: '#FFFFFF',
      },
    },
    {
      pattern: BBackground4,
      type: 'png',
      showIndex: 13,
      patternIndex: 13,
      disableColorSelection: true,
      defaultColors: {
        lightColor: '#FFFFFF',
        darkColor: '#FFFFFF',
        textColor: '#FFFFFF',
      },
    },
    {
      pattern: BBackground5,
      type: 'png',
      showIndex: 14,
      patternIndex: 14,
      disableColorSelection: true,
      defaultColors: {
        lightColor: '#FFFFFF',
        darkColor: '#FFFFFF',
        textColor: '#FFFFFF',
      },
    },
    {
      pattern: BBackground6,
      type: 'png',
      showIndex: 15,
      patternIndex: 15,
      disableColorSelection: true,
      defaultColors: {
        lightColor: '#FFFFFF',
        darkColor: '#FFFFFF',
        textColor: '#FFFFFF',
      },
    },
    {
      pattern: BBackground7,
      type: 'png',
      showIndex: 16,
      patternIndex: 16,
      disableColorSelection: true,
      defaultColors: {
        lightColor: '#FFFFFF',
        darkColor: '#FFFFFF',
        textColor: '#FFFFFF',
      },
    },
    {
      pattern: BBackground8,
      type: 'png',
      showIndex: 17,
      patternIndex: 17,
      disableColorSelection: true,
      defaultColors: {
        lightColor: '#FFFFFF',
        darkColor: '#FFFFFF',
        textColor: '#000000',
      },
    },
    {
      pattern: BBackground9,
      type: 'png',
      showIndex: 18,
      patternIndex: 18,
      disableColorSelection: true,
      defaultColors: {
        lightColor: '#FFFFFF',
        darkColor: '#FFFFFF',
        textColor: '#000000',
      },
    },
  ];

  // Sort patterns by showIndex for display
  const sortedPatterns = [...patterns].sort((a, b) => a.showIndex - b.showIndex);
  return sortedPatterns;
};

const colors = [
  {
    darkColor: '#DADADA',
    lightColor: '#E4E4E4',
    textColor: '#222222',
  },
  {
    darkColor: '#B0B0B0',
    lightColor: '#D8D8D8',
    textColor: '#222222',
  },
  {
    darkColor: '#040F5F',
    lightColor: '#051274',
    textColor: '#FFFFFF',
  },
  {
    darkColor: '#000000',
    lightColor: '#0A0037',
    textColor: '#FFFFFF',
  },
  {
    darkColor: '#ECD86C',
    lightColor: '#FFE869',
    textColor: '#2E280C',
  },
  {
    darkColor: '#D9E600',
    lightColor: '#EFFF00',
    textColor: '#2E280C',
  },
  {
    darkColor: '#E6B85D',
    lightColor: '#FFCD69',
    textColor: '#332F15',
  },
  {
    darkColor: '#F4744D',
    lightColor: '#FF8C69',
    textColor: '#592110',
  },
  {
    darkColor: '#77F64E',
    lightColor: '#8DFF69',
    textColor: '#1D4A0F',
  },
  {
    darkColor: '#4A2BB5',
    lightColor: '#643DF2',
    textColor: '#FFFFFF',
  },
  {
    darkColor: '#7453EC',
    lightColor: '#9E85F7',
    textColor: '#FFFFFF',
  },
  {
    darkColor: '#3FCCF0',
    lightColor: '#4DD3F5',
    textColor: '#113C47',
  },
  {
    darkColor: '#00A3B9',
    lightColor: '#00DBF7',
    textColor: '#113C47',
  },
  {
    darkColor: '#ED263A',
    lightColor: '#F54D5E',
    textColor: '#33090D',
  },
  {
    darkColor: '#723C13',
    lightColor: '#B26C37',
    textColor: '#FFFFFF',
  },
  {
    darkColor: '#000000',
    lightColor: '#1E1E1E',
    textColor: '#FFFFFF',
  },
  {
    darkColor: '#4DD3A9',
    lightColor: '#59FFC7',
    textColor: '#FFFFFF',
  },
  {
    darkColor: '#E67EE6',
    lightColor: '#FA8CFF',
    textColor: '#FFFFFF',
  },
];

export const useColors = () => {
  return colors;
};
