import {PlayerCardBackground} from 'components/MLS/PlayerCardBackground/PlayerCardBackground';
import {usePatterns} from 'hooks/MLSChallenge/usePatterns';
import {useColors} from 'hooks/MLSChallenge/usePatterns';

interface PatternAndColorSelectorProps {
  patternIndex: number;
  colorIndex: number;
  onPatternSelect: (patternId: number) => void;
  onColorSelect: (colorId: number) => void;
}

const PatternAndColorSelector = ({
  patternIndex,
  colorIndex,
  onPatternSelect,
  onColorSelect,
}: PatternAndColorSelectorProps) => {
  const patterns = usePatterns();
  const colors = useColors();

  const currentPattern = patterns.find((p) => p.patternIndex === patternIndex);
  const isColorSelectionDisabled = currentPattern?.disableColorSelection ?? false;

  const handlePatternSelect = (selectedPatternIndex: number) => {
    const selectedPattern = patterns.find((p) => p.patternIndex === selectedPatternIndex);
    onPatternSelect(selectedPatternIndex);

    // Reset color to -1 (no color) if selecting a pattern with disabled color selection
    if (selectedPattern?.disableColorSelection) {
      onColorSelect(-1);
    }
    // If switching from a pattern with disabled colors to one with enabled colors, set a default color if none is selected
    else if (currentPattern?.disableColorSelection && colorIndex === -1) {
      onColorSelect(0);
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <h2 className="mt-4 px-4 text-center text-sm text-mls-grey-xdark xs:text-base">Choose a color and pattern</h2>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col items-center justify-center p-4 lg:px-16">
          <div
            className={`scrollbar-hide flex max-w-[100%] overflow-x-auto ${
              isColorSelectionDisabled ? 'opacity-50' : ''
            }`}
          >
            <div className="flex gap-4">
              {colors.map((color, index) => (
                <div
                  key={index}
                  className={`flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:h-24 sm:w-24 ${
                    !isColorSelectionDisabled ? 'cursor-pointer' : 'cursor-not-allowed'
                  }`}
                  onClick={() => !isColorSelectionDisabled && onColorSelect(index)}
                  style={{
                    border: `1px solid ${index === colorIndex ? 'black' : 'lightgray'}`,
                  }}
                  aria-label={`Select color ${color.lightColor}`}
                  aria-disabled={isColorSelectionDisabled}
                >
                  <div
                    className="h-6 w-6 rounded-full sm:h-10 sm:w-10"
                    style={{backgroundColor: color.lightColor}}
                  ></div>
                </div>
              ))}
            </div>
          </div>
          {isColorSelectionDisabled && (
            <p className="mt-2 text-sm text-mls-grey-xdark">Color selection is not available for this pattern</p>
          )}
        </div>

        <div className="mb-4 flex flex-col items-center justify-center px-4 lg:px-16">
          <div className="scrollbar-hide flex max-w-[100%] overflow-x-auto">
            <div className="flex gap-4">
              {patterns.map((pattern) => (
                <div
                  key={pattern.patternIndex}
                  onClick={() => handlePatternSelect(pattern.patternIndex)}
                  className={`aspect-square h-24 w-24 cursor-pointer rounded-sm object-cover ${
                    patternIndex === pattern.patternIndex ? 'border-2 border-mls-primary' : ''
                  }`}
                >
                  <PlayerCardBackground
                    patternIndex={pattern.patternIndex}
                    colorIndex={pattern.disableColorSelection ? -1 : 0}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatternAndColorSelector;
