import React from 'react';
import clsx from 'clsx';

interface MLSButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'outline';
  isLoading?: boolean;
  loadingText?: string;
  hasError?: boolean;
  text: string;
  isPrimary?: boolean;
}

export const MLSButton: React.FC<MLSButtonProps> = ({
  variant = 'primary',
  isLoading = false,
  loadingText,
  children,
  className,
  disabled,
  hasError,
  text,
  isPrimary,
  ...props
}) => {
  const baseClasses = 'uppercase font-semibold text-lg px-4 py-2 rounded-md disabled:opacity-70 w-full';

  const variantClasses = {
    primary: 'bg-white text-black',
    outline: 'border-[1px] border-white text-white',
  };

  return (
    <button
      className={clsx(baseClasses, variantClasses[variant], className)}
      disabled={isLoading || disabled || hasError}
      {...props}
    >
      {isLoading ? loadingText || 'Loading...' : text}
    </button>
  );
};

MLSButton.displayName = 'MLSButton';
