import React from 'react';

interface ArrowIconProps extends React.SVGProps<SVGSVGElement> {
  direction?: 'left' | 'right';
  size?: number;
  strokeColor?: string;
}

const ArrowIcon = ({direction = 'left', size = 20, strokeColor = '#111111', ...props}: ArrowIconProps) => {
  const svgProps = {
    left: {
      viewBox: '0 0 24 24',
      strokeWidth: 1.5,
      d: 'M15.5253 18.9662L8.55835 12.0002L15.5253 5.0332',
    },
    right: {
      viewBox: '0 0 24 24',
      strokeWidth: 1.5,
      d: 'M8 5L14 12L8 19',
    },
  };

  const {viewBox, strokeWidth, d} = svgProps[direction];

  return (
    <svg width={size} height={size} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d={d} stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArrowIcon;
