import {useForm} from 'react-hook-form';
import {StandardInput} from 'components/Input/StandardInput/StandardInput';
import {FormSelect, FormSelectOption} from 'components/Forms/FormSelect/FormSelect';
import {ArrowButton} from 'components/Buttons/ArrowButton';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import Spinner from 'components/Loader/Spinner';
import {isLocationPermitted} from '../../feature-flags/fko-locations';
import {getLocationBySiteId} from '../../constants/locations';
import {useGetSport} from 'common/useGetSport';
import {LocationFormSelect} from 'components/Forms/LocationFormSelect/LocationFormSelect';
import {useAppSelector} from '../../redux/reduxUtils';
import {RootState} from '../../redux/store';
import {useProfiles} from 'api/User/useProfiles';

export interface UserProfileFormInterface {
  firstName: string;
  lastName: string;
  gender: string;
  dob: string;
  levelOfPlay: string;
  siteId: string;
}

interface PlayerProfileFormProps {
  onSubmit: (data: UserProfileFormInterface) => Promise<void>;
  isLoading?: boolean;
  errorMessage?: string;
  spinnerDarkMode?: boolean;
}

const ErrorMessage = ({text}: {text: string}) => {
  return text ? <p className="text-mls-error mt-1 ml-1">{text}</p> : null;
};

export const PlayerProfileForm = ({
  onSubmit,
  isLoading = false,
  errorMessage = '',
  spinnerDarkMode = false,
}: PlayerProfileFormProps) => {
  const {isDbat, isPlayerAssessment, isClasses, isFreeTrialClasses} = useGetSport();
  const {siteId: urlSiteId} = useAppSelector((state: RootState) => state.queryParamsReducer);
  const {currentProfile, otherProfiles} = useProfiles();
  const allProfiles = [currentProfile, ...otherProfiles];
  const otherProfileSiteId = allProfiles.find((profile) => profile?.mboDetails?.siteId)?.mboDetails?.siteId;
  const finalPresetSiteId = otherProfileSiteId || urlSiteId;

  const presetLocation =
    finalPresetSiteId &&
    isLocationPermitted({
      locationId: finalPresetSiteId,
      isDbat,
      isPlayerAssessment,
      isClasses,
      isFreeTrialClasses,
    })
      ? getLocationBySiteId(finalPresetSiteId)
      : undefined;

  const validationSchema = yup.object().shape({
    firstName: yup.string().required('First name is required').trim('Name cannot include leading or trailing spaces'),
    lastName: yup.string().required('Last name is required').trim('Name cannot include leading or trailing spaces'),
    gender: yup.string().required('Gender is required'),
    dob: yup
      .string()
      .required('Date of birth is required')
      .test('is-date', 'Invalid date format', (value) => {
        if (!value) {
          return true;
        }
        return !isNaN(Date.parse(value));
      }),
    levelOfPlay: yup.string().required('Level of play is required'),
    siteId: yup
      .string()
      .required('Location is required')
      .notOneOf(['disabled-placeholder'], 'Please choose a location'),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<UserProfileFormInterface>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const genderOptions: FormSelectOption[] = [
    {id: 'male', value: 'Male', displayValue: 'Male'},
    {id: 'female', value: 'Female', displayValue: 'Female'},
  ];

  const levelOptions: FormSelectOption[] = [
    {id: 'none', value: 'None', displayValue: 'None'},
    {id: 'recreation', value: 'Recreation', displayValue: 'Recreation'},
    {id: 'competitive-club', value: 'Competitive/Club', displayValue: 'Competitive/Club'},
    {id: 'college', value: 'College', displayValue: 'College'},
    {id: 'pro', value: 'Pro', displayValue: 'Pro'},
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-2 relative">
      {isLoading && (
        <div className="absolute inset-0 z-10 flex items-center justify-center">
          <Spinner darkMode={spinnerDarkMode} />
        </div>
      )}

      <div>
        <label className="opacity-30 text-sm mb-2 block">First Name</label>
        <StandardInput
          spellCheck="false"
          {...register('firstName', {required: true})}
          id="firstName"
          placeholder="First Name"
          disabled={isLoading}
        />
        <ErrorMessage text={errors.firstName?.message ? errors.firstName.message : '\u00A0'} />
      </div>

      <div>
        <label className="opacity-30 text-sm mb-2 block">Last Name</label>
        <StandardInput
          spellCheck="false"
          {...register('lastName', {required: true})}
          id="lastName"
          placeholder="Last Name"
          disabled={isLoading}
        />
        <ErrorMessage text={errors.lastName?.message ? errors.lastName.message : '\u00A0'} />
      </div>

      <div>
        <label className="opacity-30 text-sm mb-2 block">Gender</label>
        <FormSelect {...register('gender')} options={genderOptions} disabled={isLoading} />
        <ErrorMessage text={errors.gender?.message ? errors.gender.message : '\u00A0'} />
      </div>

      <div>
        <label className="opacity-30 text-sm mb-2 block">Birthdate</label>
        <StandardInput
          variant="new"
          spellCheck="false"
          {...register('dob')}
          type="date"
          id="dob"
          placeholder="Date of Birth (mm/dd/yyyy)"
          hasError={!!errors.dob?.message}
          min="1900-01-01"
          max={new Date().toISOString().split('T')[0]}
          disabled={isLoading}
        />
        <ErrorMessage text={errors.dob?.message ? errors.dob.message : '\u00A0'} />
      </div>

      <div>
        <label className="opacity-30 text-sm mb-2 block">Location</label>
        <LocationFormSelect
          register={register}
          setValue={setValue}
          hasError={!!errors.siteId}
          disabled={isLoading}
          fieldName="siteId"
          defaultValue={presetLocation?.siteId}
        />
        <ErrorMessage text={errors.siteId?.message ? errors.siteId.message : '\u00A0'} />
      </div>

      <div>
        <label className="opacity-30 text-sm mb-2 block">Level of Play</label>
        <FormSelect {...register('levelOfPlay')} options={levelOptions} disabled={isLoading} />
        <ErrorMessage text={errors.levelOfPlay?.message ? errors.levelOfPlay.message : '\u00A0'} />
      </div>

      {errorMessage && <p className="text-mls-error text-xs ml-2  text-left">{errorMessage}</p>}

      <div className="flex justify-end">
        <ArrowButton type="submit" loading={isLoading} />
      </div>
    </form>
  );
};
