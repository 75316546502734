import axios from 'axios';
import {player} from 'api/api';

export interface DownsizedImageRequestPayload {
  imageUrl: string;
}

export interface DownsizedImageResponse {
  downsized: boolean;
  imageUrl?: string;
  originalSize?: number;
  downsizedSize?: number;
}

export const getDownsizedImage = async (imageUrl: string): Promise<DownsizedImageResponse> => {
  const {data} = await axios.post(
    `${player}/image/downsize`,
    {
      imageUrl,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  return data;
};
