import clsx from 'clsx';
import {memo, ReactNode, useState} from 'react';
import {PerformanceMetric} from 'api/User/useGetPlayerDetails';
import {baselineBenchmarkValues, ExerciseBenchmark} from 'pages/Baseline/Benchmarks/BaselineBenchmarks';
import {ReactComponent as Chevron} from '../../../assets/img/icons/redesign-icons/chevron-down.svg';

export interface SessionData {
  baselineLevel: string;
  exercises: PerformanceMetric[];
}

export const MobileBaselineSessionTable = memo(
  ({sessionData, isMls = false}: {sessionData?: SessionData; isMls?: boolean}) => {
    const benchmarks = baselineBenchmarkValues;
    const levelBenchmark = benchmarks.find(
      (benchmark) => benchmark.level === (sessionData?.baselineLevel || 'beginner')
    );

    if (!levelBenchmark || !sessionData?.exercises) {
      return null;
    }
    const exercises = sessionData.exercises;
    return (
      <>
        {levelBenchmark?.benchmarks.map((benchmark, i) => {
          const exercise = exercises?.[i];
          return exercise ? (
            <_ExerciseSection
              key={`benchmark_${i}`}
              benchmark={benchmark}
              exercise={exercise}
              defaultOpen={true}
              isMls={isMls}
            />
          ) : null;
        })}
      </>
    );
  }
);
MobileBaselineSessionTable.displayName = 'MobileBaselineSessionTable';

const _ExerciseSection = ({
  benchmark,
  exercise,
  defaultOpen = false,
  isMls = false,
}: {
  benchmark: ExerciseBenchmark;
  exercise: PerformanceMetric;
  defaultOpen?: boolean;
  isMls?: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultOpen);

  return (
    <div
      className={`${
        isExpanded ? 'max-h-[600px]' : 'max-h-[48px]'
      } w-full overflow-hidden transition-all border border-grey-light border-b-0 last-of-type:border-b lg:last-of-type:rounded-b`}
    >
      <button
        onClick={() => setIsExpanded((prev) => !prev)}
        className="h-[48px] w-full px-6 py-4 bg-white flex justify-end items-center"
      >
        <span className="grow text-base text-left font-semibold overflow-hidden whitespace-nowrap text-ellipsis text-tertiary">
          {benchmark.title}
        </span>
        <Chevron className={`${isExpanded ? 'rotate-180' : ''} w-4 h-4 fill-grey-xdark opacity-75 transition-all`} />
      </button>

      <div className={`border-t ${isMls ? 'border-mls-grey-dark' : 'border-grey-light'}`}>
        <_SessionTable benchmark={benchmark} exercise={exercise} isMls={isMls} />
      </div>
    </div>
  );
};

const _SessionTable = memo(
  ({
    benchmark,
    exercise,
    isMls = false,
  }: {
    benchmark: ExerciseBenchmark;
    exercise: PerformanceMetric;
    isMls?: boolean;
  }) => {
    return (
      <>
        <table className="grid grid-cols-[minmax(min-content,2fr),minmax(min-content,1fr),minmax(min-content,2fr),minmax(min-content,1fr)]">
          <thead className="contents">
            <tr
              className={`contents text-xs sm:text-sm tracking-[-0.25px] ${
                isMls ? 'text-mls-primary' : 'text-primary'
              }`}
            >
              <th className="font-semibold bg-mls-grey-dark">
                <_Cell>Target Streak</_Cell>
              </th>
              <th className="font-normal bg-mls-grey">
                <_Cell>Streak</_Cell>
              </th>
              <th className="font-semibold bg-mls-grey-dark bg-opacity-20">
                <_Cell>Target Accuracy</_Cell>
              </th>
              <th className="font-normal bg-mls-grey">
                <_Cell>Accuracy</_Cell>
              </th>
              <th className="row-start-3 font-semibold bg-mls-grey-dark bg-opacity-20">
                <_Cell>Target SOP</_Cell>
              </th>
              <th className="row-start-3 font-normal bg-mls-grey">
                <_Cell>SOP</_Cell>
              </th>
              <th className="row-start-3 font-semibold bg-mls-grey-dark bg-opacity-20">
                <_Cell>Target MPH</_Cell>
              </th>
              <th className="row-start-3 font-normal bg-mls-grey">
                <_Cell>MPH</_Cell>
              </th>
            </tr>
          </thead>

          <tbody className="contents">
            <tr className={`contents text-sm sm:text-base ${isMls ? 'text-mls-baseline' : 'text-primary'}`}>
              <td className="bg-mls-grey-dark bg-opacity-25">
                <_Cell isMls={isMls} isMetricValue={true} isBenchmarkValue={true}>
                  {benchmark?.streak ?? '-'}
                </_Cell>
              </td>
              <td className="bg-mls-grey">
                <_Cell isMls={isMls} isMetricValue={true}>
                  {exercise?.streak ?? '-'}
                </_Cell>
              </td>
              <td className="bg-mls-grey-dark bg-opacity-25">
                <_Cell isMls={isMls} isMetricValue={true} isBenchmarkValue={true}>
                  {benchmark?.accuracy ? `${benchmark.accuracy * 100}%` : '-'}
                </_Cell>
              </td>
              <td className="bg-mls-grey">
                <_Cell isMls={isMls} isMetricValue={true}>
                  {exercise?.accuracy
                    ? `${isMls ? `${(exercise?.accuracy * 100)?.toFixed(2)}%` : `${exercise.accuracy}%`}`
                    : '-'}
                </_Cell>
              </td>
              <td className="row-start-4 bg-mls-grey-dark bg-opacity-25">
                <_Cell isMls={isMls} isMetricValue={true} isBenchmarkValue={true}>
                  {benchmark?.sop ?? '-'}
                </_Cell>
              </td>
              <td className="row-start-4 bg-mls-grey">
                <_Cell isMls={isMls} isMetricValue={true}>
                  {exercise?.speedOfPlay ?? '-'}
                </_Cell>
              </td>
              <td className="row-start-4 bg-mls-grey-dark bg-opacity-25">
                <_Cell isMls={isMls} isMetricValue={true} isBenchmarkValue={true}>
                  {benchmark?.mph ?? '-'}
                </_Cell>
              </td>
              <td className="row-start-4 bg-mls-grey">
                <_Cell isMls={isMls} isMetricValue={true}>
                  {exercise?.ballSpeed || exercise?.mph || '-'}
                </_Cell>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
);
_SessionTable.displayName = '_SessionTable';

const _Cell = ({
  children,
  isMls = false,
  isMetricValue = false,
  isBenchmarkValue = false,
}: {
  children: ReactNode;
  isMls?: boolean;
  isMetricValue?: boolean;
  isBenchmarkValue?: boolean;
}) => {
  return (
    <p
      className={clsx('h-[48px] min-h-[48px] grid place-content-center uppercase p-1', {
        'font-teko text-lg': isMls && isMetricValue,
        'font-bold': isMls && isBenchmarkValue,
      })}
    >
      {children}
    </p>
  );
};
