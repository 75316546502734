import {Link} from 'react-router-dom';
import {ReactComponent as ChevronRight} from '../../../assets/img/icons/mls-icons/chevron-right.svg';

interface NavHeadingProps {
  title: string;
  href: string;
}

export function NavHeading({title, href}: NavHeadingProps) {
  return (
    <Link to={href} className="block text-xl">
      <div className="mb-4 flex items-center">
        <h2 className="text-xl font-semibold">{title}</h2>
        <ChevronRight />
      </div>
    </Link>
  );
}
