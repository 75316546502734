import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {PlayerProfileForm, UserProfileFormInterface} from 'components/PlayerProfileForm/PlayerProfileForm';
import {useCreateProfile} from 'hooks/useCreateProfile';
import {useProfiles} from 'api/User/useProfiles';

export const MlsAuthCreateProfile = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const {createProfile, isLoading} = useCreateProfile('initial');
  const {otherProfiles, currentProfile} = useProfiles();

  useEffect(() => {
    if (currentProfile || otherProfiles.length > 0) {
      console.log('[MlsAuthCreateProfile] redirecting to home because of existing profile');
      navigate('/home');
    }
  }, [currentProfile, otherProfiles, navigate]);

  const onProfileSubmit = async (data: UserProfileFormInterface) => {
    try {
      await createProfile(data);
      navigate('/home');
    } catch (error) {
      console.error(error);
      setErrorMessage('Something went wrong, please try again.');
    }
  };

  if (otherProfiles.length > 0 || currentProfile) {
    return null; // or a loading spinner
  }

  return (
    <div className="bg-black text-white min-h-screen flex flex-col px-6">
      <div className="flex flex-1 items-center justify-center">
        <div className="w-full max-w-md">
          <h1 className="mt-4 text-3xl font-teko text-center font-bold mb-6">CREATE YOUR PLAYER PROFILE</h1>
          <PlayerProfileForm
            onSubmit={onProfileSubmit}
            isLoading={isLoading}
            errorMessage={errorMessage}
            spinnerDarkMode={true}
          />
        </div>
      </div>
    </div>
  );
};

MlsAuthCreateProfile.displayName = 'MlsAuthCreateProfile';
