import {useNavigate, useParams} from 'react-router-dom';
import {LeaderboardPageContainer} from '../LeaderboardPageContainer/LeaderboardPageContainer';
import {LeaderboardDetails} from './LeaderboardDetails';
import styles from './LeaderboardDetails.module.scss';
import {useGetLeaderboardRankings} from 'api/Leaderboards/useGetLeaderboardRankings';
import {NewPageHeading} from 'components/MLS/NewPageHeading/NewPageHeading';
import Loader from 'components/MLS/Loader/Loader';

export function LeaderboardDetailsData() {
  const navigate = useNavigate();
  const {id: leaderboardId} = useParams<{id: string}>();
  const {data, isLoading} = useGetLeaderboardRankings({leaderboardId});

  if (!data && isLoading) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <Loader text="Loading..." />
      </div>
    );
  }

  if (!data || !data.leaderboard) {
    return (
      <LeaderboardPageContainer>
        <NewPageHeading
          text="Leaderboards"
          onClickOverride={() => navigate('/leaderboards', {state: {from: `/leaderboards/${leaderboardId}`}})}
        />
        <div className={styles.notFound}>
          <h2>Oh No! You&apos;re not participating in TOCA Skills</h2>
        </div>
      </LeaderboardPageContainer>
    );
  }

  return <LeaderboardDetails leaderboard={data} />;
}
