import clsx from 'clsx';
import {memo, useMemo, useRef, useLayoutEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {PlayerCardBackground} from 'components/MLS/PlayerCardBackground/PlayerCardBackground';
import {JerseyBack} from 'pages/CustomizeJersey/JerseyBack/JerseyBack';
import MyTocaLogo from '../../../assets/img/mls/my-toca-logo-black.svg';
import {useProfiles} from 'api/User/useProfiles';
import {getAge} from 'user/user-utils';
import {ReactComponent as TocaSoccerLogo} from '../../../assets/img/icons/mls-icons/toca-soccer-logo.svg';
import {useColors} from 'hooks/MLSChallenge/usePatterns';

interface PlayerCardProps {
  containerClassNames?: string;
}

const PlayerName = memo(
  ({firstName, lastName, textColor}: {firstName: string; lastName: string; textColor: string}) => {
    const firstNameRef = useRef<HTMLSpanElement>(null);
    const lastNameRef = useRef<HTMLSpanElement>(null);

    useLayoutEffect(() => {
      const adjustFontSize = () => {
        let newFontSize = 48;
        const maxWidth = 300;
        const elements = [firstNameRef.current, lastNameRef.current];

        elements.forEach((el) => el && (el.style.fontSize = `${newFontSize}px`));

        while (elements.some((el) => el && el.offsetWidth > maxWidth) && newFontSize > 10) {
          newFontSize -= 2;
          elements.forEach((el) => el && (el.style.fontSize = `${newFontSize}px`));
        }
      };

      adjustFontSize();
    }, [firstName, lastName, textColor]);

    return (
      <div className="mt-10 flex flex-col items-center">
        <span
          ref={firstNameRef}
          data-test-id="player-card-first-name"
          className="whitespace-nowrap font-teko font-bold uppercase leading-none"
          style={{color: textColor}}
        >
          {firstName}
        </span>
        <span
          ref={lastNameRef}
          data-test-id="player-card-last-name"
          className="whitespace-nowrap font-teko font-bold uppercase leading-none"
          style={{color: textColor}}
        >
          {lastName}
        </span>
      </div>
    );
  }
);

PlayerName.displayName = 'PlayerName';

export const PlayerCard = memo(({containerClassNames = ''}: PlayerCardProps) => {
  const colors = useColors();
  const navigate = useNavigate();
  const {
    currentProfile: {jerseyInfo, playerCardInfo, firstName = '', lastName = '', dob, lifeTimeReps = 0},
  } = useProfiles();

  const jerseyColorIndex = useMemo(() => {
    const colorIndex = colors.findIndex((color) => color.lightColor === jerseyInfo?.jerseyLightColor);
    if (colorIndex === -1) {
      return 0;
    }
    return colorIndex;
  }, [jerseyInfo]);

  const playerCardColorIndex = useMemo(() => {
    const colorIndex = colors.findIndex((color) => color.lightColor === playerCardInfo?.playerCardLightColor);
    if (colorIndex === -1) {
      return 0;
    }
    return colorIndex;
  }, [playerCardInfo]);

  const patternIndex = playerCardInfo?.patternIndex || 0;
  const age = getAge(dob);
  const imgSrc = playerCardInfo?.imageSrc;
  const textColor = playerCardInfo?.playerCardTextColor || colors[playerCardColorIndex]?.textColor;
  const borderColor = '#0000001A';

  const handleNavigate = (event: React.MouseEvent, path: string) => {
    event.stopPropagation();
    navigate(path);
  };

  return (
    <div
      data-test-id="player-card-container"
      className={clsx('aspect-[327/530] max-h-screen', containerClassNames)}
      onClick={() => navigate('/avatar-creator')}
    >
      <PlayerCardBackground
        colorIndex={playerCardColorIndex}
        patternIndex={patternIndex}
        containerClassNames="rounded-lg overflow-hidden"
      >
        <div className="h-full p-1">
          <div
            className="relative flex h-full w-full flex-col items-center justify-between rounded-lg p-2"
            style={{border: `1px solid ${borderColor}`, color: textColor}}
          >
            {/* Top Section - Jersey and Logo */}
            <div className="flex w-full justify-between">
              <div
                className="flex h-[72px] w-[72px] cursor-pointer items-center justify-center rounded-full bg-white"
                onClick={(e) => handleNavigate(e, '/customize-jersey')}
              >
                <JerseyBack
                  name={jerseyInfo?.jerseyName || ''}
                  number={jerseyInfo?.jerseyNumber?.toString() || ''}
                  isPlayerCard
                  selectedColorIndex={jerseyColorIndex}
                />
              </div>
              <TocaSoccerLogo
                style={
                  {
                    '--logo-color': textColor,
                    width: '100px',
                  } as React.CSSProperties
                }
              />
              <div className="flex h-[72px] w-[72px] items-center justify-center rounded-full bg-white">
                <img src={MyTocaLogo} className="w-[56px]" alt="My Toca Logo" />
              </div>
            </div>

            {/* Player Image */}
            <div className="mt-9 flex w-full flex-col items-center">
              <div
                className="relative mb-4 h-48 w-48 rotate-45 transform overflow-hidden"
                style={{border: `1px solid ${textColor}`}}
              >
                <div className="absolute inset-0 -rotate-45 scale-[1.4] transform">
                  {imgSrc ? (
                    <img src={imgSrc} className="h-full w-full object-cover" alt="Player" />
                  ) : (
                    <div className="flex h-full w-full items-center justify-center bg-white">
                      <img src={MyTocaLogo} className="w-40" alt="My Toca Logo" />
                    </div>
                  )}
                </div>
              </div>
              <PlayerName firstName={firstName} lastName={lastName} textColor={textColor} />
            </div>

            {/* Stats Section */}
            <div className="flex w-full justify-between text-center">
              <div>
                <div className="text-base opacity-70">Age</div>
                <div className="font-teko text-[28px] font-bold" data-test-id="player-card-age-value">
                  {age}
                </div>
              </div>
              <div>
                <div className="text-base opacity-70">Reps</div>
                <div className="font-teko text-[28px] font-bold" data-test-id="player-card-reps-value">
                  {lifeTimeReps}
                </div>
              </div>
            </div>
          </div>
        </div>
      </PlayerCardBackground>
    </div>
  );
});

PlayerCard.displayName = 'PlayerCard';
