import {useEffect} from 'react';
import {useAppDispatch} from '../redux/reduxUtils';
import {setSiteId} from '../redux/slices/queryParams';

/**
 * This component captures URL parameters and stores them in Redux.
 */
export const UrlParamHandler = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Get URL parameters directly from window.location to avoid router context issues
    const urlParams = new URLSearchParams(window.location.search);
    const siteId = urlParams.get('siteId') || urlParams.get('locationId');

    if (siteId) {
      dispatch(setSiteId(siteId));
    }

    // Also listen for URL changes
    const handleUrlChange = () => {
      const newUrlParams = new URLSearchParams(window.location.search);
      const newSiteId = newUrlParams.get('siteId') || newUrlParams.get('locationId');

      if (newSiteId) {
        dispatch(setSiteId(newSiteId));
      }
    };

    // Listen for popstate events (back/forward navigation)
    window.addEventListener('popstate', handleUrlChange);

    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, [dispatch]);

  return null;
};
