import {CircularProgressBar} from 'components/MLS/CircularProgressBar/CircularProgressBar';
import {getLocationBySiteId} from 'constants/locations';
import {AppointmentResponse} from '../../../api/MLSChallenge/useGetPlayerAppointments';
import {formatDate} from './UpcomingSessions';
import {useNavigate} from 'react-router-dom';

export interface SessionProps {
  id: string;
  reps: number;
  date: string;
  location: string;
  duration: number;
  stats: {
    accuracy: number;
    speed: number;
    awareness: number;
    passes: number;
  };
}

const StatItem = ({
  value,
  label,
  showProgressBar = false,
  isBaselineSession = false,
}: {
  value: number;
  label: string;
  showProgressBar?: boolean;
  isBaselineSession?: boolean;
}) => {
  return (
    <div className="flex flex-col items-center justify-center gap-4">
      {showProgressBar ? (
        <CircularProgressBar value={value} size={50} strokeWidth={3} />
      ) : (
        <span
          className={`font-teko text-2xl font-bold ${isBaselineSession ? 'text-mls-baseline' : 'text-mls-sky-blue'}`}
          data-test-id="session-stat-value"
        >
          {label === 'Accuracy' ? `${value.toFixed(2)}%` : value.toFixed(2)}
        </span>
      )}
      <span className="whitespace-nowrap text-xs text-mls-secondary" data-test-id="session-stat-label">
        {label}
      </span>
    </div>
  );
};

export const Session = ({session}: {session: AppointmentResponse['sessionSummaries']['summaries'][0]}) => {
  const navigate = useNavigate();
  if (!session) {
    return null;
  }

  const {
    summary: {session: {accuracy = 0, streak = 0, mph = 0, speedOfPlay = 0} = {}} = {},
    startDateTime,
    siteId,
    durationMinutes = 0,
    totalBallsFired = 0,
    _id,
    isBaselineSession = false,
  } = session;

  const locationName = getLocationBySiteId(siteId)?.name;

  const onClickViewFullBaselineCard = () => {
    navigate(`/baseline-session-data/${_id}`);
  };

  return (
    <div key={_id} className="mb-6 w-full rounded-lg bg-mls-grey" data-test-id={`session-${_id}`}>
      <div className="p-6">
        {/* Header */}
        <div className="mb-8 flex items-center gap-4 border-b border-mls-grey-dark pb-6">
          <div
            className={`flex h-20 w-20 items-center justify-center rounded-full ${
              isBaselineSession ? 'bg-mls-baseline' : 'bg-mls-sky-blue'
            }`}
          >
            <span className="text-center font-teko text-xl font-bold text-white" data-test-id="session-reps">
              {totalBallsFired}
              <br />
              REPS
            </span>
          </div>
          <div>
            <h2 className="text-base text-mls-secondary" data-test-id="session-date">
              {startDateTime && formatDate(startDateTime.toString())}
            </h2>
            <p className="text-base text-mls-secondary">
              {locationName && <span data-test-id="session-location">{locationName}</span>}
              {locationName && durationMinutes > 0 && ' • '}
              {durationMinutes > 0 && <span data-test-id="session-duration">{durationMinutes} min</span>}
            </p>
            {isBaselineSession ? (
              <span
                className="text-base text-mls-baseline cursor-pointer mt-1 underline"
                onClick={onClickViewFullBaselineCard}
              >
                View Detailed Baseline Card
              </span>
            ) : null}
          </div>
        </div>
        {/* Stats Grid */}
        <div className="grid grid-cols-4 gap-2">
          <StatItem isBaselineSession={isBaselineSession} value={accuracy * 100} label="Accuracy" />
          <StatItem isBaselineSession={isBaselineSession} value={streak} label="Streak" />
          <StatItem isBaselineSession={isBaselineSession} value={mph} label="Mph" />
          <StatItem isBaselineSession={isBaselineSession} value={speedOfPlay} label="Speed of Play" />
        </div>
      </div>
    </div>
  );
};
