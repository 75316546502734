import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface QueryParamsState {
  siteId: string | null;
}

// Get initial state from localStorage if available
const STORAGE_KEY = 'site_id';
const getInitialState = (): QueryParamsState => {
  const storedSiteId = localStorage.getItem(STORAGE_KEY);
  return {
    siteId: storedSiteId,
  };
};

const queryParamsSlice = createSlice({
  name: 'queryParams',
  initialState: getInitialState(),
  reducers: {
    setSiteId: (state, action: PayloadAction<string | null>) => {
      state.siteId = action.payload;

      // Persist to localStorage
      if (action.payload) {
        localStorage.setItem(STORAGE_KEY, action.payload);
      } else {
        localStorage.removeItem(STORAGE_KEY);
      }
    },
  },
});

export const {setSiteId} = queryParamsSlice.actions;
export default queryParamsSlice.reducer;
