import {getCartoonStyles} from './getCartoonStyles';
import {getBackgroundRemoved} from './getBackgroundRemoved';

export type TransformationType = 'original' | 'cartoon' | 'background-removed';

export interface ImageTransformation {
  type: TransformationType;
  url: string;
}

/**
 * Processes an image through both cartoonization and background removal
 * @param input The input image as either a File or URL string
 * @returns An array of transformed images with their types
 */
export async function getImageTransformations(input: string | File): Promise<ImageTransformation[]> {
  try {
    // First get cartoon styles of original image
    const cartoonResults = await getCartoonStyles(input);

    // For cartoon-bg-removed, first cartoonize then remove background
    const cartoonBgRemovedResults = await Promise.all(
      cartoonResults.map((cartoonUrl) => getBackgroundRemoved(cartoonUrl))
    ).then((results) => results.flat());

    // Combine and label the results
    const transformations: ImageTransformation[] = [
      // Original image first
      {
        type: 'original' as const,
        url: input instanceof File ? await fileToDataUrl(input) : input,
      },
      // Then cartoonized version of original
      ...cartoonResults.map((url) => ({
        type: 'cartoon' as const,
        url,
      })),
      // Then background removed version of cartoon
      ...cartoonBgRemovedResults.map((url) => ({
        type: 'background-removed' as const,
        url,
      })),
    ];

    return transformations;
  } catch (error) {
    console.error('Error in getImageTransformations:', error);
    throw new Error(`Failed to transform image: ${error instanceof Error ? error.message : 'Unknown error'}`);
  }
}

async function fileToDataUrl(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}
