import {useState} from 'react';
import {ArrowButton} from 'components/Buttons/ArrowButton';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useNavigate, Link} from 'react-router-dom';
import {BasePhoneVerification} from 'pages/SignInSignUp/PhoneNumberAddVerification/BasePhoneVerification';
import {getUserAccountProfileExistStatus} from 'api/api';
import {PhoneAuthProvider, signInWithCredential, updateEmail} from 'firebase/auth';
import {auth as firebaseAuth} from '../../firebase/config';
import {logLoginEvent} from 'common/analytics-events';
import {setAccessToken, setCurrentAccountId} from 'user/user-utils';
import {handlePostSignUp} from '../../firebase/auth-utils';
import {InternationalPhoneInput} from 'components/InternationalPhoneInput/InternationalPhoneInput';

export const MLSAuthSignup = () => {
  const validationSchema = yup.object().shape({
    email: yup.string().required('Email is required').email('Email is invalid'),
    phone: yup.string().required('Phone is required'),
  });
  const [step, setStep] = useState<'collectInfo' | 'verifyPhone'>('collectInfo');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isAccountExists, setIsAccountExists] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const watchPhone = watch('phone');

  const handleCollectInfoContinue = async (data: any) => {
    try {
      setIsLoading(true);
      // Format phone number to remove only spaces, parentheses and hyphens, keeping the plus sign
      const formattedPhone = data.phone.replace(/[\s()-]/g, '');
      const response = await getUserAccountProfileExistStatus({
        email: data.email,
        phoneNumber: formattedPhone,
      });
      if (response.data.accountExists) {
        setIsAccountExists(true);
      } else {
        setStep('verifyPhone');
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Something went wrong, please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const updateUserEmail = async (user: any, email: string) => {
    try {
      await updateEmail(user, email);
    } catch (error) {
      setErrorMessage('Failed to update email');
      throw error;
    }
  };

  const handlePhoneVericfiationComplete = async (
    phoneNumber: string,
    verificationId: string,
    verificationCode: string
  ) => {
    try {
      setIsLoading(true);
      const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
      const userCredential: any = await signInWithCredential(firebaseAuth, credential);
      const authUser = userCredential.user;
      setCurrentAccountId(authUser.uid);
      setAccessToken(authUser.accessToken);
      const isNewAccount = userCredential._tokenResponse.isNewUser;

      logLoginEvent(isNewAccount ? 'phone-signup' : 'phone-login', {
        userUid: userCredential?.user?.uid,
        operationType: userCredential?.operationType,
      });

      if (isNewAccount) {
        await updateUserEmail(authUser, getValues('email'));
      }

      const route = await handlePostSignUp(userCredential);
      navigate(route);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="bg-black">
      <div className="flex relative flex-col min-h-screen max-w-lg mx-auto flex-1 text-white items-center justify-center px-4">
        <ArrowButton
          onClick={() => navigate('/auth')}
          className="absolute top-4 left-2"
          arrowDirection="left"
          variant="secondary"
        />
        {step === 'collectInfo' && (
          <div className="collectInfoStep">
            <h1 className="text-5xl font-teko text-center font-bold">Enter Your Info</h1>
            <p className="mt-2 font-helvetica font-thin text-base mb-4 text-center">
              Please provide both your Email and Phone Number
            </p>

            <form onSubmit={handleSubmit(handleCollectInfoContinue)} className="flex flex-col w-full">
              <label className="text-sm font-helvetica font-thin opacity-30 ml-1">Email</label>
              <input
                className="text-black border p-3 rounded !mt-2 text-sm"
                type="email"
                placeholder="Email address"
                {...register('email')}
                onChange={(e) => {
                  setValue('email', e.target.value, {shouldValidate: true});
                  setErrorMessage('');
                  setIsAccountExists(false);
                }}
              />
              <label className="text-sm font-helvetica font-thin opacity-30 ml-1 mt-4">Phone Number</label>
              <InternationalPhoneInput
                value={watchPhone || ''}
                onChange={(val) => {
                  setValue('phone', val, {shouldValidate: true});
                  setErrorMessage('');
                  setIsAccountExists(false);
                }}
                defaultCountry="us"
                disabled={isLoading}
                className="!mt-2"
              />
              {isAccountExists && (
                <p className="text-mls-error text-sm mt-3">
                  Account already exists with this email or phone number,{' '}
                  <Link className="underline text-blue-500" to="/auth/signin">
                    please sign in here.
                  </Link>
                </p>
              )}
              {errorMessage && <p className="text-mls-error text-sm mt-3">{errorMessage}</p>}
            </form>
            <div className="flex px-2 items-center bg-rsed-500 absolute bottom-4 left-2 md:left-4">
              <div className="text-[10px] md:text-[14px] text-left w-4/5 mr-1">
                <span>
                  By continuing, you agree to the{' '}
                  <a
                    className="underline"
                    href="https://www.tocafootball.com/policies/waiver"
                    target="_blank"
                    rel="noreferrer"
                  >
                    TOCA Waiver,
                  </a>{' '}
                  <a
                    className="underline"
                    href="https://www.tocafootball.com/policies/terms-of-service"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Service,
                  </a>{' '}
                  and{' '}
                  <a
                    className="underline"
                    href="https://www.tocafootball.com/policies/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </span>
              </div>
              <ArrowButton
                className="bg-primary"
                onClick={handleSubmit(handleCollectInfoContinue)}
                arrowDirection="right"
                variant="primary"
                loading={isLoading}
              />
            </div>
          </div>
        )}
        {step === 'verifyPhone' && (
          <div className="verifyPhoneStep">
            <BasePhoneVerification
              phoneNumber={getValues('phone')}
              onVerificationComplete={handlePhoneVericfiationComplete}
              phoneInputHeader={
                <>
                  <h1 className="text-3xl text-center font-bold">Create your account with phone number</h1>
                  <p className="mt-4 font-helvetica font-thin text-base mb-8 text-center">
                    Enter your phone number to confirm and verify your account
                  </p>
                </>
              }
              verificationCodeHeader={({phoneNumber}) => (
                <>
                  <h1 className="text-3xl text-center font-bold mb-2">ENTER YOUR VERIFICATION CODE</h1>
                  <p className="mt-2 font-helvetica font-thin text-base mb-8 text-center">
                    A code was sent to {phoneNumber}
                  </p>
                </>
              )}
              phoneSubmitButton={({isSubmitting, onSubmit, hasError}) => (
                <ArrowButton
                  className="fixed bottom-8 right-4"
                  onClick={onSubmit}
                  loading={isSubmitting}
                  arrowDirection="right"
                  variant="primary"
                  hasError={hasError}
                />
              )}
              verificationSubmitButton={({isSubmitting, onSubmit, hasError}) => (
                <ArrowButton
                  className="absolute bottom-8 right-4"
                  onClick={onSubmit}
                  loading={isSubmitting}
                  arrowDirection="right"
                  variant="primary"
                  hasError={hasError}
                />
              )}
            />
            {errorMessage && <p className="text-mls-error text-xs mt-3">{errorMessage}</p>}
          </div>
        )}
      </div>
    </div>
  );
};
