import {memo, useMemo} from 'react';
import styles from './ListCard.module.scss';
import TocaLogo from '../../../../assets/img/icons/toca-logo-black.png';
// import TestLogo from '../../../../assets/img/profile-picture.jpg'; //TODO
import {LeaderboardRankings} from 'api/Leaderboards/leaderboard.types';
import {getLocationBySiteId} from 'constants/locations';

export type ListCardProps = {
  highlight?: {player: boolean; search?: boolean};
  icon?: boolean;
  player: LeaderboardRankings['rankings'][0];
};

export const ListCard = memo(({highlight, icon = true, player}: ListCardProps) => {
  const {athleteName, score, overallRank, siteId, club, avatarUrl = ''} = player;

  const location = siteId ? getLocationBySiteId(siteId) : undefined;

  const highlightClasses = useMemo(
    () => `${highlight?.player && styles.highlightPlayer} ${highlight?.search && styles.highlightSearch}`,
    [highlight]
  );
  return (
    <div className={`${styles.container} ${highlightClasses}`} data-test-id="list-card">
      <p className={`${styles.rank} ${overallRank > 999 && styles.longRank}`} data-test-id="player-rank">
        {overallRank}
      </p>
      <div className={styles.player}>
        {icon && (
          // <div className={styles.iconContainer}>
          //   {profileImageUrl ? (
          //     <img src={TocaLogo /*TODO CHANGE */} />
          //   ) : (
          //     <img src={TocaLogo} className={styles.placeholder} />
          //   )}
          // </div>
          <div className={styles.iconContainer}>
            <div className={styles.iconTransform}>
              {avatarUrl ? (
                <img src={avatarUrl /*TODO CHANGE */} />
              ) : (
                <img src={TocaLogo} className={styles.placeholder} />
              )}
            </div>
          </div>
        )}

        <div className={styles.nameAndLocation}>
          <p className={styles.name} data-test-id="player-name">
            {athleteName}
          </p>
        </div>
      </div>

      <p className={styles.score} data-test-id="player-score">
        {score.toFixed(2)}
      </p>
      {/* <p className={styles.speed}>{speed ? speed.toFixed(2) : <span>{`-`}</span>}</p> */}
    </div>
  );
});
ListCard.displayName = 'ListCard';
