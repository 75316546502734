import {ImageTransformation} from 'api/MLSChallenge/getImageTransformations';

interface AvatarCartoonSelectorProps {
  selectedIndex: number | null;
  onCartoonSelect: (index: number) => void;
  transformations: ImageTransformation[];
  showLoader: boolean;
  error?: unknown;
}

export const AvatarCartoonSelector: React.FC<AvatarCartoonSelectorProps> = ({
  selectedIndex,
  transformations,
  onCartoonSelect,
  showLoader,
  error,
}) => {
  return (
    <div className="flex flex-col gap-6">
      <h2 className="mt-4 text-center text-sm text-mls-grey-xdark xs:text-base">Choose your avatar style</h2>
      <div className="grid grid-cols-3 gap-4 p-4">
        {transformations.map((transformation, index) => (
          <div
            key={index}
            onClick={() => {
              if (selectedIndex === index) {
                return;
              }
              onCartoonSelect(index);
            }}
            className={`relative aspect-square w-full cursor-pointer rounded-sm border-2 ${
              selectedIndex === index ? 'border-mls-primary' : 'border-transparent'
            }`}
          >
            <img src={transformation.url} alt={`${transformation.type} style`} className="h-full w-full object-cover" />
            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 p-1 text-center text-xs text-white">
              {transformation.type === 'cartoon'
                ? 'Cartoon'
                : transformation.type === 'background-removed'
                  ? 'No Background'
                  : 'Original'}
            </div>
          </div>
        ))}

        {showLoader && (
          <>
            {[...Array(2)].map((_, index) => (
              <div key={`loading-${index}`} className="relative aspect-square w-full rounded-sm bg-[#E5E7EB]">
                <div className="h-full w-full animate-pulse bg-[#99A1AF]" />
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="border-mls-grey-light h-8 w-8 animate-spin rounded-full border-4 border-t-mls-grey-dark" />
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
