import {memo, useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {useProfiles} from 'api/User/useProfiles';
import {useGetUser} from 'api/User/useGetUser';
import Loader from 'components/MLS/Loader/Loader';
import {PlayerCard} from './PlayerCard/PlayerCard';
import {Leaderboard} from './Leaderboard/Leaderboard';
import {MySessions} from './MySessions/MySessions';

export const PlayerHome = memo(() => {
  const navigate = useNavigate();
  const {
    currentProfile: {_id: currentProfileId, playerCardInfo, jerseyInfo},
  } = useProfiles();
  const currentActiveProfileId = useRef<string | null>(null);
  const {isFetching: isUserAndProfilesFetching} = useGetUser({enabled: false});

  useEffect(() => {
    if (currentProfileId !== currentActiveProfileId.current && !isUserAndProfilesFetching) {
      currentActiveProfileId.current = currentProfileId;
      if (!playerCardInfo || !(playerCardInfo?.selectedCartoonIndex || playerCardInfo?.selectedCartoonIndex === 0)) {
        navigate('/avatar-creator');
      } else if (!jerseyInfo || !jerseyInfo?.jerseyName) {
        navigate('/customize-jersey');
      }
    }
  }, [currentProfileId, navigate, isUserAndProfilesFetching]);

  return isUserAndProfilesFetching ? (
    <div className="flex min-h-screen items-center justify-center">
      <Loader text="Loading..." />
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center gap-8 py-8" data-test-id="player-home-container">
      <PlayerCard containerClassNames="w-[327px] h-[530px] cursor-pointer" />
      <Leaderboard />
      {/* <PersonalBest /> */}
      <MySessions />
    </div>
  );
});

PlayerHome.displayName = 'PlayerHome';
