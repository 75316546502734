import axios from 'axios';
import {player} from 'api/api';

// Split into separate request payloads
export interface UpdatePlayerDetailsRequestPayload {
  patternIndex: number;
  playerCardLightColor: string;
  playerCardDarkColor: string;
  playerCardTextColor: string;
}

export interface UpdatePlayerAvatarsRequestPayload {
  selectedCartoonIndex: number;
  cartoonUrls: string[];
  croppedCartoonUrls: (string | null)[];
  imageSrc: string | null;
}

export interface UpdatePlayerDetailsResponse {
  message: string;
}
export interface UpdatePlayerAvatarResponse {
  cartoonPublicUrls: string[];
  croppedCartoonPublicUrls: (string | null)[];
  fallbackCartoonPublicUrl: string | null;
}

// Split into two separate API calls
export const updatePlayerDetails = async (
  payload: UpdatePlayerDetailsRequestPayload
): Promise<UpdatePlayerDetailsResponse> => {
  console.log('Updating player details:', payload);

  const response = await axios.put(`${player}/player-card/details`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data;
};

export const updatePlayerAvatars = async (
  payload: UpdatePlayerAvatarsRequestPayload
): Promise<UpdatePlayerAvatarResponse> => {
  const response = await axios.put(`${player}/player-card/avatars`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data;
};
