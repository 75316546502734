import styles from './Trainer.module.scss';
import {memo} from 'react';
import {TrainerSearch} from './Search/TrainerSearch';
import {Profile} from '../../user/player-info.interface';
import {useProfiles} from '../../api/User/useProfiles';
import {getLocationBySiteId} from '../../constants/locations';
import {LogoBanner} from 'components/Connects/LogoBanner';
import {LoadingAnimation} from 'components/Loader/LoadingAnimation';
import {TrainerStudioSelection} from 'components/Trainer/TrainerStudioSelection';
import {useGetTrainerPlayers} from 'api/Trainer/useGetTrainerPlayers';

export type PlayerShort = Pick<Profile, '_id' | 'dob' | 'email' | 'firstName' | 'lastName'> &
  Required<Pick<Profile, 'mboDetails'>>;

export const TrainerHomePage = memo(() => {
  const {currentProfile} = useProfiles();
  const {
    data: players,
    isError: isErrorPlayers,
    isLoading: isLoadingPlayers,
  } = useGetTrainerPlayers({trainerId: currentProfile?._id});

  const trainerLocation = currentProfile?.mboDetails?.siteId
    ? getLocationBySiteId(currentProfile.mboDetails.siteId)
    : undefined;

  return (
    <>
      <div data-test-id="trainer-home" className={styles.flexContainer}>
        <LogoBanner />

        <div className={styles.trainerHeading}>
          <p className={styles.heading}>Trainer View</p>
          <p>
            <span>Center:</span>
            <span className={styles.locationName} data-test-id="trainer-location-name">
              {trainerLocation?.name ?? 'n/a'}
            </span>
          </p>
        </div>

        {/* TODO do we need to confirm trainer identity / staffId match? */}
        {/* TODO ! need to ensure profiles have staffIds ! */}
        {currentProfile?.staffId && trainerLocation?.siteId ? (
          <TrainerStudioSelection trainerStaffId={currentProfile.staffId} siteId={trainerLocation.siteId} />
        ) : (
          <div className={styles.centeredContainer}>
            <p className="text-center text-base font-medium text-alert/90">
              {!currentProfile.staffId
                ? `Cannot load studio selection. This profile does not have the required staffId data.`
                : `Cannot load studio selection. Unable to verify the siteId associated with this profile.`}
            </p>
          </div>
        )}

        {/* <div className={styles.searchContainer}>
          {isErrorPlayers ? (
            <div className={styles.centeredContainer}>
              <p className={styles.errorText}>There was an error retrieving the list of players.</p>
            </div>
          ) : isLoadingPlayers ? (
            <LoadingAnimation />
          ) : players ? (
            <TrainerSearch players={players} />
          ) : null}
        </div> */}
      </div>
    </>
  );
});
TrainerHomePage.displayName = 'TrainerHomePage';
